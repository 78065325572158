import moment from "moment";
import React from "react";
import { createStore } from "react-hooks-global-state";

const initialState = { logged: false, error: null as null | string };

export enum GLOBAL_STATE_ACIONS {
  ERROR,
  LOGIN,
  SETLOGIN,
}

const logged = localStorage.getItem("logged");
if (logged === "1") {
  const time = localStorage.getItem("ISANSYSLoggedTIME");
  if (time) {
    const lastLogin = moment(time);
    const duration = moment.duration(moment().diff(lastLogin));
    const minutes = duration.asMinutes();
    if (minutes < 60) {
      initialState.logged = true;
    } else {
      localStorage.clear();
    }
  }
}

const reducer: React.Reducer<
  typeof initialState,
  { type: GLOBAL_STATE_ACIONS; payload?: any }
> = (state, action) => {
  switch (action.type) {
    case GLOBAL_STATE_ACIONS.LOGIN:
      localStorage.setItem("logged", "1");
      return { ...state, logged: true };
    case GLOBAL_STATE_ACIONS.SETLOGIN:
      localStorage.setItem("logged", "1");
      return { ...state, logged: action.payload };
    case GLOBAL_STATE_ACIONS.ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export const { dispatch: dispatchGlobalState, useGlobalState } = createStore(
  reducer,
  initialState
);

export const setLastAppError = (err: string) => {
  dispatchGlobalState({
    type: GLOBAL_STATE_ACIONS.ERROR,
    payload: err,
  });
};

export const setLoggedStatus = () => {
  dispatchGlobalState({
    type: GLOBAL_STATE_ACIONS.LOGIN,
  });
};

export const setLogged = (payload: boolean) => {
  dispatchGlobalState({
    type: GLOBAL_STATE_ACIONS.SETLOGIN,
    payload: payload,
  });
};
