import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
} from "@material-ui/core";
import { Button } from "react-bootstrap";
// import useAxios from "axios-hooks";
import { AxiosRequestConfig } from "axios";
import APIHelper from "@/utils/ApiHelper";

const SelectWardModal = (props: any) => {
  const { open, handleClose, user, connection } = props;

  const [userWards, setUserWards] = useState([]);
  const [ward, setWard] = useState<any>([]);
  const [uWards, setUWard] = useState<any>([]);

  console.log("connectionID", connection.connectionId);
  useEffect(() => {
    const payload = {
      wardDetailsId: 0,
      byUserId: user?.UserDetailsId,
      wardName: "string",
      status: 0,
      isDeleted: 0,
      timestamp: "2024-01-16T07:07:43.724Z",
    };

    APIHelper.getWards(payload).then((data: any) => {
      setWard(data);
      APIHelper.getAllUserWards()
        .then((data: any) => {
          setUWard(data);
        })
        .catch((err: any) => {
          console.log("Error in line 71 => ", err);
        });
    });

    if (connection?.connectionId) {
      connection?.invoke(
        "RequestAccessibleUserWards",
        user?.UserDetailsId,
        () => {}
      );
    }
  }, [user, connection]);

  useEffect(() => {
    if (open) {
      if (ward?.data?.length > 0) {
        const filterWards = ward.data?.map((val: any) => {
          const isWards = uWards.data?.find(
            (w: any) => w.WardDetailsId === val.WardDetailsId
          );

          return { ...val, Attribute: 0, ...isWards };
        });

        setUserWards(JSON.parse(JSON.stringify(filterWards)));
      }
    } else {
      setUserWards([]);
    }

    return () => {
      setUserWards([]);
    };
  }, [user, ward, uWards, open, handleClose]);

  const handleSwitchChange = async (index: number) => {
    const item: any = userWards[index];
    let userWardDetail = {
      userWardsId: 0,
      userDetailsId: user?.UserDetailsId,
      wardDetailsId: item.WardDetailsId,
      attribute: 1,
    };
    const config: AxiosRequestConfig = {
      data: userWardDetail,
    };
    if (item.Attribute === 1) {
      config.method = "POST";
      config.url = "/Administration/UserWardsDelete";
      config.data = {
        userWardsId: item.userWardId,
        userDetailsId: user?.UserDetailsId ?? 0,
        wardDetailsId: item.WardDetailsId ?? 0,
        attribute: 0,
      };
    }
    await APIHelper.addUserWard(config);
    const arr = JSON.parse(JSON.stringify(userWards));
    arr[index].Attribute = arr[index].Attribute === 0 ? 1 : 0;
    setUserWards(arr);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ width: "auto" }}
        className="userSelectWard"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="d-flex justify-content-between">
            <div>Ward List Details</div>
            <div>{user?.userName}</div>
          </div>
        </DialogTitle>
        <DialogContent style={{ width: "fit-content" }}>
          <div
            className="form-group mb-3 validated "
            style={{ width: "auto", padding: "inherit" }}
          >
            {userWards?.map((wardDetail: any, index) => (
              <div className="d-flex w-100 justify-content-between" key={index}>
                <div>{wardDetail?.WardName}</div>
                <div>
                  <Switch
                    checked={wardDetail.Attribute}
                    onChange={() => handleSwitchChange(index)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="btn btn-danger" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SelectWardModal;
