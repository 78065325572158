import { TopBar } from "@/components";
import { Backend_Url } from "@/utils/AxiosBootstrap";
import { createConnection } from "@/utils/signarrConnection";
import React, { useEffect, useState } from "react";
import "../../styles/pages/wards.style.scss";
import { useNavigate } from "react-router-dom";
import { useGlobalVar } from "@/utils/getGlobalVar";
import APIHelper from "@/utils/ApiHelper";

const Wards = () => {
  const navigate = useNavigate();
  const [wardData, setWardData] = useState<any>([]);
  const { setSelectedWard } = useGlobalVar();

  useEffect(() => {
    APIHelper.getBedList()
      .then((data: any) => {
        getWardsDetail(data);
      })
      .catch((err: any) => {
        console.log("error =>", err);
        getWardsDetail([]);
      });
  }, []);

  const getWardsDetail = (bedsData: any) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail")!);
    const connection = createConnection(`${Backend_Url}/signalr`);
    connection?.start().then(() => {
      console.log("connectionId", connection?.connectionId);
      if (connection?.connectionId) {
        connection
          ?.invoke("RequestAccessibleWardDetails", {
            connectionId: connection.connectionId,
            accessibleWardList: null,
            Token: userDetail?.token,
          })
          ?.then((data) => {
            console.log("With id ==>", data);
          })
          ?.catch((error) => {
            console.log("error line 500", error);
          });
        connection?.on("pushWards", (data, error) => {
          console.log("step 1", data);
          const d = [];
          for (let i = 0; i < data.length; i++) {
            const beds = bedsData?.data?.filter(
              (val: any) => val.ByWardId === data[i].wardDetailsId
            );
            console.log("beds => ", beds);

            d.push({ ...data[i], beds });
          }
          setWardData(d);
        });
        connection?.on("pushWardItem", (data, error) => {
          console.log("step 2", data);
          console.log(":error =>", error);
        });
      }
    });
  };

  console.log("wards => ", wardData);

  const onButtonClick = (id: number) => {
    navigate(`/dashboard?wardDetailsId=${id}`);
    setSelectedWard(id);
  };

  // const onDashboardNavigate = (id: number) => {
  //   navigate(`/dashboard`);
  // };

  return (
    <div
      className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
      id="kt_wrapper"
      style={{
        paddingTop: "55px",
        borderTop: "1px solid #ddc",
      }}
    >
      <TopBar />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
          color: "black",
        }}
      >
        <h3>Select one of the following Wards: </h3>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          marginTop: "20px",
          flexDirection: "column",
        }}
      >
        <div
          className="wardItem"
          style={{ marginBottom: "20px" }}
          onClick={() => navigate("/dashboard")}
        >
          ALL WARDS
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 2fr)",
            gap: "10px",
            width: "70%",
            marginTop: "10rem",
          }}
        >
          {wardData?.map((ward: any, index: any) => (
            <div key={index} style={{ marginBottom: "20px" }}>
              <div
                className="wardItem"
                onClick={() => onButtonClick(ward?.wardDetailsId)}
              >
                {ward?.wardName}
              </div>
              {ward?.beds?.map(() => (
                <div className="bed-count"></div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Wards;
