import { useGlobalVar } from "@/utils/getGlobalVar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { IsanSelect } from "../form/Select";
// import { getPageNameByPath } from "./SideMenu";
import { Box, Tabs, Tab } from "@material-ui/core";
import denmark from "@/assets/denmark.png";
import germeny from "@/assets/germeny.png";
import norvey from "@/assets/norvey.png";
import uk from "@/assets/uk.png";
import { changeLang } from "@/translate/i18n";
import util from "tweetnacl-util";
import nacl from "tweetnacl";
// import { decrypt } from "@/utils/naclCrypto";
import APIHelper from "@/utils/ApiHelper";

const wardToOption = (w: { WardName: string; WardDetailsId: number }) => {
  return { label: w.WardName, value: w.WardDetailsId };
};

export function Breadcum() {
  let location = useLocation();
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const hook = useGlobalVar();

  const { ward, setSelectedWard } = useGlobalVar();
  const [dropShown] = useState(false);
  const [tabs, setTabs] = useState(false);
  const [value, setValue] = useState(0);
  const [fetchWards, setWards] = useState<any>([]);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    console.log("newValue", newValue);
    sessionStorage.setItem("TabVal", newValue.toString());
    let route = "";
    switch (newValue) {
      case 0:
        route = "/dashboard/userList";
        break;
      case 1:
        route = "/dashboard/wardList";
        break;
      case 2:
        route = "/dashboard/bedList";
        break;
    }
    navigate(route);
  };

  useEffect(() => {
    const payload = {
      wardDetailsId: 0,
      byUserId: 0,
      wardName: "string",
      status: 0,
      isDeleted: 0,
      timestamp: "2024-01-16T07:07:43.724Z",
    };
    APIHelper.getWards(payload)
      .then((data: any) => {
        setWards(data);
      })
      .catch((error: any) => {
        console.log("error =>", error);
      });
  }, []);

  useEffect(() => {
    // const dropShown = listShownRoute.find((r) => route == r);
    // dropShown ? setDropShown(true) : setDropShown(false);

    const tabShownRoute = [
      "/dashboard/userList",
      "/dashboard/wardList",
      "/dashboard/bedList",
    ];
    const tabShown = tabShownRoute.find((route) => location.pathname === route);
    tabShown ? setTabs(true) : setTabs(false);
    if (tabShown?.includes("wardList")) {
      setValue(1);
    } else if (tabShown?.includes("bedList")) {
      setValue(2);
    } else {
      setValue(0);
    }
  }, [location.pathname]);

  const handleChangeLan = (data: any) => {
    changeLang(data);
    localStorage.setItem("Language", data);
    i18n.changeLanguage(data);
  };

  useEffect(() => {
    const data = localStorage.getItem("Language");
    if (data) i18n.changeLanguage(data);
  }, []);

  const locations =
    location.pathname.includes("bedForm") ||
    location.pathname.includes("wardForm") ||
    location.pathname.includes("userForm");

  const data = sessionStorage.getItem("TabVal");

  const handleReloadCache = () => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail")!);
    const keyPairFrontEnd = nacl.box.keyPair();
    let sitecustomConfig: any = APIHelper.setReloadCache({
      data: {
        ConnectionId: null,
        WardDetailsId: null,
        accessibleWardList: null,
        RoleType: hook?.user?.roleType,
        IsEmptyBed: true,
        frontEndPublicKey: util?.encodeBase64(keyPairFrontEnd.publicKey),
        Token: userDetail?.token,
      },
    });
    sitecustomConfig.then(async (data: any) => {
      // const uint8Array = util.decodeBase64(data.data.publicKey);
      // const sharedKey: any = nacl.box.before(
      //   uint8Array,
      //   keyPairFrontEnd.secretKey
      // );
      // const plaintext = await decrypt(sharedKey, data.data.payload);
    });
  };
  const route =
    location.pathname.includes("/dashboard/userList") ||
    location.pathname.includes("/dashboard/bedList") ||
    location.pathname.includes("dashboard/wardList");

  return (
    <div>
      {route && (
        <div
          className="kt-subheader d-flex kt-grid__item"
          id="kt_subheader"
          style={{
            display: tabs || locations ? "block" : "none",
            top: tabs || locations ? "50px" : "0px",
          }}
        >
          {tabs || locations ? (
            <>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "20px",
                    marginLeft: "10px",
                    marginTop: "17px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "15px",
                      color: "black",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={handleReloadCache}
                  >
                    Reload cache
                  </div>
                  <img
                    src={denmark}
                    alt="denmark"
                    height={32}
                    width={32}
                    onClick={() => handleChangeLan("dk")}
                  />
                  <img
                    src={uk}
                    alt="uk"
                    height={32}
                    width={32}
                    onClick={() => handleChangeLan("en")}
                  />
                  <img
                    src={germeny}
                    alt="germeny"
                    height={32}
                    width={32}
                    onClick={() => handleChangeLan("de")}
                  />
                  <img
                    src={norvey}
                    alt="norvey"
                    height={32}
                    width={32}
                    onClick={() => handleChangeLan("nb")}
                  />
                </div>
                <Tabs
                  value={locations ? data && data : value}
                  onChange={(e: any, v: any) => handleChange(e, v)}
                  aria-label="basic tabs example"
                >
                  <Tab label="Users" />
                  <Tab label="Ward" />
                  <Tab label="Bed" />
                </Tabs>
              </Box>
            </>
          ) : (
            <></>
          )}
          {dropShown ? (
            <div
              style={{ marginRight: "6%", marginTop: 15 }}
              className="d-flex align-items-end"
            >
              <div className="d-flex align-items-baseline">
                <p style={{ width: "178px" }}>{t("breadcum.selectward")}:</p>
                <IsanSelect
                  id="byWardId"
                  name="byWardId"
                  label="asdfd"
                  style={{ marginBottom: "1%" }}
                  onChange={(e: any) => setSelectedWard(e.target.value)}
                  value={!isNaN(ward) ? parseInt(ward) : ""}
                  options={fetchWards.data?.map(wardToOption) || []}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
}
