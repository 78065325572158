// import { useStateContext } from "@/state";
import axios from "axios";
import moment from "moment";
import CryptoJS from "crypto-js";

// export const { configData } = useStateContext();

//Color codes
let White = "rgba(255, 255, 255, 0.1)";
let Blue = "rgba(25, 181, 254, 1)";
let Grey = "rgba(245, 245, 245, 0.1)";

let DefaultEwsScore0Colour = "rgb(99, 204, 0)";
let DefaultEwsScore0ColourWithServerTextAlpha = "rgba(99, 204, 0, 1)";
let DefaultEwsScore0ColourWithServerGraphAlpha = "rgba(99, 204, 0, 1)";

let DefaultEwsScore1Colour = "rgb(245, 226, 24)";
let DefaultEwsScore1ColourWithServerTextAlpha = "rgba(245, 226, 24, 1)";
let DefaultEwsScore1ColourWithServerGraphAlpha = "rgba(245, 226, 24, 1)";

let DefaultEwsScore2Colour = "rgb(255, 165, 0)";
let DefaultEwsScore2ColourWithServerTextAlpha = "rgba(255, 165, 0, 0.7)";
let DefaultEwsScore2ColourWithServerGraphAlpha = "rgba(255, 165, 0, 0.7)";

let DefaultEwsScore3Colour = "rgb(255, 16, 0)";
let DefaultEwsScore3ColourWithServerTextAlpha = "rgba(255, 16, 0, 0.5)";
let DefaultEwsScore3ColourWithServerGraphAlpha = "rgba(255, 16, 0, 0.5)";

let DefaultEwsScore4Colour = "rgb(255, 16, 0)";
let DefaultEwsScore4ColourWithServerTextAlpha = "rgba(255, 16, 0, 0.5)";
let DefaultEwsScore4ColourWithServerGraphAlpha = "rgba(255, 16, 0, 0.5)";

// Theses are NOT display strings. They should really be an enum.
let notConnectedDisplay = "OUT OF RANGE";
let waitingForDataDisplay = "WAITING FOR DATA";
let leadsOffDisplay = "LEADS OFF";
// let noTimestampDisplay = "NO TIMESTAMP";
// let orientationDisplay = "ORIENTATION";
// let noDataDisplay = "NO DATA";
let removedDisplay = "REMOVED";
// let leadsOffDetectedInMeasurement = "LEADS OFF";

// let expiredText = "";

// let scoreTimeout = "scoreTimeout";
// let scoreWaitingData = "scoreWaitingData";
// let scoreLeadsOff = "scoreLeadsOff";
// let scoreNotConnected = "scoreNotConnected";
// let scoreRemoved = "scoreRemoved";
// let scoreSetupMode = "scoreSetupMode";
// let scoreLowAmplitude = "scoreLowAmplitude";
// var scoreRawAccelerometerMode = "scoreRawAccelerometerMode";

let VITAL_SIGN_STATE__NORMAL = 0;
// let VITAL_SIGN_STATE__WAITING_FOR_DATA = 3;
// let VITAL_SIGN_STATE__LEADS_OFF_DETECTED_VIA_READING = 4;
// let VITAL_SIGN_STATE__MANUALLY_ENTERED_READING = 6;
// let VITAL_SIGN_STATE__LOW_AMPLITUDE = 8;

// These variables are overwritten when either the Dashboard or a Chart page loads. But its safer to have default Green, Yellow, Orange and Red values for them just in case
let EwsScore0Colour = DefaultEwsScore0Colour;
let EwsScore0ColourWithServerTextAlpha =
  DefaultEwsScore0ColourWithServerTextAlpha;
let EwsScore0ColourWithServerGraphAlpha =
  DefaultEwsScore0ColourWithServerGraphAlpha;

let EwsScore1Colour = DefaultEwsScore1Colour;
let EwsScore1ColourWithServerTextAlpha =
  DefaultEwsScore1ColourWithServerTextAlpha;
let EwsScore1ColourWithServerGraphAlpha =
  DefaultEwsScore1ColourWithServerGraphAlpha;

let EwsScore2Colour = DefaultEwsScore2Colour;
let EwsScore2ColourWithServerTextAlpha =
  DefaultEwsScore2ColourWithServerTextAlpha;
let EwsScore2ColourWithServerGraphAlpha =
  DefaultEwsScore2ColourWithServerGraphAlpha;

let EwsScore3Colour = DefaultEwsScore3Colour;
let EwsScore3ColourWithServerTextAlpha =
  DefaultEwsScore3ColourWithServerTextAlpha;
let EwsScore3ColourWithServerGraphAlpha =
  DefaultEwsScore3ColourWithServerGraphAlpha;

let EwsScore4Colour = DefaultEwsScore4Colour;
let EwsScore4ColourWithServerTextAlpha =
  DefaultEwsScore4ColourWithServerTextAlpha;
let EwsScore4ColourWithServerGraphAlpha =
  DefaultEwsScore4ColourWithServerGraphAlpha;

// Called by pushInitialCharts only.
// This setups up colours that are then used by getScoreColor
export function setThresholdColours(thresholdColours: any) {
  let numberOfEwsColours = thresholdColours.length;

  if (numberOfEwsColours === 0) {
    // From a Patient Session BEFORE Threshold Colours were introduced
    // Use these as default values. They match the previous hard coded colours and CSS elements
    setScoreColours(
      0,
      "rgb(85, 174, 58)",
      "rgba(85, 174, 58, 1)",
      "rgba(85, 174, 58, 1)"
    );
    setScoreColours(
      1,
      "rgb(245, 226, 24)",
      "rgba(245, 226, 24, 0.2)",
      "rgba(255, 165, 0, 0.2)"
    );
    setScoreColours(
      2,
      "rgb(255, 165, 0)",
      "rgba(255, 165, 0, 0.2)",
      "rgba(255, 165, 0, 0.7)"
    );
    setScoreColours(
      3,
      "rgb(255, 16, 0)",
      "rgba(255, 16, 0, 0.2)",
      "rgba(255, 16, 0, 0.5)"
    );
    setScoreColours(
      4,
      "rgb(255, 16, 0)",
      "rgba(255, 16, 0, 0.2)",
      "rgba(255, 16, 0, 0.5)"
    );
  } else {
    // From a Patient Session with Threshold Colours
    for (let i = 0; i < numberOfEwsColours; i++) {
      // Colour is an Int
      let colourAsInt = thresholdColours[i].Colour;
      let redPart = (colourAsInt & 0xff0000) >> 16;
      let greenPart = (colourAsInt & 0x00ff00) >> 8;
      let bluePart = colourAsInt & 0x0000ff;

      // Colours need to be in the form of 'rgb(99, 204, 0)'
      let rgbColour =
        "rgb(" + redPart + ", " + greenPart + ", " + bluePart + ")";

      // Colours with an Alpha need to be in the form of 'rgba(99, 204, 0, 0.5)'
      // Alpha is a Float
      let serverTextAlpha = thresholdColours[i].ServerTextAlpha;
      let rgbColourWithServerTextAlpha =
        "rgba(" +
        redPart +
        ", " +
        greenPart +
        ", " +
        bluePart +
        ", " +
        serverTextAlpha +
        ")";

      let serverGraphAlpha = thresholdColours[i].ServerGraphAlpha;
      let rgbColourWithServerGraphAlpha =
        "rgba(" +
        redPart +
        ", " +
        greenPart +
        ", " +
        bluePart +
        ", " +
        serverGraphAlpha +
        ")";

      setScoreColours(
        i,
        rgbColour,
        rgbColourWithServerTextAlpha,
        rgbColourWithServerGraphAlpha
      );
    }
  }
}

// Only called by setThresholdColours above
function setScoreColours(
  score: number,
  colour: string,
  colourWithServerTextAlpha: string,
  colourWithServerGraphAlpha: string
) {
  switch (score) {
    case 0:
      EwsScore0Colour = colour;
      EwsScore0ColourWithServerTextAlpha = colourWithServerTextAlpha;
      EwsScore0ColourWithServerGraphAlpha = colourWithServerGraphAlpha;
      return {
        EwsScore0Colour,
        EwsScore0ColourWithServerTextAlpha,
        EwsScore0ColourWithServerGraphAlpha,
      };
    // break;
    case 1:
      EwsScore1Colour = colour;
      EwsScore1ColourWithServerTextAlpha = colourWithServerTextAlpha;
      EwsScore1ColourWithServerGraphAlpha = colourWithServerGraphAlpha;
      return {
        EwsScore1Colour,
        EwsScore1ColourWithServerTextAlpha,
        EwsScore1ColourWithServerGraphAlpha,
      };
    // break;
    case 2:
      EwsScore2Colour = colour;
      EwsScore2ColourWithServerTextAlpha = colourWithServerTextAlpha;
      EwsScore2ColourWithServerGraphAlpha = colourWithServerGraphAlpha;
      return {
        EwsScore2Colour,
        EwsScore2ColourWithServerTextAlpha,
        EwsScore2ColourWithServerGraphAlpha,
      };
    // break;
    case 3:
      EwsScore3Colour = colour;
      EwsScore3ColourWithServerTextAlpha = colourWithServerTextAlpha;
      EwsScore3ColourWithServerGraphAlpha = colourWithServerGraphAlpha;
      return {
        EwsScore3Colour,
        EwsScore3ColourWithServerTextAlpha,
        EwsScore3ColourWithServerGraphAlpha,
      };
    // break;
    case 4:
      EwsScore4Colour = colour;
      EwsScore4ColourWithServerTextAlpha = colourWithServerTextAlpha;
      EwsScore4ColourWithServerGraphAlpha = colourWithServerGraphAlpha;
      return {
        EwsScore4Colour,
        EwsScore4ColourWithServerTextAlpha,
        EwsScore4ColourWithServerGraphAlpha,
      };
    // break;
    default:
  }
}

// Used only on the Charts page
export function getScoreColor(
  score: number,
  measurementIsTimeRangeManualVital?: any
) {
  var result;
  switch (score) {
    case -101:
    case -102:
    case -103:
    case -104:
    case -1:
      result = Blue;
      break;

    case 0:
      if (measurementIsTimeRangeManualVital) {
        result = EwsScore0ColourWithServerGraphAlpha;
      } else {
        result = White;
      }
      break;
    case 1:
      result = EwsScore1ColourWithServerGraphAlpha;
      break;
    case 2:
      result = EwsScore2ColourWithServerGraphAlpha;
      break;
    case 3:
      result = EwsScore3ColourWithServerGraphAlpha;
      break;
    case 4:
      result = EwsScore4ColourWithServerGraphAlpha;
      break;
    default:
      result = Grey;
  }
  return result;
}

export const getHumanReadableDeviceId = (data: any) => {
  if (data && data?.deviceSessionId) {
    return data?.deviceSessionId;
  } else {
    console.log("No device found");
  }
  return null;
};

export const getLatestBatteryValue = (data: any, batteryType: string) => {
  if (data && data[batteryType] && data[batteryType].length > 0) {
    return `${data[batteryType][data[batteryType].length - 1].reading}%`;
  }
  return null;
};

export const getBatteryValueData = async (patientSessionId: string) => {
  const apiUrl =
    process.env.REACT_APP_API_URL + "/api/Patients/GetIsansysBatteryValues";

  try {
    const response = await axios.post(apiUrl, {
      PatientSessionId: patientSessionId,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

function measurementValid(measurement: any) {
  return measurement && measurement.deviceSessionId !== 0;
}

export const emptyString = "";
export const canShowLastDataString = (measurement: any) => {
  var result = false;
  if (measurementValid(measurement)) {
    if (!isNaN(measurement.score)) {
      result =
        measurement.displayTimeStamp !== emptyString &&
        measurement.display === emptyString;
    }
  }
  return result;
};

export const canShowLastData = (measurement: any) => {
  return canShowLastDataString(measurement);
};

class SensorStatusManager {
  notPartOfSession: number;
  connected: number;
  notConnected: number;
  leadsOff: number;
  removed: number;
  setupMode: number;
  accelerometerMode: number;

  constructor() {
    this.notPartOfSession = 1 << 0; // 1
    this.connected = 1 << 1; // 2
    this.notConnected = 1 << 2; // 4
    this.leadsOff = 1 << 3; // 8
    this.removed = 1 << 4; // 16
    this.setupMode = 1 << 5; // 32
    this.accelerometerMode = 1 << 6; // 64
  }
}

const sensorStatusManager = new SensorStatusManager();

export default sensorStatusManager;

function getParameterByName(name: any) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function isDebug() {
  return getParameterByName("debug") === "true";
}

export const showDevelopmentCode = () => {
  return isDebug();
};

export const getSensorStatusText = (sensorStatus: any) => {
  var text = "";

  if (sensorStatus) {
    if ((sensorStatus.sensorState & sensorStatusManager.connected) > 0) {
      text += "CON ";
    }

    if ((sensorStatus.sensorState & sensorStatusManager.notConnected) > 0) {
      text += "NOT CON ";
    }

    if ((sensorStatus.sensorState & sensorStatusManager.removed) > 0) {
      text += "REM ";
    }

    if (
      (sensorStatus.sensorState & sensorStatusManager.accelerometerMode) >
      0
    ) {
      text += "ACC ";
    }

    if ((sensorStatus.sensorState & sensorStatusManager.leadsOff) > 0) {
      text += "LO ";
    }

    if ((sensorStatus.sensorState & sensorStatusManager.setupMode) > 0) {
      text += "SET ";
    }

    if ((sensorStatus.sensorState & sensorStatusManager.notPartOfSession) > 0) {
      text += "NOT PART ";
    }

    //logOnDebug("getSensorState for " + getSensorTypeAsString(sensorStatus.sensorType) + " = " + text)
  }
  return text;
};

export const getDashboardVitalSignDisplayStatus = (
  displayText: string
): string => {
  if (displayText && typeof displayText === "string") {
    switch (displayText.toUpperCase()) {
      case notConnectedDisplay:
      case leadsOffDisplay:
      case removedDisplay:
        return "";

      case waitingForDataDisplay:
        return "Waiting for Data";

      default:
        return displayText;
    }
  }
  return displayText;
};

export const isEmpty = (obj: any) => {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

export const showVariableIfNotEmpty = (variable: any) => {
  if (!isEmpty(variable)) {
    return variable;
  } else {
    return "---";
  }
};

export const formatWithOneDecimalPlaces = (number: any) => {
  if (isNaN(number)) {
    // Number is really a text string - e.g. Leads Off / Waiting For Data / Out Of Range
    return number;
  } else {
    if (number > 0) {
      var rounded = Math.round(number * 10) / 10;
      var fixed = rounded.toFixed(1);
      return fixed;
    } else {
      return emptyString;
    }
  }
};

export const formatWithOneDecimalPlace = (value: any) => {
  let result: any = formatWithOneDecimalPlaces(value);
  return getDashboardVitalSignDisplayStatus(result);
};

export const canShowMeasurements = (measurement: any) => {
  var result = false;
  if (measurementValid(measurement)) {
    if (measurement.vitalSignState === VITAL_SIGN_STATE__NORMAL) {
      if (measurement.score >= 0) {
        result = true;
      }
    }
  }
  return result;
};

export const canShowMeasurement = (measurement: any) => {
  return canShowMeasurements(measurement);
};

export const displayBloodPressureReadings = (bloodPressure: any) => {
  if (bloodPressure != null) {
    switch (bloodPressure.display) {
      case "REMOVED":
      case "WAITING FOR DATA":
        return bloodPressure.display;

      case null:
      case undefined:
      case "":
        return "";

      default:
        return bloodPressure.display + "/" + bloodPressure.display2;
    }
  } else {
    return "";
  }
};

export const getBloodPressureDeviceStatus = (status: any) => {
  var result = displayBloodPressureReadings(status);
  switch (result) {
    case "REMOVED":
      return "stringRemoved";
    case "WAITING FOR DATA":
      return "stringWaitingForData";
    default:
      return result;
  }
};

export const emptyBed = (patientDetailsId: any) => {
  return patientDetailsId === 0 || patientDetailsId === null;
};

function canShowEwsMeasurements(measurement: any) {
  if (measurement == null) {
    return false;
  }

  return measurement.score !== emptyString;
}

export const canShowEwsMeasurement = (measurement: any) => {
  return canShowEwsMeasurements(measurement);
};

export const canShowEwsLastDataReceivedAtXStrings = (measurement: any) => {
  if (measurement == null) {
    return false;
  }
  if (measurement.thresholdScore == null) {
    return false;
  }

  // EWS measurements get thresholdScore set to -1 when its expired. This means that there WAS a measurement to start with. So can show the Last Data string
  return measurement.thresholdScore === -1;
};

export const canShowEwsLastDataReceivedAtXString = (measurement: any) => {
  return canShowEwsLastDataReceivedAtXStrings(measurement);
};

// utils/dateFormatter.js
export function formatDate(inputStr: any) {
  if (inputStr === "" || inputStr === undefined) {
    return "";
  }
  const date = new Date(inputStr);

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[date.getMonth()];
  const day = date.getDate();

  let suffix = "th";
  if (day % 10 === 1 && day !== 11) suffix = "st";
  if (day % 10 === 2 && day !== 12) suffix = "nd";
  if (day % 10 === 3 && day !== 13) suffix = "rd";

  return `${hours}:${minutes}:${seconds} ${month} ${day}${suffix}`;
}

export const getDashboardVitalSignDisplayStatusIcon = (status: any) => {
  if (status && (typeof status === "string" || status instanceof String)) {
    switch (status.toUpperCase()) {
      case notConnectedDisplay:
        return "gwStatusBluetoothIcon";

      case leadsOffDisplay:
        return "gwStatusLeadsOffIcon";

      case removedDisplay:
        return "gwStatusRemovedIcon";

      case waitingForDataDisplay:
        return "";

      default:
        return "";
    }
  }

  return "";
};

// function getDeviceStatus(status: any) {
//   switch (status) {
//     case notConnectedDisplay:
//       return "stringNotConnected";
//     case leadsOffDisplay:
//       return "stringLeadsOff";
//     case removedDisplay:
//       return "stringRemoved";
//     case waitingForDataDisplay:
//       return "stringWaitingForData";
//     case leadsOffDetectedInMeasurement:
//       return "stringLeadsOffDetectedInMeasurement";
//     default:
//       return status;
//   }
// }

export const deviceDisplayStatusManually = (measurement: any) => {
  if (measurement) {
    let measurementExpiryTimestamp = moment(
      measurement.measurementExpiryTimestamp
    ).toDate();
    let d = new Date();
    let currentTimestamp = moment(d).toDate();
    if (currentTimestamp >= measurementExpiryTimestamp) {
      return "stringWaitingForData";
    } else {
      if (measurement.display == null) {
        return measurement.reading;
      } else {
        return measurement.display;
      }
    }
  }
};

export const calculateDaysLeft = (expireDate: any) => {
  if (expireDate) {
    const today = new Date();
    const expireDateTime = new Date(expireDate);

    // Calculate difference in milliseconds
    const differenceInTime = expireDateTime.getTime() - today.getTime();

    // Convert milliseconds to days
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    if (differenceInDays > 30) {
      // If the difference is more than 30 days, return 30 days
      return "There are 30 or more days remaining on your Lifeguard Server License";
    } else if (differenceInDays <= 0) {
      // License has expired or will expire today
      return "Your license has expired or will expire today";
    } else {
      // Return the actual remaining days
      return `There are ${differenceInDays} days remaining on your Lifeguard Server License`;
    }
  }
};

export const calculateDuration = (start_time: any, end_time: any) => {
  const start: any = new Date(start_time);
  const end: any = new Date(end_time);

  const diff = Math.abs(end - start);

  const days = Math.floor(diff / (1000 * 60 * 60 * 24)) ?? 0;
  const hours =
    Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) ?? 0;
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)) ?? 0;
  const seconds = Math.floor((diff % (1000 * 60)) / 1000) ?? 0;

  return `${days}d:${hours}h:${minutes}m:${seconds}s`;
};

// Remember to include the crypto-js.min.js in the Bundle of whatever calls this
export function encryptData(plainText: any, key: any, iv: any) {
  // AES128 so key and IV are 16 bytes
  let ciphertext = CryptoJS.AES.encrypt(plainText, key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return ciphertext.ciphertext;
}
