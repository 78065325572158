import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { Button, ProgressBar } from "react-bootstrap";

const DownloadPatientSummary = ({
  downloadFiles,
  handleClose,
  getPublicationPayload,
}: any) => {
  const [statusBar, setStatusBar] = useState(0);
  const { open, historicalData, connection } = downloadFiles;

  const pregressBar_update = () => {
    var statusInfo: any = document.getElementsByClassName("statusInfo")?.[0];
    var actionButton: any =
      document.getElementsByClassName("actionButton")?.[0];
    var downloadInfo: any =
      document.getElementsByClassName("downloadInfo")?.[0];
    downloadInfo.style.visibility = "hidden";
    actionButton.style.visibility = "hidden";
    var width = 0;
    var identity = setInterval(scene, 500);
    let NumberofDownloads = historicalData?.length;
    function scene() {
      statusInfo.innerHTML = `Number of files to download: ${NumberofDownloads}`;
      if (width >= 100) {
        clearInterval(identity);
      } else {
        width += (1 / historicalData.length) * 100;

        setStatusBar(Math.ceil(width));
        NumberofDownloads--;
      }
      if (width >= 100) {
        setTimeout(function () {
          handleClose();
        }, 2000);
      }
    }
  };

  const batchSubHistoricExport = () => {
    let AllpatientSessionId_Batches = historicalData;
    AllpatientSessionId_Batches.forEach(function (
      AllpatientSessionId_Batch: any,
      index: any
    ) {
      setTimeout(function () {
        var verbose = false;

        var item: any = AllpatientSessionId_Batch;

        if (item.hasExportSubscription === false) {
          var payload = {
            ServerAddressForExport: window.location.href,
            PatientSessionId: item?.patientSessionId,
            Verbose: verbose,
            ForceOverwrite: false,
            ConnectionId: connection?.connection?.connectionId,
            Subscription: `http:\\\\isansys.com\\DatabaseExtractor\\DownloadResponse\\${item?.patientSessionId}`,
            Publication:
              "http:\\\\isansys.com\\DatabaseExtractor\\DownloadRequest",
            PublicationPayload: getPublicationPayload(
              window.location.href,
              item?.patientSessionId,
              verbose,
              false,
              false,
              false
            ),
          };
          var payloadStr = JSON.stringify(payload);

          connection.invoke("RealTimeHistoricExportProxy", payloadStr);
          item.hasExportSubscription = true;
        }

        const { authorisationKey, userDetailsId } = JSON.parse(
          localStorage.getItem("userDetail") as any
        );

        // Update auditlog
        var jsonpayload = {
          AuditLogID: 1, // Dummy value
          UserId: userDetailsId,
          PatientSessionId: item?.patientSessionId,
          GatewayId: authorisationKey,
          Action: 1,
          Details:
            "WardName:" +
            " " +
            item?.wardName +
            " " +
            " " +
            "BedName:" +
            " " +
            item?.bedName,
          ActionDate: new Date().toJSON(),
        };

        var json = JSON.stringify(jsonpayload);
        connection.invoke("InsertAuditData", json);
      }, index * 500);
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ width: "auto" }}
      >
        <DialogContent>
          <div
            className="form-group mb-3 downloadModalContent"
            style={{ width: "auto" }}
          >
            <div>
              <p className="downloadInfo">
                Number of files to download: {historicalData.length}
              </p>
              <h3 className="statusInfo">
                Are you sure want to download selected files?
              </h3>
            </div>
            <ProgressBar now={statusBar} label={`${statusBar}%`} />
          </div>
        </DialogContent>
        <DialogActions className="actionButton">
          <Button
            className="btn btn-success"
            onClick={() => {
              batchSubHistoricExport();
              pregressBar_update();
            }}
          >
            Yes
          </Button>
          <Button className="btn btn-danger" onClick={handleClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DownloadPatientSummary;
