import { configure } from "axios-hooks";
import Axios from "axios";
import { setLastAppError } from "@/state";

export const Backend_Url = `${process.env.REACT_APP_API_URL}`;

export const AxiosInstance = Axios.create({
  baseURL: `${Backend_Url}/api`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
const requestHandler = (request: any) => {
  if (localStorage.getItem("userDetail")) {
    const userDetail = JSON.parse(localStorage.getItem("userDetail")!);
    request.headers.Authorization = `Bearer ${userDetail.token}`;
  }
  return request;
};
AxiosInstance.interceptors.request.use((request) => requestHandler(request));
AxiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // TODO: add global error handler here

      setLastAppError(error.response.data.message);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      setLastAppError("The request was made but no response was received");
    } else {
      // Something happened in setting up the request that triggered an Error
      setLastAppError(error.message);
      // console.log("Error", error);
    }
    return Promise.reject(error);
  }
);
configure({ axios: AxiosInstance });
