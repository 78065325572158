import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Logout() {
  const goTo = useNavigate();

  useEffect(() => {
    localStorage.removeItem("logged");
    localStorage.clear();
    goTo("/");
  }, [goTo]);
  return <></>;
}

export default Logout;
