import clsx from "clsx";
import { Button } from "react-bootstrap";

type ButtonProps = {
  text: string;
  loading?: boolean;
} & React.ComponentProps<typeof Button>;

export function IsanButton({ text, ...rest }: ButtonProps) {
  return (
    <Button
      {...rest}
      disabled={rest.loading === true || rest.disabled === true}
      className={clsx(rest.className, "isan-button", {
        "kt-spinner kt-spinner--light kt-spinner--right": rest.loading === true,
      })}
    >
      {text}
    </Button>
  );
}
