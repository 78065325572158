import logo from "@/assets/logo.png";
import { useCallback, useEffect, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGlobalVar } from "@/utils/getGlobalVar";
import moment from "moment";
import { setLogged } from "@/state";
import { TableBody } from "@material-ui/core";

export function TopBar(props: any) {
  const { user } = useGlobalVar();
  const { t } = useTranslation();
  const goTo = useNavigate();
  const { pathname } = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    const data = localStorage.getItem("Language");
    if (data) i18n.changeLanguage(data);
  }, []);

  const headerRef: any = useRef(null);

  const moveToLogin = useCallback(() => {
    localStorage?.clear();
    setLogged(false);
    goTo("/", { replace: true });
  }, [goTo]);

  const logout = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();
      moveToLogin();
    },
    [moveToLogin]
  );

  useEffect(() => {
    const timer = setInterval(() => {
      const startTime = moment(localStorage?.getItem("ISANSYSLoggedTIME"));
      const duration = moment?.duration(moment()?.diff(startTime));
      const hours = duration?.minutes();
      if (hours >= 60) {
        moveToLogin();
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [moveToLogin]);

  const handleChangeRoute = (route: string) => {
    goTo(route);
  };

  return (
    <>
      <div
        id="kt_header"
        className="kt-header kt-grid__item  kt-header--fixed main-header"
        ref={headerRef}
      >
        <div className="kt-header__topbar kt-header_tabpanel w-100 p-0">
          <div className="kt-header kt-titlebar w-100 header-main-start">
            <table style={{ width: "100%" }} className="table-body-topbar">
              <TableBody>
                <tr>
                  <td style={{ width: "33vw" }}>
                    <div>
                      <img src={logo} alt="logo" />
                    </div>
                    <div
                      style={{
                        color: "black",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {props?.chartName?.map((chart: any, index: number) => (
                        <div key={index} style={{ marginRight: "10px" }}>
                          {chart?.name}{" "}
                          <span
                            id="LifetempCarat"
                            style={{
                              border: "1px solid #ccc",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              props?.toggleChartVisibility(chart.index)
                            }
                          >
                            ^
                          </span>
                        </div>
                      ))}
                    </div>
                  </td>
                  <td style={{ width: "34vw", textAlign: "center" }}>
                    <div
                      className="d-flex"
                      style={{
                        fontSize: "20pt",
                        fontWeight: "bold",
                        color: "#808080",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <div>{t("topbar.patientstatus")}</div>
                    </div>
                  </td>
                  <td style={{ width: "33vw" }}>
                    <div
                      className="kt-header kt-titlebar "
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                      }}
                    >
                      <div>
                        {user?.roleType === 1 && (
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                              onClick={() =>
                                handleChangeRoute("/dashboard/userList")
                              }
                              className={
                                pathname?.includes("/dashboard/")
                                  ? "dashboard-button selected-button"
                                  : "dashboard-button"
                              }
                            >
                              {t("header.admin")}
                            </Dropdown.Toggle>
                          </Dropdown>
                        )}
                      </div>
                      <div>
                        <Dropdown>
                          <Dropdown.Toggle
                            className={
                              pathname === "/dashboard"
                                ? "dashboard-button selected-button"
                                : "dashboard-button"
                            }
                            onClick={() => handleChangeRoute("/dashboard")}
                            variant="success"
                            id="dropdown-basic"
                          >
                            {t("header.dashboard")}
                          </Dropdown.Toggle>
                        </Dropdown>
                      </div>

                      <div>
                        <Dropdown>
                          <Dropdown.Toggle
                            className={
                              pathname === "/historical"
                                ? "dashboard-button selected-button"
                                : "dashboard-button"
                            }
                            onClick={() => handleChangeRoute("/historical")}
                            variant="success"
                            id="dropdown-basic"
                          >
                            {t("header.historical")}
                          </Dropdown.Toggle>
                        </Dropdown>
                      </div>

                      <div>
                        <Dropdown>
                          <Dropdown.Toggle
                            className={
                              pathname === "/wards"
                                ? "dashboard-button selected-button"
                                : "dashboard-button"
                            }
                            onClick={() => handleChangeRoute("/wards")}
                            variant="success"
                            id="dropdown-basic"
                          >
                            {t("Wards")}
                          </Dropdown.Toggle>
                        </Dropdown>
                      </div>

                      <div>
                        <Dropdown>
                          <Dropdown.Toggle
                            className={
                              pathname === "/about"
                                ? "dashboard-button selected-button"
                                : "dashboard-button"
                            }
                            onClick={() => handleChangeRoute("/about")}
                            variant="success"
                            id="dropdown-basic"
                          >
                            {t("header.about")}
                          </Dropdown.Toggle>
                        </Dropdown>
                      </div>

                      <div
                        className="kt-header__topbar-item kt-header__topbar-item--user"
                        style={{ marginRight: "10px" }}
                      >
                        <a
                          onClick={logout}
                          href="#1"
                          className="dashboard-button"
                        >
                          {t("header.signout")}
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </TableBody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
