import clsx from "clsx";

type InputProps = React.HTMLProps<HTMLInputElement>;

export function IsanInput({ ...rest }: InputProps) {
  return (
    <input
      {...rest}
      className={clsx(rest.className, "isan-input", "form-control")}
    />
  );
}
