import { Breadcum, IsanButton, IsanSelect, TopBar } from "@/components";
import { Modal } from "react-bootstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import { createSearchParams, useNavigate } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { useEffect, useState } from "react";
// import useAxios from "axios-hooks";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useAlert } from "react-alert";
import Loader from "@/Loader/Loader";
import APIHelper from "@/utils/ApiHelper";

type Bed = {
  BedName: string;
  Timestamp: string;
  BedDetailsId: number;
  Status: number;
};

const Status: Record<number, string> = {
  0: "Enabled",
  1: "Disabled",
};
const EditIconComponent = (row: Bed) => {
  const navigate = useNavigate();
  const params = createSearchParams(row as unknown as Record<string, string>);
  const goTo = () => navigate("/dashboard/bedForm?" + params);
  return <EditIcon onClick={goTo} />;
};

const wardToOption = (w: { WardName: string; WardDetailsId: number }) => {
  return { label: w.WardName, value: w.WardDetailsId };
};

function BedList() {
  const { t } = useTranslation();
  const [rowDelete, setRowDelete] = useState<Bed | undefined>(undefined);
  const [selectedWard, setSelectedWard] = useState("");
  const [wardList, setWardList] = useState([]);
  const [getBedDetails, setBedDetails] = useState<any>([]);
  const [wards, setWards] = useState<any>([]);
  const navigate = useNavigate();
  const alert = useAlert();
  const goTo = () => navigate("/dashboard/bedForm");

  const fetch = () => {
    APIHelper.getBedList()
      .then((data: any) => {
        setBedDetails(data);
      })
      .catch((err: any) => {
        console.log("error =>", err);
      });
  };

  const columns: TableColumn<{
    BedName: string;
    Timestamp: string;
    BedDetailsId: number;
    Status: number;
    ByWardId: string;
    ByWardName: string;
  }>[] = [
    {
      name: t("addbed.table.wardid"),
      selector: (row) => row.ByWardId,
      sortable: true,
    },
    {
      name: t("addbed.table.wardname"),
      selector: (row) => row.ByWardName,
      sortable: true,
    },
    {
      name: t("addbed.table.bedid"),
      selector: (row) => row.BedDetailsId,
      sortable: true,
    },
    {
      name: t("addbed.table.bedname"),
      selector: (row) => row.BedName,
      sortable: true,
    },
    {
      name: t("addbed.table.status"),
      cell: (row) => (
        <span style={{ color: row.Status === 1 ? "red" : "green" }}>
          {Status[row.Status]}
        </span>
      ),
      selector: (row) => row.Status,
      sortable: true,
    },
    {
      name: t("addbed.table.edit"),
      cell: EditIconComponent,
    },
  ];

  const onDeleteClick = () => {
    const userDetail: any = localStorage.getItem("userDetail");
    const { authorisationKey } = JSON.parse(userDetail);
    const deleteRow = { ...rowDelete, isDeleted: 1, authorisationKey };
    APIHelper?.getDeleteBed(deleteRow).then(() => {
      alert.success(t("addbed.alertmessage.delete"));
      setRowDelete(undefined);
      fetch();
    });
  };

  const onDeleteCancel = () => {
    setRowDelete(undefined);
  };

  const DeleteIcon = (row: Bed) => {
    const markForDelete = () => {
      setRowDelete(row);
    };

    return (
      <FontAwesomeIcon
        onClick={markForDelete}
        icon={faTrash}
        style={{ marginLeft: "15px" }}
      />
    );
  };

  useEffect(() => {
    APIHelper.getWards()
      .then((data) => {
        setWards(data);
        fetch();
      })
      .catch((err: any) => {
        console.log("err =>", err);
        fetch();
      });
  }, []);

  useEffect(() => {
    const findWardName = (wardId: number) => {
      const getWard = wards?.data?.find(
        (ward: any) => ward?.WardDetailsId === wardId
      );

      return getWard && getWard.WardName ? getWard.WardName : "-";
    };

    const bedList = getBedDetails?.data?.map((data: any) => ({
      ...data,
      ByWardName: findWardName(data.ByWardId),
    }));

    setWardList(bedList);
  }, [getBedDetails, wards.data]);

  const handleWardChange = (e: any) => {
    setSelectedWard(e.target.value);
  };

  return getBedDetails?.loading ? (
    <Loader />
  ) : (
    <>
      <div
        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
        id="kt_wrapper"
      >
        <TopBar />
        <Breadcum />
        <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className="kt-portlet kt-portlet--mobile">
              <div
                className="kt-portlet__body kt-portlet__body--fit"
                style={{ marginTop: "13px" }}
              >
                <DataTable
                  progressPending={getBedDetails?.loading}
                  progressComponent={<Loader />}
                  title={
                    <div
                      className="d-flex justify-content-between w-50"
                      style={{ marginTop: "19px" }}
                    >
                      <IsanButton
                        onClick={goTo}
                        text={t("users.new.btn")}
                        style={{ height: "20px" }}
                      />
                      <div>
                        <IsanSelect
                          id="byWardId"
                          name="byWardId"
                          label="asdfd"
                          onChange={(e: any) => handleWardChange(e)}
                          value={
                            !isNaN(+selectedWard) ? parseInt(selectedWard) : ""
                          }
                          style={{ marginBottom: "1%" }}
                          options={wards.data?.map(wardToOption) || []}
                        />
                      </div>
                    </div>
                  }
                  pagination={true}
                  columns={[
                    ...columns,
                    {
                      name: t("addbed.table.delete"),
                      cell: DeleteIcon,
                    },
                  ]}
                  data={
                    wardList?.filter((ward: any) =>
                      !selectedWard || selectedWard === "All Wards"
                        ? ward
                        : ward.ByWardId === parseInt(selectedWard)
                    ) || []
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={rowDelete !== undefined}>
        <Modal.Header closeButton>
          <Modal.Title>{t("users.new.delete")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{t("users.new.sure")}</p>
        </Modal.Body>

        <Modal.Footer>
          <IsanButton onClick={onDeleteCancel} text="cancel" />
          <IsanButton onClick={onDeleteClick} text="Delete" />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BedList;
