import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// import { useTranslation } from "react-i18next";

// i18n.use(LanguageDetector).init({
i18n.use(initReactI18next).init({
  // debug: false,
  lng: "en",
  // locales: ["es", "da", "de", "nb"],
  defaultNS: ["translations"],
  fallbackLng: "en",
  ns: ["translations"],
  // resources: messages,
  resources: {
    da: {
      translations: require("../../src/i18n/locales/da.json"),
    },
    de: {
      translations: require("../../src/i18n/locales/de.json"),
    },
    en: {
      translations: require("../../src/i18n/locales/en.json"),
    },
    nb: {
      translations: require("../../src/i18n/locales/nb.json"),
    },
  },
});

i18n.languages = ["da", "de", "en", "nb"];

const changeLang = (language) => {};
export { i18n, changeLang };

// uk = english = en
// denmark = Danish (dansk) = da
// norway = Norwegian (norsk) = nb
// germany = German (Deutsche) = de

// [
// 	{
// 	  "language": "English",
// 	  "symbol": "en",
// 	  "text": "Patient Status Engine"
// 	},
// 	{
// 	  "language": "German",
// 	  "symbol": "de",
// 	  "text": "Patientenstatus-Engine"
// 	},
// 	{
// 	  "language": "Norwegian",
// 	  "symbol": "no",
// 	  "text": "Pasientstatusmotor"
// 	},
// 	{
// 	  "language": "Danish",
// 	  "symbol": "da",
// 	  "text": "Patient Status Engine"
// 	}
//   ]
