const chartTitle = [
  {
    title: "patientMeasurementHeartRate",
    vitalType: 1,
    chartTitle: "Heart Rate (bpm)",
  },
  {
    title: "patientMeasurementRespirationRate",
    vitalType: 2,
    chartTitle: "Respiration Rate (breath/min)",
  },
  {
    title: "patientMeasurementTemperature",
    vitalType: 3,
    chartTitle: "Temperature (°C)",
  },
  {
    title: "patientMeasurementSpO2",
    vitalType: 4,
    chartTitle: "SpO2 (%)",
  },
  {
    title: "patientMeasurementBloodPressure",
    vitalType: 5,
    chartTitle: "Blood Pressure",
  },
  {
    title: "patientMeasurementHeartbeat",
    vitalType: 7,
    chartTitle: "Heart Beat",
  },
  {
    title: "patientMeasurementEws",
    vitalType: 8,
    chartTitle: "Ews",
  },
  {
    title: "patientMeasurementWeight",
    vitalType: 23,
    chartTitle: "Weight Scale",
  },
];

const vitalTypes = {
  patientMeasurementHeartRate: 1,
  patientMeasurementRespirationRate: 2,
  patientMeasurementTemperature: 3,
  patientMeasurementSpO2: 4,
  patientMeasurementBloodPressure: 5,
  patientMeasurementHeartbeat: 7,
  patientMeasurementEws: 8,
  patientMeasurementPatientOrientation: 9,
  patientMeasurementSupplementalOxygenLevel: 10,
  patientMeasurementConsciousnessLevel: 11,
  patientMeasurementBatteryPercentage: 12,
  patientMeasurementAnnotation: 14,
  patientMeasurementSetupModeLog: 15,
  patientMeasurementCapillaryRefilTime: 16,
  patientMeasurementFamilyOrNurseConcern: 17,
  patientMeasurementRespirationDistress: 18,
  patientMeasurementSetupMode: 19,
  patientMeasurementRawAccelerometer: 20,
  patientMeasurementSpO2Intermediate: 21,
  patientMeasurementConnectionEvent: 22,
  patientMeasurementWeight: 23,
};

export { chartTitle, vitalTypes };

export const dataBase = [
  {
    bloodPressureMeasurements: [
      {
        deviceSessionId: 879,
        deviceType: 0,
        display: null,
        display2: null,
        measurementExpiryTimestamp: "2024-06-01T10:56",
        patientMeasurement: 5,
        patientSessionId: 0,
        reading: 189,
        reading2: 130,
        score: 0,
        sensorType: 4,
        timeStamp: "10:56 am",
        timeStamps: "2024-06-01T06:56",
        unixTimeStamp: 1718103318121,
        vitalSignState: 0,
      },
      {
        reading: 59,
        reading2: 43,
        timeStamp: "10:57 am",
        unixTimeStamp: 1718103319121,
        measurementExpiryTimestamp: "2024-06-01T10:57",
        deviceSessionId: 879,
        deviceType: 0,
        display: null,
        display2: null,
        patientMeasurement: 5,
        patientSessionId: 0,
        score: 0,
        sensorType: 4,
        timeStamps: "2024-06-01T06:57",
        vitalSignState: 0,
      },
      {
        reading: 125,
        reading2: 72,
        timeStamp: "10:58 am",
        unixTimeStamp: 1718103320121,
        measurementExpiryTimestamp: "2024-06-01T10:58",
        deviceSessionId: 879,
        deviceType: 0,
        display: null,
        display2: null,
        patientMeasurement: 5,
        patientSessionId: 0,
        score: 0,
        sensorType: 4,
        timeStamps: "2024-06-01T06:58",
        vitalSignState: 0,
      },
      {
        reading: 125,
        reading2: 72,
        timeStamp: "10:59 am",
        unixTimeStamp: 1718103320121,
        measurementExpiryTimestamp: "2024-06-01T10:59",
        deviceSessionId: 879,
        deviceType: 0,
        display: null,
        display2: null,
        patientMeasurement: 5,
        patientSessionId: 0,
        score: 0,
        sensorType: 4,
        timeStamps: "2024-06-01T06:59",
        vitalSignState: 0,
      },
    ],
  },
  {
    earlyWarnings: [],
  },
  {
    heartRateMeasurements: [
      {
        deviceSessionId: 876,
        deviceType: 0,
        display: null,
        display2: null,
        measurementExpiryTimestamp: "2024-06-01T10:55",
        patientMeasurement: 1,
        patientSessionId: 0,
        reading: 193,
        reading2: 0,
        score: 3,
        sensorType: 1,
        timeStamp: "10:55 am",
        timeStamps: "2024-06-01T10:55",
        unixTimeStamp: 1718103231286,
        vitalSignState: 0,
      },
      {
        reading: 199,
        reading2: 0,
        timeStamp: "10:56 am",
        unixTimeStamp: 1718103232286,
        measurementExpiryTimestamp: "2024-06-01T10:56",
        deviceSessionId: 876,
        deviceType: 0,
        display: null,
        display2: null,
        patientMeasurement: 1,
        patientSessionId: 0,
        score: 3,
        sensorType: 1,
        timeStamps: "2024-06-01T10:56",
        vitalSignState: 0,
      },
      {
        reading: 194,
        reading2: 0,
        timeStamp: "10:57 am",
        unixTimeStamp: 1718103233286,
        measurementExpiryTimestamp: "2024-06-01T10:57",
        deviceSessionId: 876,
        deviceType: 0,
        display: null,
        display2: null,
        patientMeasurement: 1,
        patientSessionId: 0,
        score: 3,
        sensorType: 1,
        timeStamps: "2024-06-01T10:57",
        vitalSignState: 0,
      },
      {
        reading: 194,
        reading2: 0,
        timeStamp: "10:58 am",
        unixTimeStamp: 1718103233286,
        measurementExpiryTimestamp: "2024-06-01T10:58",
        deviceSessionId: 876,
        deviceType: 0,
        display: null,
        display2: null,
        patientMeasurement: 1,
        patientSessionId: 0,
        score: 3,
        sensorType: 1,
        timeStamps: "2024-06-01T10:58",
        vitalSignState: 0,
      },
      {
        reading: 194,
        reading2: 0,
        timeStamp: "10:59 am",
        unixTimeStamp: 1718103233286,
        measurementExpiryTimestamp: "2024-06-01T10:59",
        deviceSessionId: 876,
        deviceType: 0,
        display: null,
        display2: null,
        patientMeasurement: 1,
        patientSessionId: 0,
        score: 3,
        sensorType: 1,
        timeStamps: "2024-06-01T10:59",
        vitalSignState: 0,
      },
    ],
  },
  {
    respirationRateMeasurements: [
      {
        deviceSessionId: 876,
        deviceType: 0,
        display: null,
        display2: null,
        measurementExpiryTimestamp: "2024-06-01T10:55",
        patientMeasurement: 1,
        patientSessionId: 0,
        reading: 193,
        reading2: 0,
        score: 3,
        sensorType: 1,
        timeStamp: "10:55 am",
        timeStamps: "2024-06-01T10:55",
        unixTimeStamp: 1718103231286,
        vitalSignState: 0,
      },
      {
        reading: 199,
        reading2: 0,
        timeStamp: "10:56 am",
        unixTimeStamp: 1718103232286,
        measurementExpiryTimestamp: "2024-06-01T10:56",
        deviceSessionId: 876,
        deviceType: 0,
        display: null,
        display2: null,
        patientMeasurement: 1,
        patientSessionId: 0,
        score: 3,
        sensorType: 1,
        timeStamps: "2024-06-01T10:56",
        vitalSignState: 0,
      },
      {
        reading: 194,
        reading2: 0,
        timeStamp: "10:57 am",
        unixTimeStamp: 1718103233286,
        measurementExpiryTimestamp: "2024-06-01T10:57",
        deviceSessionId: 876,
        deviceType: 0,
        display: null,
        display2: null,
        patientMeasurement: 1,
        patientSessionId: 0,
        score: 3,
        sensorType: 1,
        timeStamps: "2024-06-01T10:57",
        vitalSignState: 0,
      },
    ],
  },
  {
    temperatureMeasurements: [
      {
        deviceSessionId: 876,
        deviceType: 0,
        display: null,
        display2: null,
        measurementExpiryTimestamp: "2024-06-01T10:55",
        patientMeasurement: 1,
        patientSessionId: 0,
        reading: 193,
        reading2: 0,
        score: 3,
        sensorType: 1,
        timeStamp: "10:55 am",
        timeStamps: "2024-06-01T10:55",
        unixTimeStamp: 1718103231286,
        vitalSignState: 0,
      },
      {
        reading: 199,
        reading2: 0,
        timeStamp: "10:56 am",
        unixTimeStamp: 1718103232286,
        measurementExpiryTimestamp: "2024-06-01T10:56",
        deviceSessionId: 876,
        deviceType: 0,
        display: null,
        display2: null,
        patientMeasurement: 1,
        patientSessionId: 0,
        score: 3,
        sensorType: 1,
        timeStamps: "2024-06-01T10:53",
        vitalSignState: 0,
      },
      {
        reading: 194,
        reading2: 0,
        timeStamp: "10:57 am",
        unixTimeStamp: 1718103233286,
        measurementExpiryTimestamp: "2024-06-01T10:57",
        deviceSessionId: 876,
        deviceType: 0,
        display: null,
        display2: null,
        patientMeasurement: 1,
        patientSessionId: 0,
        score: 3,
        sensorType: 1,
        timeStamps: "2024-06-01T10:53",
        vitalSignState: 0,
      },
    ],
  },
  {
    weightScaleMeasurements: [
      {
        deviceSessionId: 876,
        deviceType: 0,
        display: null,
        display2: null,
        measurementExpiryTimestamp: "2024-06-01T10:55",
        patientMeasurement: 1,
        patientSessionId: 0,
        reading: 193,
        reading2: 0,
        score: 3,
        sensorType: 1,
        timeStamp: "10:55 am",
        timeStamps: "2024-06-01T10:55",
        unixTimeStamp: 1718103231286,
        vitalSignState: 0,
      },
      {
        reading: 199,
        reading2: 0,
        timeStamp: "10:56 am",
        unixTimeStamp: 1718103232286,
        measurementExpiryTimestamp: "2024-06-01T10:56",
        deviceSessionId: 876,
        deviceType: 0,
        display: null,
        display2: null,
        patientMeasurement: 1,
        patientSessionId: 0,
        score: 3,
        sensorType: 1,
        timeStamps: "2024-06-17T10:56",
        vitalSignState: 0,
      },
      {
        reading: 194,
        reading2: 0,
        timeStamp: "10:57 am",
        unixTimeStamp: 1718103233286,
        measurementExpiryTimestamp: "2024-06-19T10:57",
        deviceSessionId: 876,
        deviceType: 0,
        display: null,
        display2: null,
        patientMeasurement: 1,
        patientSessionId: 0,
        score: 3,
        sensorType: 1,
        timeStamps: "2024-06-19T10:57",
        vitalSignState: 0,
      },
      {
        reading: 194,
        reading2: 0,
        timeStamp: "10:58 am",
        unixTimeStamp: 1718103233286,
        measurementExpiryTimestamp: "2024-06-19T10:58",
        deviceSessionId: 876,
        deviceType: 0,
        display: null,
        display2: null,
        patientMeasurement: 1,
        patientSessionId: 0,
        score: 3,
        sensorType: 1,
        timeStamps: "2024-06-19T10:58",
        vitalSignState: 0,
      },
      {
        reading: 194,
        reading2: 0,
        timeStamp: "10:59 am",
        unixTimeStamp: 1718103233286,
        measurementExpiryTimestamp: "2024-06-19T10:59",
        deviceSessionId: 876,
        deviceType: 0,
        display: null,
        display2: null,
        patientMeasurement: 1,
        patientSessionId: 0,
        score: 3,
        sensorType: 1,
        timeStamps: "2024-06-19T10:59",
        vitalSignState: 0,
      },
    ],
  },
];
