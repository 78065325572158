import * as signalR from "@microsoft/signalr";

const jsonProtocol = new signalR.JsonHubProtocol();

class Custom implements signalR.IHubProtocol {
  name = "json";
  version = 1;
  transferFormat = 1;
  // headers = {
  //   Authorization: `Bearer ${JSON.parse(localStorage.getItem('userDetail')!)}`
  // };
  parseMessages = (i: string, l: signalR.ILogger) => {
    return jsonProtocol.parseMessages(i, l);
  };
  writeMessage = (p: signalR.HubMessage) => {
    return jsonProtocol.writeMessage(p);
  };
}

function createConnection(url: string) {
  const userDetails = JSON.parse(localStorage.getItem("userDetail")!);

  const hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(url, { accessTokenFactory: () => userDetails.token }) // passed jwt token
    .configureLogging(signalR.LogLevel.Debug)
    .withHubProtocol(new Custom())
    .build();
  // Starts the SignalR connection

  return hubConnection;
}

export { createConnection };
