import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const PatientSummaryDelete = (props: any) => {
  const [patientData, setPatientdata] = useState<any>(props?.patientData);

  useEffect(() => {
    setPatientdata(props?.patientData);
  }, [props?.patientData]);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ width: "auto" }}
      >
        <DialogTitle id="alert-dialog-title">Delete Patient ID</DialogTitle>
        <DialogContent>
          <div
            className="form-group mb-3 validated "
            style={{
              width: "auto",
              padding: "inherit",
              background: "antiquewhite",
            }}
          >
            <div>
              <h3>Patient ID: {patientData?.byPatientId}</h3>
            </div>
            <div>
              <h3>Ward: {patientData?.wardName}</h3>
            </div>
            <div>
              <h3>Bed: {patientData?.bedName}</h3>
            </div>
            <div>
              <h3>
                Session Start:{" "}
                {moment(patientData?.patientStartSessionTime).format(
                  "dddd Do MMMM YYYY, h:mm"
                )}
              </h3>
            </div>
            <div>
              <h3>
                Session End:{" "}
                {moment(patientData?.patientEndSessionTime).format(
                  "dddd Do MMMM YYYY, h:mm"
                )}
              </h3>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className="btn btn-danger"
            onClick={() => props.deleteDetail(patientData)}
          >
            Delete
          </Button>
          <Button className="btn btn-warning" onClick={props.handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PatientSummaryDelete;
