import React, { useEffect } from "react";
import { transitions, positions, Provider as AlertProvider } from "react-alert";

//@ts-expect-error
import AlertTemplate from "react-alert-template-basic";
import Login from "./pages/Account/login";
import Dashboard from "./pages/Administration/Index";
import Historical from "./pages/Administration/Historical/index";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RequireAuth } from "./components/app/RequireAuth";
import Logout from "./pages/logout/Logout.page";
import BedList from "./pages/Administration/BedList";
import AddBed from "./pages/Administration/AddBed";
import WardList from "./pages/Administration/WardList";
import AddWard from "./pages/Administration/AddWard";
import UserList from "./pages/Administration/UserList";
import AddUser from "./pages/Administration/AddUser";
import About from "./pages/Administration/About";
import PatientDetail from "./pages/Administration/PatientDetail";
import PatientSummary from "./pages/Administration/Historical/PatientSummary";
import { useGlobalVar } from "@/utils/getGlobalVar";
import { useGlobalState } from "./state";
import Wards from "./pages/Administration/Wards";

const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 3000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

const App = () => {
  const hook: any = useGlobalVar();
  const [isLogged] = useGlobalState("logged");

  useEffect(() => {
    const route = [
      { path: "/dashboard", title: "" },
      { path: "/dashboard/bedList", title: "bedList" },
      { path: "/patienddetail", title: "" },
      { path: "/historical", title: "" },
      { path: "/patientHistorysummary", title: "" },
      { path: "/about", title: "" },
      { path: "/dashboard/logout", title: "" },
      { path: "/dashboard/contactList", title: "contactList" },
      { path: "/dashboard/contactForm", title: "contactForm" },
      { path: "/dashboard/bedForm", title: "bedForm" },
      { path: "/dashboard/WardList", title: "WardList" },
      { path: "/dashboard/wardForm", title: "wardForm" },
      { path: "/dashboard/UserList", title: "UserList" },
      { path: "/dashboard/userForm", title: "userForm" },
    ];

    const currentRoute: any = route.find((route) =>
      window.location.pathname?.includes(route.path)
    );

    if (window.location.pathname === "/") {
      document.title = "Log in - Lifeguard";
    } else if (currentRoute && Object.keys(currentRoute).length > 0) {
      document.title = currentRoute.title;
    } else {
      document.title = "";
    }

    const getTimeout = hook?.user?.userCapabilities?.find(
      (data: any) => data.capabilityName === "Login Timeout"
    );

    var sessionTimeOutInMinutes = getTimeout?.userCapabilityValue / 60;
    var idleTime = 0;
    const logoutUser = () => {
      idleTime = idleTime + 1;

      if (idleTime > sessionTimeOutInMinutes && isLogged) {
        localStorage.removeItem("userDetail");
        localStorage.removeItem("logged");
        window.location.href = "http://localhost:3000/";
      }
    };
    window.addEventListener("mousemove", () => {
      resetIdleTime();
    });
    window.addEventListener("scroll", () => {
      resetIdleTime();
    });
    window.addEventListener("keydown", () => {
      resetIdleTime();
    });

    window.setInterval(logoutUser, 60 * 1000);

    const resetIdleTime = () => {
      idleTime = 0;
    };
  }, [hook?.user, isLogged]);

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/patienddetail"
            element={
              <RequireAuth>
                <PatientDetail />
              </RequireAuth>
            }
          />
          <Route
            path="/historical"
            element={
              <RequireAuth>
                <Historical />
              </RequireAuth>
            }
          />
          <Route
            path="/patientHistorysummary"
            element={
              <RequireAuth>
                <PatientSummary />
              </RequireAuth>
            }
          />
          <Route
            path="/about"
            element={
              <RequireAuth>
                <About />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/logout"
            element={
              <RequireAuth>
                <Logout />
              </RequireAuth>
            }
          />

          <Route
            path="/dashboard/bedList"
            element={
              <RequireAuth>
                <BedList />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/bedForm/:id"
            element={
              <RequireAuth>
                <AddBed />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/bedForm"
            element={
              <RequireAuth>
                <AddBed />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/WardList"
            element={
              <RequireAuth>
                <WardList />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/wardForm"
            element={
              <RequireAuth>
                <AddWard />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/UserList"
            element={
              <RequireAuth>
                <UserList />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/userForm"
            element={
              <RequireAuth>
                <AddUser />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/userForm/:id"
            element={
              <RequireAuth>
                <AddUser />
              </RequireAuth>
            }
          />
          <Route
            path="/wards"
            element={
              <RequireAuth>
                <Wards />
              </RequireAuth>
            }
          />
        </Routes>
      </Router>
    </AlertProvider>
  );
};

export default App;
