import {
  Breadcum,
  IsanButton,
  IsanErrorLabel,
  IsanSelect,
  IsanInput,
  TopBar,
} from "@/components";
import { useFormik } from "formik";
// import useAxios from "axios-hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
// import { AxiosRequestConfig } from "axios";
import { useTranslation } from "react-i18next";
import APIHelper from "@/utils/ApiHelper";

function AddUser() {
  let [searchParams] = useSearchParams();
  const [fetchReq, setReq] = useState<any>([]);
  const alert = useAlert();
  const navigate = useNavigate();
  const goTo = () => navigate("/dashboard/userList");

  const formik = useFormik({
    initialValues: {
      userName: "",
      email: "",
      password: "",
      byUserId: 0,
      authKey: "",
      userDetailsId: 0,
      status: 0,
      confirmPassword: "",
      download: false,
      historic: false,
      roleType: "",
      isDeleted: 0,
    },

    onSubmit: async (values) => {
      const {
        userName,
        password,
        confirmPassword,
        email,
        roleType,
        byUserId,
        userDetailsId,
      } = values;
      const body = {
        userName,
        password,
        confirmPassword,
        email,
        roleType: parseInt(roleType ?? 0),
        byUserId,
        status: 1,
        userDetailsId,
      };
      try {
        const config = {
          data: body,
        };
        let alertMessage = t("users.alertmessage.create");
        const userId = searchParams.get("userDetailsId");
        if (userId) {
          // config.method = "POST";
          // config.url = "/Administration/UpdateUserInfo";
          APIHelper.updateUser(config)
            .then(() => {
              alertMessage = t("users.alertmessage.edit");
            })
            .catch((error: any) => {
              console.log("error => ", error);
            });
        }

        // const getPostedData =
        await APIHelper.registerUser(config)
          .then((data: any) => {
            setReq(data);
            alert.success(alertMessage);
          })
          .catch((error: any) => {
            console.log("error =>", error);
          });
        goTo();
      } catch (err) {
        alert.error(t("users.alertmessage.error"));
      }
    },
  });

  useEffect(() => {
    const userDetail: any = localStorage.getItem("userDetail");
    const { authorisationKey, userDetailsId } = JSON.parse(userDetail);
    formik.setFieldValue("authKey", authorisationKey);
    formik.setFieldValue("byUserId", userDetailsId);

    if (searchParams.get("userDetailsId")) {
      formik.setFieldValue("userDetailsId", searchParams.get("userDetailsId"));
      formik.setFieldValue("byUserId", searchParams.get("byUserId"));
      const role = searchParams.get("roleType");
      if (role) {
        formik.setFieldValue("roleType", parseInt(role, 10));
      }
      formik.setFieldValue("email", searchParams.get("email"));
      formik.setFieldValue("userName", searchParams.get("userName"));
      formik.setFieldValue("status", searchParams.get("status"));
      formik.setFieldValue("isDeleted", searchParams.get("isDeleted"));
    }
  }, [searchParams, formik]);

  const { t } = useTranslation();

  return (
    <>
      <div
        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
        id="kt_wrapper"
      >
        <TopBar />
        <div style={{ display: "block" }}>
          <Breadcum />
        </div>
        <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className="kt-portlet kt-portlet--mobile">
              <div className="kt-portlet__body kt-portlet__body--fit">
                <div className="kt-form kt-form--label-right">
                  <div className="kt-form__body">
                    <div className="text-center h3 mt-4 mb-4">
                      {t("users.addnew.add")}
                    </div>
                    <div className="card card-custom gutter-b example example-compact">
                      <div className="card-body">
                        <form onSubmit={formik.handleSubmit}>
                          <div className="form-group mb-3 validated">
                            <label htmlFor="User Name">
                              {t("users.addnew.name")}
                            </label>
                            <IsanInput
                              id="User Name"
                              name="userName"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.userName}
                            />
                            <IsanErrorLabel
                              inputTouched={formik.touched.userName}
                              text={formik.errors.userName}
                            />
                          </div>
                          <div className="form-group mb-3 validated">
                            <label htmlFor="Password">
                              {t("users.addnew.password")}
                            </label>
                            <IsanInput
                              id="Password"
                              name="password"
                              type="password"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.password}
                            />
                            <IsanErrorLabel
                              inputTouched={formik.touched.password}
                              text={formik.errors.password}
                            />
                          </div>
                          <div className="form-group mb-3 validated">
                            <label htmlFor="CPassword">
                              {t("users.addnew.Cpassword")}
                            </label>
                            <IsanInput
                              id="CPassword"
                              name="confirmPassword"
                              type="password"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.confirmPassword}
                            />
                            <IsanErrorLabel
                              inputTouched={formik.touched.confirmPassword}
                              text={formik.errors.confirmPassword}
                            />
                          </div>
                          <div className="form-group mb-3 validated">
                            <label htmlFor="email">
                              {t("users.addnew.email")}
                            </label>
                            <IsanInput
                              id="email"
                              name="email"
                              type="email"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.email}
                            />
                            <IsanErrorLabel
                              inputTouched={formik.touched.email}
                              text={formik.errors.email}
                            />
                          </div>
                          <div className="form-group mb-3 validated">
                            <label htmlFor="RoleType">
                              {t("users.addnew.role")}
                            </label>
                            <IsanSelect
                              id="RoleType"
                              name="roleType"
                              type="byWardId"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.roleType}
                              options={[
                                { label: "Administrator", value: 1 },
                                { label: "Nurse", value: 2 },
                                { label: "Doctor", value: 3 },
                                { label: "Lab Assistance", value: 3 },
                              ]}
                            />
                            <IsanErrorLabel
                              inputTouched={formik.touched.roleType}
                              text={formik.errors.roleType}
                            />
                          </div>
                          <div className="form-group d-flex mb-3 validated">
                            <div className="mr-3">
                              <input
                                type="checkbox"
                                name="historic"
                                id="historic"
                              />
                              <IsanErrorLabel
                                inputTouched={formik.touched.email}
                                text={formik.errors.email}
                              />
                            </div>
                            <label htmlFor="Enable" className="mr-2">
                              {t("users.addnew.enable")}
                            </label>
                          </div>
                          <div className="form-group d-flex mb-3 validated">
                            <div className="mr-3">
                              <input
                                type="checkbox"
                                name="download"
                                id="download"
                              />
                              <IsanErrorLabel
                                inputTouched={formik.touched.email}
                                text={formik.errors.email}
                              />
                            </div>
                            <label htmlFor="Historic" className="mr-2">
                              {t("users.addnew.history")}
                            </label>
                          </div>
                          <div className="form-group d-flex mb-3 validated">
                            <div className="mr-3">
                              <input
                                type="checkbox"
                                name="download"
                                id="download"
                              />
                              <IsanErrorLabel
                                inputTouched={formik.touched.email}
                                text={formik.errors.email}
                              />
                            </div>
                            <label htmlFor="Logout" className="mr-2">
                              {t("users.addnew.auto")}
                            </label>
                          </div>
                          <IsanButton
                            loading={fetchReq.loading}
                            type="submit"
                            text={t("users.addnew.submit")}
                          />
                          &nbsp;
                          <IsanButton
                            disabled={fetchReq.loading}
                            type="submit"
                            text={t("users.addnew.cancle")}
                            onClick={goTo}
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddUser;
