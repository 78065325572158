import { Breadcum, IsanButton, TopBar } from "@/components";
import { Button, Modal } from "react-bootstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import { createSearchParams, useNavigate } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { useEffect, useState } from "react";
// import useAxios from "axios-hooks";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useAlert } from "react-alert";
import SelectWardModal from "./SelectWardModal";
import { Backend_Url } from "@/utils/AxiosBootstrap";
import { createConnection } from "@/utils/signarrConnection";
import Loader from "@/Loader/Loader";
import APIHelper, { doDelete } from "@/utils/ApiHelper";

type Users = {
  UserDetailsId: number;
  ByUserId: number;
  RoleType: number;
  Email: string;
  UserName: string;
  Status: number;
  IsDeleted: number;
  Timestamp: string;
};

const Status: Record<number, string> = {
  0: "Enabled",
  1: "Disabled",
};

const Role: Record<number, string> = {
  1: "Administrator",
  2: "Nurse",
  3: "Doctor",
  4: "Lab Assistance",
};

const EditIconComponent = (row: Users) => {
  const navigate = useNavigate();
  const params = createSearchParams(row as unknown as Record<string, string>);
  const goTo = () => navigate("/dashboard/userForm?" + params);
  return <EditIcon onClick={goTo} />;
};

function UserList() {
  const { t } = useTranslation();
  const [rowDelete, setRowDelete] = useState<Users | undefined>(undefined);
  const [selectWard, setSelectWard] = useState<any>({ open: false });
  const [connection, setConnection] = useState<any>({});
  // const [loading, setLoading] = useState(false);
  const [getUserDetail, setUserDetail] = useState<any>([]);
  const navigate = useNavigate();
  const alert = useAlert();
  const goTo = () => navigate("/dashboard/userForm");

  const fetch = () => {
    const payload = {
      userDetailsId: 0,
      byUserId: 0,
      roleType: 0,
      password: "string",
      email: "string",
      userName: "string",
      status: 0,
      isDeleted: 0,
      timestamp: "2024-01-13T05:52:25.731Z",
    };
    APIHelper.GetUserDetails(payload)
      .then((data) => {
        setUserDetail(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
    // setLoading(false);
  };

  const handleSelectWard = (row?: any) => {
    const { open } = selectWard;
    setSelectWard({ ...selectWard, open: !open, user: row });
  };

  useEffect(() => {
    const connection = createConnection(`${Backend_Url}/signalr`);
    setConnection(connection);
    connection.start().then((a) => {});
  }, []);

  const DeleteIcon = (row: Users) => {
    const markForDelete = () => {
      setRowDelete(row);
    };

    return (
      <FontAwesomeIcon
        onClick={markForDelete}
        icon={faTrash}
        style={{ marginLeft: "15px" }}
      />
    );
  };

  const columns: TableColumn<Users>[] = [
    {
      name: t("users.table.userid"),
      selector: (row) => row.UserDetailsId,
      sortable: true,
    },
    {
      name: t("users.table.username"),
      selector: (row) => row.UserName,
      sortable: true,
    },
    {
      name: t("users.table.email"),
      selector: (row) => row.Email,
      sortable: true,
    },
    {
      name: t("users.table.role"),
      selector: (row) => Role[row.RoleType],
      sortable: true,
    },
    {
      name: t("users.table.status"),
      cell: (row) => (
        <span style={{ color: row.Status === 1 ? "red" : "green" }}>
          {Status[row.Status]}
        </span>
      ),
      sortable: true,
    },
    {
      name: t("users.table.wardsselection"),
      cell: (row) =>
        row?.RoleType !== 1 ? (
          <Button
            className="btn btn-primary"
            onClick={() => handleSelectWard(row)}
          >
            {t("users.table.selectward")}
          </Button>
        ) : (
          <></>
        ),
    },
    {
      name: t("users.table.edit"),
      cell: EditIconComponent,
    },
    {
      name: t("users.table.delete"),
      cell: DeleteIcon,
    },
  ];

  const onDeleteClick = () => {
    const userDetail: any = localStorage.getItem("userDetail");
    const { authorisationKey, userDetailsId } = JSON.parse(userDetail);
    const deleteRow = {
      ...rowDelete,
      isDeleted: 1,
      authKey: authorisationKey,
      byUserId: userDetailsId,
    };
    doDelete("/Administration/DeleteUser", deleteRow).then(() => {
      alert.success(t("users.alertmessage.delete"));
      setRowDelete(undefined);
      fetch();
    });
  };

  const onDeleteCancel = () => {
    setRowDelete(undefined);
  };

  useEffect(() => {
    // setLoading(true);
    fetch();
  }, []);

  useEffect(() => {
    APIHelper.getWards()
      .then((data: any) => {
        console.log("data -->", data);
      })
      .catch((err: any) => {
        console.log("err =====>", err);
      });
  }, []);
  return getUserDetail.loading ? (
    <Loader />
  ) : (
    <>
      <div
        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
        id="kt_wrapper"
      >
        <TopBar />
        <Breadcum />
        <SelectWardModal
          open={selectWard?.open}
          handleClose={handleSelectWard}
          user={selectWard?.user}
          connection={connection}
        />
        <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className="kt-portlet kt-portlet--mobile">
              <div
                className="kt-portlet__body kt-portlet__body--fit"
                style={{ marginTop: "13px" }}
              >
                <DataTable
                  progressPending={getUserDetail.loading}
                  title={
                    <IsanButton onClick={goTo} text={t("users.new.btn")} />
                  }
                  pagination={true}
                  columns={[...columns]}
                  data={getUserDetail.data || []}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={rowDelete !== undefined}>
        <Modal.Header closeButton>
          <Modal.Title>{t("users.new.delete")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{t("users.new.sure")}</p>
        </Modal.Body>

        <Modal.Footer>
          <IsanButton onClick={onDeleteCancel} text="cancel" />
          <IsanButton onClick={onDeleteClick} text="Delete" />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UserList;
