import nacl from "tweetnacl";
import util from "tweetnacl-util";

function newNonce() {
  return nacl.randomBytes(nacl.box.nonceLength);
}

export function encrypt(secretOrSharedKey: any, plaintext: any, key: any) {
  const nonce = newNonce();
  const messageUint8 = util.decodeUTF8(plaintext);
  const encryptedBytes = key
    ? nacl.box(messageUint8, nonce, key, secretOrSharedKey)
    : nacl.box.after(messageUint8, nonce, secretOrSharedKey);

  const fullMessage = new Uint8Array(nonce.length + encryptedBytes.length);
  fullMessage.set(nonce);
  fullMessage.set(encryptedBytes, nonce.length);

  const base64FullMessage = util.encodeBase64(fullMessage);
  return base64FullMessage;
}

export function decrypt(
  secretOrSharedKey: any,
  messageWithNonceBase64: any,
  key?: any
) {
  const messageWithNonceAsUint8Array = util.decodeBase64(
    messageWithNonceBase64
  );
  const nonce = messageWithNonceAsUint8Array.slice(0, nacl.box.nonceLength);
  const message = messageWithNonceAsUint8Array.slice(
    nacl.box.nonceLength,
    messageWithNonceBase64.length
  );

  const decrypted = key
    ? nacl.box.open(message, nonce, key, secretOrSharedKey)
    : nacl.box.open.after(message, nonce, secretOrSharedKey);

  if (!decrypted) {
    throw new Error("Could not decrypt message");
  }

  const plaintext = util.encodeUTF8(decrypted);
  return plaintext;
}
