import * as React from "react";
import { TopBar, Breadcum, IsanSelect } from "@/components";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
} from "@material-ui/core";
import { createConnection } from "@/utils/signarrConnection";
import { useTranslation } from "react-i18next";
import cross from "@/assets/cross.png";
import LeadsOff from "@/assets/LeadsOff.png";
import outofrange from "@/assets/outofrange.png";
import lowbattery from "@/assets/lowbattery.png";
import moment from "moment";
import { Backend_Url } from "@/utils/AxiosBootstrap";
import { useGlobalVar } from "@/utils/getGlobalVar";
import { useStateContext } from "@/state";
import {
  canShowEwsLastDataReceivedAtXString,
  canShowEwsMeasurement,
  canShowLastData,
  canShowMeasurement,
  emptyBed,
  formatDate,
  formatWithOneDecimalPlace,
  getBloodPressureDeviceStatus,
  getDashboardVitalSignDisplayStatus,
  getDashboardVitalSignDisplayStatusIcon,
  getSensorStatusText,
  showDevelopmentCode,
  showVariableIfNotEmpty,
} from "@/utils/commonFunctions";
import nacl from "tweetnacl";
import util from "tweetnacl-util";
import { decrypt } from "@/utils/naclCrypto";

import APIHelper from "../../utils/ApiHelper";

interface Data {
  Ward: string;
  Bed: string;
  HeartRate: number;
  RespirationRate: string;
  Temperature: number;
  SpO2: string;
  BloodPressure: string;
  Weight: string;
  Consciousness: string;
  SupplementalOxygen: string;
  CapillaryRefillTime: string;
  FamilyOrNurseConcern: string;
  RespirationDistress: string;
  UrineOutput: string;
  EarlyWarningScore: string;
  AllData: any;
}

function createData(
  Ward: string,
  Bed: string,
  HeartRate: number,
  RespirationRate: string,
  Temperature: number,
  SpO2: string,
  BloodPressure: string,
  Weight: string,
  Consciousness: string,
  SupplementalOxygen: string,
  CapillaryRefillTime: string,
  FamilyOrNurseConcern: string,
  RespirationDistress: string,
  UrineOutput: string,
  EarlyWarningScore: string,
  AllData: any
): Data {
  return {
    Ward,
    Bed,
    HeartRate,
    RespirationRate,
    Temperature,
    SpO2,
    BloodPressure,
    Weight,
    Consciousness,
    SupplementalOxygen,
    CapillaryRefillTime,
    FamilyOrNurseConcern,
    RespirationDistress,
    UrineOutput,
    EarlyWarningScore,
    AllData,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "Ward",
    numeric: false,
    disablePadding: false,
    label: "Ward",
  },
  {
    id: "Bed",
    numeric: false,
    disablePadding: false,
    label: "Bed",
  },
  {
    id: "HeartRate",
    numeric: false,
    disablePadding: false,
    label: "Heart Rate",
  },
  {
    id: "RespirationRate",
    numeric: false,
    disablePadding: false,
    label: "Respiration Rate",
  },
  {
    id: "Temperature",
    numeric: false,
    disablePadding: false,
    label: "Temperature",
  },
  {
    id: "SpO2",
    numeric: false,
    disablePadding: false,
    label: "SpO2",
  },
  {
    id: "BloodPressure",
    numeric: false,
    disablePadding: false,
    label: "Blood Pressure",
  },
  {
    id: "Weight",
    numeric: false,
    disablePadding: false,
    label: "Weight",
  },
  {
    id: "Consciousness",
    numeric: false,
    disablePadding: false,
    label: "Consciousness Level",
  },
  {
    id: "SupplementalOxygen",
    numeric: false,
    disablePadding: false,
    label: "Supplemental Oxygen",
  },
  {
    id: "CapillaryRefillTime",
    numeric: false,
    disablePadding: false,
    label: "Capillary Refill Time",
  },
  {
    id: "FamilyOrNurseConcern",
    numeric: false,
    disablePadding: false,
    label: "Family Or Nurse Concern",
  },
  {
    id: "RespirationDistress",
    numeric: false,
    disablePadding: false,
    label: "Respiration Distress",
  },
  {
    id: "UrineOutput",
    numeric: false,
    disablePadding: false,
    label: "Urine Output",
  },
  {
    id: "EarlyWarningScore",
    numeric: false,
    disablePadding: false,
    label: "Early Warning Score",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    order,
    orderBy,

    onRequestSort,
    rowCount,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const { t } = useTranslation();

  return (
    <TableHead className="table-header">
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              paddingLeft: rowCount ? "16px" : "0px",
              paddingRight: rowCount ? "16px" : "0px",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {t(`dashboard.table.${headCell.label}`)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let currentDevice: any = [];
const wardToOption = (w: { WardName: string; WardDetailsId: number }) => {
  return { label: w.WardName, value: w.WardDetailsId };
};
export default function Dashboard() {
  const hook = useGlobalVar();
  const { configData, setConfigData } = useStateContext();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("Ward");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [dataDetail, setDataDetail] = React.useState<any>([]);
  const [dashboardDetail, setDashboardDetail] = React.useState<any>([]);
  const [connection, setConnection] = React.useState<any>({});
  const [showEmpty, setShowEmpty] = React.useState<boolean>(false);
  const [endSession, setEndSession] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState<any>([]);
  const [fetchWards] = React.useState<any>();
  const [getAllUserWards] = React.useState<any>([]);
  const { ward, setSelectedWard } = useGlobalVar();

  const [fetchWard, setWards] = React.useState<any>([]);

  React.useEffect(() => {
    const payload = {
      wardDetailsId: 0,
      byUserId: 0,
      wardName: "string",
      status: 0,
      isDeleted: 0,
      timestamp: "2024-01-16T07:07:43.724Z",
    };
    APIHelper.getWards(payload)
      .then((data: any) => {
        setWards(data);
      })
      .catch((error: any) => {
        console.log("error =>", error);
      });
  }, []);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const userDetail = JSON.parse(localStorage.getItem("userDetail")!);
  const keyPairFrontEnd = nacl.box.keyPair();

  const preparedDashboardTable = React.useCallback(
    async (data: any) => {
      if (typeof data === "string") {
        setLoading(true);

        const dashboardItemList = JSON.parse(data);

        const filteredData = JSON.parse(
          JSON.stringify(dashboardItemList)
        ).filter((item: any) => {
          return (
            item?.patientStartSessionTime !== "0001-01-01T00:00:00" &&
            item?.patientEndSessionTime !== "0001-01-01T00:00:00"
          );
        });

        const row = filteredData.map((val: any) =>
          createData(
            val.wardName,
            val.bedName,
            val?.heartRateLifetouch ?? " ",
            val?.respirationRateLifetouch ?? " ",
            val?.pulseOxSpO2 ?? " ",
            val?.temperature ?? " ",
            val?.bloodPressure ?? " ",
            val?.weight ?? " ",
            val?.manuallyEnteredConsciousnessLevel ?? " ",
            val?.manuallyEnteredSupplementalOxygenLevel ?? " ",
            val?.manuallyEnteredCapillaryRefillTime ?? " ",
            val?.manuallyEnteredFamilyOrNurseConcern ?? " ",
            val?.manuallyEnteredRespirationDistress ?? " ",
            val?.manuallyEnteredUrineOutput ?? " ",
            val?.earlyWarning ?? " ",
            val
          )
        );

        setRows(row);
        setDataDetail(dashboardItemList);
        setDashboardDetail(dashboardItemList);
        setLoading(false);
      }
    },
    [setLoading, setRows, setDataDetail, setDashboardDetail]
  );

  const onRequestDashboardApiCall = async () => {
    const data = {
      ConnectionId: connection?.connectionId ? connection?.connectionId : null,
      WardDetailsId: null,
      accessibleWardList: null,
      RoleType: hook?.user?.roleType,
      IsEmptyBed: true,
      frontEndPublicKey: util?.encodeBase64(keyPairFrontEnd.publicKey),
      Token: userDetail?.token,
    };
    try {
      const responseData = await APIHelper.requestIntialDashbord(data);
      const uint8Array = util.decodeBase64(responseData.data.publicKey);
      const sharedKey: any = nacl.box.before(
        uint8Array,
        keyPairFrontEnd.secretKey
      );
      const plaintext = await decrypt(sharedKey, responseData.data.payload);
      preparedDashboardTable(plaintext);
    } catch (error) {
      console.log("error =>", error);
    }
  };

  React.useEffect(() => {
    onRequestDashboardApiCall();
  }, [connection?.connectionId, userDetail?.token]);

  React.useEffect(() => {
    // establish SignalR the connection
    const connection: any = createConnection(`${Backend_Url}/signalr`);
    if (connection) onStart(connection);
    return () => {
      if (connection) connection.stop();
    };
  }, []);

  const onStart = async (connection: any) => {
    try {
      setConnection(connection);
    } catch (err) {}
  };

  //start the SignalR connection

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const { t } = useTranslation();

  // const startTime = moment().startOf("day");
  // const diffMinutes = moment.duration(moment().diff(startTime)).asMinutes();

  // const displayTimeStamp = (
  //   manually: any,
  //   data: any,
  //   patientData: any,
  //   value: any
  // ) => {
  //   const colours =
  //     patientData?.thresholdSet?.ThresholdSetAgeBlockDetails?.[0]
  //       ?.ThresholdSetColours;

  //   const decimalToHexcolorconvert = (d: any, padding?: any) => {
  //     var hex = Number(d).toString(16);
  //     padding =
  //       typeof padding === "undefined" || padding === null
  //         ? (padding = 2)
  //         : padding;
  //     while (hex.length < padding) {
  //       hex = "0" + hex;
  //     }
  //     return hex;
  //   };

  //   const dates = moment(data?.deviceEndSessionTime, "HH-mm-ss");
  //   const currentTime = moment();
  //   const diffMinutes = currentTime.diff(dates, "minutes");

  //   if (manually == null) {
  //     const color = colours?.find((color: any) => color?.Score === data?.score);

  //     const date = moment(data).format("HH-MM-SS");
  //     const displayDate = moment(data?.measurementExpiryTimestamp).format(
  //       "DD-MM-YYYY"
  //     );
  //     const backgroundcolor =
  //       color?.Colour == 0
  //         ? "#000"
  //         : color?.Colour == 1
  //         ? "#FFF"
  //         : decimalToHexcolorconvert(color?.Colour);
  //     const textColor =
  //       color?.TextColour == 0
  //         ? "#000"
  //         : color?.TextColour == 1
  //         ? "#FFF"
  //         : decimalToHexcolorconvert(color?.TextColour);

  //     if (diffMinutes != 10) {
  //       return (
  //         <TableCell align="center">
  //           {t("dashboard.table.last_updated")}
  //           <br />
  //           {displayDate}
  //         </TableCell>
  //       );
  //     } else {
  //       return data?.reading ? (
  //         <TableCell
  //           align="center"
  //           style={{
  //             backgroundColor: `#${backgroundcolor}40`,
  //             color: `#${backgroundcolor}`,
  //             padding: "8%",
  //             fontSize: "14pt",
  //             minWidth: "100px",
  //             fontWeight: "bolder",
  //             width: "8%",
  //           }}
  //         >
  //           {value === "Consciousness"
  //             ? patientData?.manuallyEnteredConsciousnessLevel?.display
  //             : value === "SupplementalOxygen"
  //             ? patientData?.manuallyEnteredSupplementalOxygenLevel?.display
  //             : data?.reading}
  //         </TableCell>
  //       ) : (
  //         <TableCell align="center"></TableCell>
  //       );
  //     }
  //   } else {
  //     const displayDate = moment(manually?.timeStamp).format("hh-mm-ss MMM Do");
  //     const color = colours?.find(
  //       (color: any) => color?.Score === manually?.score
  //     );
  //     const backgroundcolor =
  //       color?.Colour == 0
  //         ? "#000"
  //         : color?.Colour == 1
  //         ? "#FFF"
  //         : decimalToHexcolorconvert(color?.Colour);
  //     const textColor =
  //       color?.TextColour == 0
  //         ? "#000"
  //         : color?.TextColour == 1
  //         ? "#FFF"
  //         : decimalToHexcolorconvert(color?.TextColour);

  //     if (diffMinutes <= 10) {
  //       return (
  //         <TableCell align="center">
  //           {t("dashboard.table.last_updated")}
  //           <br />
  //           {displayDate}
  //         </TableCell>
  //       );
  //     } else {
  //       return manually?.reading ? (
  //         <TableCell
  //           align="center"
  //           style={{
  //             backgroundColor: `#${backgroundcolor}`,
  //             color: `#${textColor}`,
  //             padding: "8%",
  //             height: "30px",
  //             width: "80px",
  //           }}
  //         >
  //           {manually?.reading}
  //         </TableCell>
  //       ) : (
  //         <TableCell align="center"></TableCell>
  //       );
  //     }
  //   }
  // };

  const onRequestDashboard = React.useCallback(
    async (wardDetailId: any, userDetail: any, userWards: any) => {
      await connection.invoke("sendConnectionId", {
        connectionId: connection.connectionId,
        wardDetailsId: wardDetailId,
        accessibleWardList: hook.user.roleType === 1 ? null : wardDetailId,
        roleType: hook.user.roleType,
        isEmptyBed: true,
        token: userDetail.token,
      });
    },
    [connection, hook]
  );

  const statusIndicator = (indicator: number) => {
    if (indicator === 1) return "green";
    else if (indicator === 2) return "#FFD700";
    else if (indicator === 3) return "#f00";
    else return "#f00";
  };

  React.useEffect(() => {
    let interval: any = null;
    const { ward } = hook;

    const userDetail = JSON.parse(localStorage.getItem("userDetail")!);

    const selectedWard = fetchWards?.data?.find(
      (val: any) => val.wardDetailsId === ward
    );
    const wardDetailId = selectedWard ? selectedWard?.wardDetailsId : null;
    const userWards = getAllUserWards?.data?.map((val: any) => val.userWardsId);

    if (connection.connectionId) {
      if (connection.connectionId) {
        // interval = setInterval(() => {
        onRequestDashboard(wardDetailId, userDetail, userWards);
        // }, 5000);
      } else {
        const connection = createConnection(`${Backend_Url}/signalr`);
        setConnection(connection);
        // interval = setInterval(() => {
        onRequestDashboard(wardDetailId, userDetail, userWards);
        // }, 5000);
      }
      connection.on("pushBedDeviceSession", (data: any) => {
        if (data.length > 0) {
          data.forEach((device: any) => {
            currentDevice.push({
              key: device.deviceSessionId,
              value:
                moment.utc(device.deviceEndSessionTime) >
                moment.utc(device.deviceStartSessionTime),
            });
          });
        }
      });
      //call the mothod throughtSignalR
      connection.on("setClientMessage", (data: any) => {
        setLoading(false);
        preparedDashboardTable(data);
      });

      connection.on("pushDeviceSetupModeStatus", (payload: any) => {
        console.log("pushDeviceSetupModeStatus => ", payload);
      });

      connection.on("pushDeviceSetupModeStatus", (payload: any) => {
        console.log("pushDeviceSetupModeStatus => ", payload);
      });

      connection.on("pushRealTimeServerPing", (payload: any) => {
        console.log("pushRealTimeServerPing => ", payload);
      });

      connection.on("pushLeadsOffChange", (payload: any) => {
        console.log("pushLeadsOffChange => ", payload);
      });

      connection.on("pushPatientName", (payload: any) => {
        console.log("pushPatientName => ", payload);
      });

      connection.on("pushDashboardItem", (payload: any) => {
        console.log("pushDashboardItem => ", payload);
      });

      connection.on("connectivityStateChange", (payload: any) => {
        console.log("connectivityStateChange => ", payload);
      });

      connection.on("pushDeviceSetupModeStatus", (payload: any) => {
        console.log("pushDeviceSetupModeStatus => ", payload);
      });

      connection.on("pushDeviceRawAccelerometerModeStatus", (payload: any) => {
        console.log("pushDeviceRawAccelerometerModeStatus => ", payload);
      });

      connection.on("pushLifetouchSetupmodeData", (payload: any) => {
        console.log("pushLifetouchSetupmodeData => ", payload);
      });

      connection.on("pushOximeterSetupmodeData", (payload: any) => {
        console.log("pushOximeterSetupmodeData => ", payload);
      });

      connection.on("pushLifetouchAcceleromteterData", (payload: any) => {
        console.log("pushLifetouchAcceleromteterData => ", payload);
      });
    }

    return () => {
      clearInterval(interval);
    };
    // }
  }, [
    connection.connectionId,
    getAllUserWards,
    connection,
    fetchWards?.data,
    hook,
    onRequestDashboard,
    preparedDashboardTable,
  ]);

  // React.useEffect(() => {
  //   connection.on("pushDeviceSetupModeStatus", (payload: any) => {});
  // }, []);

  // React.useEffect(() => {
  //   const storedData: any = sessionStorage.getItem("dashboardData");

  //   if (storedData) {
  //     setLoading(false);
  //     preparedDashboardTable(storedData);
  //   } else {
  //     setLoading(true);
  //   }
  // }, []);

  React.useEffect(() => {
    setInterval(() => {
      const hours =
        new Date().getHours() > 9
          ? new Date().getHours()
          : `0${new Date().getHours()}`;
      const minute =
        new Date().getMinutes() > 9
          ? new Date().getMinutes()
          : `0${new Date().getMinutes()}`;
      const second =
        new Date().getSeconds() > 9
          ? new Date().getSeconds()
          : `0${new Date().getSeconds()}`;
      const time: any = `${hours}:${minute}:${second}`;
      const element: any = (document as any).getElementsByClassName(
        "timer"
      )?.[0];
      if (element) {
        element.innerText = time;
      }
    }, 1000);
  }, []);

  // const ShowEarlyWarningScore = (data: any) => {
  //   if (moment(data?.measurementExpiryTimestamp).isAfter(new Date())) {
  //     return data?.score ? `${data?.score}/${data?.maxScore}` : "";
  //   } else {
  //     return "";
  //   }
  // };

  const createDashboardRowTooltip = (patientData: any): string => {
    var result = patientData?.patientSessionStarted
      ? `Session Start Time: ${moment(
          patientData.patientStartSessionTime
        ).format("dddd, MMMM Do YYYY, h:mm")} \n\n`
      : "No Session";
    // const title = [
    //   "heartRateLifetouch",
    //   "respirationRateLifetouch",
    //   "temperature",
    //   "pulseOxSpO2",
    //   "bloodPressure",
    //   "earlyWarning",
    // ];
    // const filterTitle = title.filter((dataTitle) => {
    //   const data = patientData[dataTitle];
    //   const date = moment(data?.timeStamp).format("YYYY-MM-DD");
    //   const currentDate = moment(new Date()).format("YYYY-MM-DD");
    //   return moment(date).isBefore(moment(currentDate));
    // });
    // if (filterTitle.length) {
    //   return "";
    // } else {
    //   new Promise((resolve, reject) => {
    //     resolve(
    //       title.map((dataTitle) => {
    //         const rateData = patientData[dataTitle];
    //         result = result.concat(
    //           `Last measurement at ${moment(rateData?.timeStamp).format(
    //             "HH:mm:ss MMM Do"
    //           )} for ${dataTitle} \n`
    //         );
    //         return dataTitle;
    //       })
    //     );
    //   });
    return result;
    // }
  };

  // const formatTimestamp = (timestamp: any) => {
  //   return moment(timestamp).format("HH:mm:ss MMM Do");
  // };

  const handleChangeCheckBox = (e: any) => {
    setShowEmpty(e.target.checked);
    hook?.setSelectedWard(null);
    setPage(0);
    if (e.target.checked === true) {
      const filteredData = dataDetail;
      let rowData = filteredData.map((val: any) =>
        createData(
          val.wardName,
          val.bedName,
          val?.heartRateLifetouch ?? " ",
          val?.respirationRateLifetouch ?? " ",
          val?.pulseOxSpO2 ?? " ",
          val?.temperature ?? " ",
          val?.bloodPressure ?? " ",
          val?.weight ?? " ",
          val?.manuallyEnteredConsciousnessLevel ?? " ",
          val?.manuallyEnteredSupplementalOxygenLevel ?? " ",
          val?.manuallyEnteredCapillaryRefillTime ?? " ",
          val?.manuallyEnteredFamilyOrNurseConcern ?? " ",
          val?.manuallyEnteredRespirationDistress ?? " ",
          val?.manuallyEnteredUrineOutput ?? " ",
          val?.earlyWarning ?? " ",
          val
        )
      );
      setRows(JSON.parse(JSON.stringify(rowData)));
    } else {
      const filteredData = JSON.parse(JSON.stringify(dataDetail)).filter(
        (item: any) => {
          return (
            item?.patientStartSessionTime !== "0001-01-01T00:00:00" &&
            item?.patientEndSessionTime !== "0001-01-01T00:00:00"
          );
        }
      );

      let rowData = filteredData.map((val: any) =>
        createData(
          val.wardName,
          val.bedName,
          val?.heartRateLifetouch ?? " ",
          val?.respirationRateLifetouch ?? " ",
          val?.pulseOxSpO2 ?? " ",
          val?.temperature ?? " ",
          val?.bloodPressure ?? " ",
          val?.weight ?? " ",
          val?.manuallyEnteredConsciousnessLevel ?? " ",
          val?.manuallyEnteredSupplementalOxygenLevel ?? " ",
          val?.manuallyEnteredCapillaryRefillTime ?? " ",
          val?.manuallyEnteredFamilyOrNurseConcern ?? " ",
          val?.manuallyEnteredRespirationDistress ?? " ",
          val?.manuallyEnteredUrineOutput ?? " ",
          val?.earlyWarning ?? " ",
          val
        )
      );
      setRows(JSON.parse(JSON.stringify(rowData)));
    }
  };

  const handleShowEndSession = (e: any) => {
    setEndSession(e.target.checked);
    hook?.setSelectedWard(null);
    setPage(0);
    if (e.target.checked === true) {
      const filteredData = dataDetail;

      // const filteredData = JSON.parse(JSON.stringify(dataDetail)).filter(
      //   (item: any) => {
      //     return (

      //       item?.patientEndSessionTime === ""
      //     );
      //   }
      // );
      let rowData = filteredData.map((val: any) =>
        createData(
          val.wardName,
          val.bedName,
          val?.heartRateLifetouch ?? " ",
          val?.respirationRateLifetouch ?? " ",
          val?.pulseOxSpO2 ?? " ",
          val?.temperature ?? " ",
          val?.bloodPressure ?? " ",
          val?.weight ?? " ",
          val?.manuallyEnteredConsciousnessLevel ?? " ",
          val?.manuallyEnteredSupplementalOxygenLevel ?? " ",
          val?.manuallyEnteredCapillaryRefillTime ?? " ",
          val?.manuallyEnteredFamilyOrNurseConcern ?? " ",
          val?.manuallyEnteredRespirationDistress ?? " ",
          val?.manuallyEnteredUrineOutput ?? " ",
          val?.earlyWarning ?? " ",
          val
        )
      );
      setRows(JSON.parse(JSON.stringify(rowData)));
    } else {
      const filteredData = JSON.parse(JSON.stringify(dataDetail)).filter(
        (item: any) => {
          return (
            item?.patientEndSessionTime !== "" &&
            item?.patientEndSessionTime !== "0001-01-01T00:00:00"
          );
        }
      );

      let rowData = filteredData.map((val: any) =>
        createData(
          val.wardName,
          val.bedName,
          val?.heartRateLifetouch ?? " ",
          val?.respirationRateLifetouch ?? " ",
          val?.pulseOxSpO2 ?? " ",
          val?.temperature ?? " ",
          val?.bloodPressure ?? " ",
          val?.weight ?? " ",
          val?.manuallyEnteredConsciousnessLevel ?? " ",
          val?.manuallyEnteredSupplementalOxygenLevel ?? " ",
          val?.manuallyEnteredCapillaryRefillTime ?? " ",
          val?.manuallyEnteredFamilyOrNurseConcern ?? " ",
          val?.manuallyEnteredRespirationDistress ?? " ",
          val?.manuallyEnteredUrineOutput ?? " ",
          val?.earlyWarning ?? " ",
          val
        )
      );
      setRows(JSON.parse(JSON.stringify(rowData)));
    }
  };

  let filteredRows: any;
  if (hook.ward !== "All Wards" && hook.ward !== null) {
    filteredRows = visibleRows.filter((patientData: any) => {
      return patientData.AllData.wardDetailsId === hook?.ward;
    });
  } else {
    filteredRows = visibleRows;
  }

  React.useEffect(() => {
    const data: any = localStorage.getItem("configUiData");
    setConfigData(JSON.parse(data));
    // const e = { target: { checked: true } };
    // handleChangeCheckBox(e);
  }, [setConfigData]);

  function numberToHexColor(number: number) {
    if (number < 0 || number > 16777215) {
      throw new RangeError("Number must be between 0 and 16777215.");
    }

    let hexColor = number.toString(16);

    while (hexColor.length < 6) {
      hexColor = "0" + hexColor;
    }
    return `#${hexColor}`;
  }

  function getTextColorForScore(
    score: any,
    thresholdSetColours: any,
    displayTime: any
  ) {
    if (displayTime === null || displayTime === undefined) {
      return "transparent";
    }

    const match = thresholdSetColours?.find(
      (color: any) => color?.Score === score
    );
    if (match) {
      return numberToHexColor(match.Colour);
    }
    return "transparent";
  }

  // function getFontColor(
  //   score: any,
  //   thresholdSetColours: any,
  //   displayTime: any
  // ) {
  //   if (displayTime == null || displayTime == undefined) {
  //     return "transparent";
  //   }
  //   const match = thresholdSetColours?.find(
  //     (color: any) => color?.Score === score
  //   );
  //   if (match) {
  //     return numberToHexColor(match.TextColour);
  //   }
  //   return "transparent";
  // }

  return (
    <>
      <TopBar />
      <Breadcum />
      <div
        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
        id="kt_wrapper"
        style={{
          paddingTop: "55px",
          borderTop: "1px solid #ddc",
        }}
      >
        <div
          className="d-flex dashboard-container"
          style={{ overflow: "scroll", height: "94vh" }}
        >
          <div className="dashboard">
            <Box sx={{ width: "100%" }}>
              <Paper>
                <TableContainer>
                  <Table className="dashboardTable">
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />

                    <TableBody className="table-hover">
                      {loading ? (
                        ""
                      ) : (
                        <>
                          {filteredRows?.map(
                            (patientData: any, index: number) => {
                              return (
                                <TableRow
                                  hover
                                  onClick={(event: any) => {
                                    if (
                                      patientData?.AllData
                                        ?.patientStartSessionTime !==
                                        "0001-01-01T00:00:00" &&
                                      patientData?.AllData
                                        ?.patientEndSessionTime !==
                                        "0001-01-01T00:00:00"
                                    ) {
                                      console.log(
                                        "patientData.AllData => ",
                                        patientData.AllData
                                      );

                                      window.open(
                                        `/patienddetail?patientSessionId=${patientData.AllData.patientSessionId}&thresholdSetAgeBlockDetailId=${patientData.AllData.thresholdSetAgeBlockDetail?.ThresholdSetAgeBlockDetailId}&patientDetaildsId=${patientData.AllData.patientDetailsId}&from=${patientData.AllData.patientStartSessionTime}&hospitalPatientId=${patientData.AllData.hospitalPatientId}&wardName=${patientData.AllData.wardName}&bedName=${patientData.AllData.bedName}`,
                                        "_self"
                                      );
                                    }
                                  }}
                                  title={createDashboardRowTooltip(
                                    patientData.AllData
                                  )}
                                  tabIndex={-1}
                                  key={index}
                                  className={`${
                                    patientData?.AllData
                                      ?.patientStartSessionTime !==
                                      "0001-01-01T00:00:00" &&
                                    patientData?.AllData
                                      ?.patientEndSessionTime !==
                                      "0001-01-01T00:00:00"
                                      ? "ward-row ward-row-selected "
                                      : "ward-row"
                                  } table-row`}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="none"
                                    align="center"
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {patientData?.Ward}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      fontWeight: "bold",
                                      display: "flex",
                                    }}
                                    className="dashboardBedColumn"
                                  >
                                    <div
                                      className="bed-indicator"
                                      style={{
                                        float: "left",
                                        background: `${statusIndicator(
                                          patientData?.AllData
                                            ?.connectivityState
                                        )}`,
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        float: "inherit",
                                        textAlign: "center",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>{patientData?.Bed}</div>
                                    </div>
                                  </TableCell>
                                  {patientData?.heartRateLifetouch?.display ??
                                  patientData?.manuallyEnteredHeartRate
                                    ?.display ? (
                                    <TableCell
                                      className={getDashboardVitalSignDisplayStatusIcon(
                                        patientData?.heartRateLifetouch
                                          ?.display ??
                                          patientData?.manuallyEnteredHeartRate
                                            ?.display
                                      )}
                                    ></TableCell>
                                  ) : (
                                    <TableCell
                                      className="table-cell"
                                      style={{
                                        width: "100%",
                                        textAlign: "center",

                                        background: getTextColorForScore(
                                          patientData?.AllData
                                            ?.heartRateLifetouch?.score ??
                                            patientData?.AllData
                                              ?.manuallyEnteredHeartRate?.score,
                                          patientData?.AllData
                                            ?.thresholdSetAgeBlockDetail
                                            ?.ThresholdSetColours,
                                          patientData?.AllData
                                            ?.heartRateLifetouch?.reading ??
                                            patientData?.AllData
                                              ?.manuallyEnteredHeartRate
                                              ?.reading
                                        ),
                                      }}
                                    >
                                      {!patientData?.AllData
                                        ?.patientSessionEnded && (
                                        <>
                                          {showDevelopmentCode() && (
                                            <>
                                              <div>
                                                DSID :{" "}
                                                {getSensorStatusText(
                                                  patientData?.AllData
                                                    ?.heartRateLifetouch
                                                    ?.deviceSessionId
                                                )}
                                              </div>
                                              <div>
                                                VSS :{" "}
                                                {
                                                  patientData?.AllData
                                                    ?.heartRateLifetouch
                                                    ?.vitalSignState
                                                }
                                              </div>
                                              <div>
                                                S:{" "}
                                                {
                                                  patientData?.AllData
                                                    ?.heartRateLifetouch?.score
                                                }
                                              </div>
                                              <div>
                                                T:{" "}
                                                {
                                                  patientData?.AllData
                                                    ?.heartRateLifetouch
                                                    ?.displayTimeStamp
                                                }
                                              </div>
                                            </>
                                          )}

                                          {getDashboardVitalSignDisplayStatus(
                                            patientData?.AllData
                                              ?.heartRateLifetouch?.reading
                                          ) ? (
                                            <div>
                                              <div>
                                                {getDashboardVitalSignDisplayStatus(
                                                  patientData?.AllData
                                                    ?.heartRateLifetouch
                                                    ?.reading
                                                )}
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              {canShowLastData(
                                                patientData?.AllData
                                                  ?.heartRateLifetouch
                                              ) && (
                                                <>
                                                  <div>
                                                    {t(
                                                      "dashboard.table.last_data"
                                                    )}
                                                    <br />
                                                    {patientData?.AllData
                                                      ?.temperature
                                                      ?.timeStamp &&
                                                      moment(
                                                        patientData?.AllData
                                                          ?.temperature
                                                          ?.timeStamp
                                                      ).format(
                                                        "HH:mm:ss MMM Do"
                                                      )}
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}

                                      {configData?.ShowManuallyEnteredVitalsOnDashboard &&
                                        !patientData?.AllData
                                          ?.patientSessionEnded && (
                                          <div>
                                            {patientData?.AllData
                                              ?.manuallyEnteredHeartRate
                                              ?.reading ? (
                                              <div>
                                                {
                                                  patientData?.AllData
                                                    ?.manuallyEnteredHeartRate
                                                    ?.reading
                                                }
                                              </div>
                                            ) : (
                                              <div>
                                                {
                                                  patientData?.AllData
                                                    ?.manuallyEnteredHeartRate
                                                    ?.timeStamp
                                                }
                                              </div>
                                            )}
                                            {canShowLastData(
                                              patientData?.AllData
                                                ?.manuallyEnteredHeartRate
                                            ) && (
                                              <div>
                                                {t("dashboard.table.last_data")}
                                                <br />
                                                {patientData?.AllData
                                                  ?.temperature?.timeStamp &&
                                                  moment(
                                                    patientData?.AllData
                                                      ?.temperature?.timeStamp
                                                  ).format("HH:mm:ss MMM Do")}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                    </TableCell>
                                  )}

                                  {patientData?.respirationRateLifetouch
                                    ?.display ??
                                  patientData?.manuallyEnteredRespirationRate
                                    ?.display ? (
                                    <TableCell
                                      className={getDashboardVitalSignDisplayStatusIcon(
                                        patientData?.respirationRateLifetouch
                                          ?.display ??
                                          patientData
                                            ?.manuallyEnteredRespirationRate
                                            ?.display
                                      )}
                                    ></TableCell>
                                  ) : (
                                    <TableCell
                                      className="table-cell"
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        // fontSize: "13px",

                                        background: getTextColorForScore(
                                          patientData?.AllData
                                            ?.respirationRateLifetouch?.score ??
                                            patientData?.AllData
                                              ?.manuallyEnteredRespirationRate
                                              ?.score,
                                          patientData?.AllData
                                            ?.thresholdSetAgeBlockDetail
                                            ?.ThresholdSetColours,
                                          patientData?.AllData
                                            ?.respirationRateLifetouch
                                            ?.reading ??
                                            patientData?.AllData
                                              ?.manuallyEnteredRespirationRate
                                              ?.reading
                                        ),
                                      }}
                                    >
                                      {!patientData?.AllData
                                        ?.patientSessionEnded && (
                                        <>
                                          {showDevelopmentCode() && (
                                            <>
                                              <div>
                                                DSID :{" "}
                                                {showVariableIfNotEmpty(
                                                  getSensorStatusText(
                                                    patientData
                                                      ?.lifetouchSensorStatus
                                                      ?.AllData?.deviceSessionId
                                                  )
                                                )}
                                              </div>
                                              <div>
                                                VSS :{" "}
                                                {showVariableIfNotEmpty(
                                                  patientData?.AllData
                                                    ?.respirationRateLifetouch
                                                    ?.vitalSignState
                                                )}
                                              </div>
                                              <div>
                                                S:{" "}
                                                {showVariableIfNotEmpty(
                                                  patientData?.AllData
                                                    ?.respirationRateLifetouch
                                                    ?.score
                                                )}
                                              </div>
                                              <div>
                                                T:{" "}
                                                {showVariableIfNotEmpty(
                                                  patientData?.AllData
                                                    ?.respirationRateLifetouch
                                                    ?.displayTimeStamp
                                                )}
                                              </div>
                                            </>
                                          )}

                                          <>
                                            {getDashboardVitalSignDisplayStatus(
                                              patientData?.AllData
                                                ?.respirationRateLifetouch
                                                ?.reading ??
                                                patientData?.AllData
                                                  ?.manuallyEnteredRespirationRate
                                                  ?.reading
                                            ) ? (
                                              <div>
                                                {getDashboardVitalSignDisplayStatus(
                                                  patientData?.AllData
                                                    ?.respirationRateLifetouch
                                                    ?.reading ??
                                                    patientData?.AllData
                                                      ?.manuallyEnteredRespirationRate
                                                      ?.reading
                                                )}
                                              </div>
                                            ) : (
                                              <div>
                                                {
                                                  patientData?.AllData
                                                    ?.respirationRateLifetouch
                                                    ?.timeStamp
                                                }
                                              </div>
                                            )}
                                          </>
                                          <>
                                            {canShowLastData(
                                              patientData?.AllData
                                                ?.respirationRateLifetouch
                                            ) && (
                                              <div>
                                                {t("dashboard.table.last_data")}
                                                <br />
                                                {patientData?.AllData
                                                  ?.temperature?.timeStamp &&
                                                  moment(
                                                    patientData?.AllData
                                                      ?.temperature?.timeStamp
                                                  ).format("HH:mm:ss MMM Do")}
                                              </div>
                                            )}
                                          </>
                                        </>
                                      )}

                                      {configData?.showManuallyEnteredVitalsOnDashboard &&
                                        patientData?.AllData
                                          ?.patientSessionEnded && (
                                          <>
                                            {patientData?.AllData
                                              ?.manuallyEnteredRespirationRate
                                              ?.reading ? (
                                              <div>
                                                {
                                                  patientData?.AllData
                                                    ?.manuallyEnteredRespirationRate
                                                    ?.reading
                                                }
                                              </div>
                                            ) : (
                                              <div>
                                                {
                                                  patientData?.AllData
                                                    ?.manuallyEnteredRespirationRate
                                                    ?.timeStamp
                                                }
                                              </div>
                                            )}
                                            {canShowLastData(
                                              patientData?.AllData
                                                ?.manuallyEnteredRespirationRate
                                            ) && (
                                              <div>
                                                {t("dashboard.table.last_data")}
                                                <br />
                                                {patientData?.AllData
                                                  ?.temperature?.timeStamp &&
                                                  moment(
                                                    patientData?.AllData
                                                      ?.temperature?.timeStamp
                                                  ).format("HH:mm:ss MMM Do")}
                                              </div>
                                            )}
                                          </>
                                        )}
                                    </TableCell>
                                  )}

                                  {patientData?.temperature?.display ??
                                  patientData?.manuallyEnteredTemperature
                                    ?.display ? (
                                    <TableCell
                                      className={getDashboardVitalSignDisplayStatusIcon(
                                        patientData?.temperature?.display ??
                                          patientData
                                            ?.manuallyEnteredTemperature
                                            ?.display
                                      )}
                                    ></TableCell>
                                  ) : (
                                    <TableCell
                                      className="table-cell"
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        // fontSize: "13px",

                                        background: canShowLastData(
                                          patientData?.AllData?.temperature
                                            ?.reading
                                        )
                                          ? getTextColorForScore(
                                              patientData?.AllData?.temperature
                                                ?.score ??
                                                patientData?.AllData
                                                  ?.manuallyEnteredTemperature
                                                  ?.score,
                                              patientData?.AllData
                                                ?.thresholdSetAgeBlockDetail
                                                ?.ThresholdSetColours,
                                              patientData?.AllData?.temperature
                                                ?.reading ??
                                                patientData?.AllData
                                                  ?.manuallyEnteredTemperature
                                                  ?.reading
                                            )
                                          : "white",
                                      }}
                                    >
                                      {!patientData?.patientSessionEnded && (
                                        <>
                                          {showDevelopmentCode() && (
                                            <>
                                              <div>
                                                {getSensorStatusText(
                                                  patientData?.AllData
                                                    ?.temperatureSensorStatus
                                                )}
                                              </div>

                                              <div>
                                                DSID :{" "}
                                                {showVariableIfNotEmpty(
                                                  getSensorStatusText(
                                                    patientData?.AllData
                                                      ?.temperature
                                                      ?.deviceSessionId
                                                  )
                                                )}
                                              </div>
                                              <div>
                                                VSS :{" "}
                                                {showVariableIfNotEmpty(
                                                  patientData?.AllData
                                                    ?.temperature
                                                    ?.vitalSignState
                                                )}
                                              </div>
                                              <div>
                                                S:{" "}
                                                {showVariableIfNotEmpty(
                                                  patientData?.AllData
                                                    ?.temperature?.score
                                                )}
                                              </div>
                                              <div>
                                                T:{" "}
                                                {showVariableIfNotEmpty(
                                                  patientData?.AllData
                                                    ?.temperature
                                                    ?.displayTimeStamp
                                                )}
                                              </div>
                                            </>
                                          )}

                                          <>
                                            {canShowLastData(
                                              patientData?.AllData?.temperature
                                                ?.reading
                                            ) ? (
                                              <div>
                                                {formatWithOneDecimalPlace(
                                                  patientData?.AllData
                                                    ?.temperature?.reading ??
                                                    patientData?.AllData
                                                      ?.manuallyEnteredTemperature
                                                      ?.reading
                                                )}
                                              </div>
                                            ) : (
                                              <div>
                                                {t("dashboard.table.last_data")}
                                                <br />
                                                {patientData?.AllData
                                                  ?.temperature?.timeStamp &&
                                                  moment(
                                                    patientData?.AllData
                                                      ?.temperature?.timeStamp
                                                  ).format("HH:mm:ss MMM Do")}
                                              </div>
                                            )}
                                          </>
                                          <>
                                            {canShowLastData(
                                              patientData?.AllData?.temperature
                                            ) && (
                                              <div>
                                                {t("dashboard.table.last_data")}
                                                <br />
                                                {patientData?.AllData
                                                  ?.temperature?.timeStamp &&
                                                  moment(
                                                    patientData?.AllData
                                                      ?.temperature?.timeStamp
                                                  ).format("HH:mm:ss MMM Do")}
                                              </div>
                                            )}
                                          </>
                                        </>
                                      )}

                                      {configData?.showManuallyEnteredVitalsOnDashboard &&
                                        !patientData?.AllData
                                          ?.patientSessionEnded && (
                                          <>
                                            <div>
                                              {
                                                patientData?.AllData
                                                  ?.manuallyEnteredTemperature
                                                  ?.reading
                                              }
                                            </div>
                                            <div>
                                              {
                                                patientData?.AllData
                                                  ?.manuallyEnteredTemperature
                                                  ?.timeStamp
                                              }
                                            </div>
                                            {canShowLastData(
                                              patientData?.AllData
                                                ?.manuallyEnteredTemperature
                                            ) && (
                                              <div>
                                                <span>
                                                  {t(
                                                    "dashboard.table.last_updated"
                                                  )}
                                                </span>
                                                <br />
                                                <span>
                                                  {
                                                    patientData?.AllData
                                                      ?.manuallyEnteredTemperature
                                                      ?.timeStamp
                                                  }
                                                </span>
                                              </div>
                                            )}
                                          </>
                                        )}
                                    </TableCell>
                                  )}

                                  {patientData?.pulseOxSpO2?.display ??
                                  patientData?.manuallyEnteredSpO2?.display ? (
                                    <TableCell
                                      className={getDashboardVitalSignDisplayStatusIcon(
                                        patientData?.pulseOxSpO2?.display ??
                                          patientData?.manuallyEnteredSpO2
                                            ?.display
                                      )}
                                    ></TableCell>
                                  ) : (
                                    <TableCell
                                      className="table-cell"
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        // fontSize: "13px",
                                        // border: "1px solid white",
                                        // borderBottom:
                                        //   "1px solid rgba(224, 224, 224, 1)",

                                        background: getTextColorForScore(
                                          patientData?.AllData?.pulseOxSpO2
                                            ?.score ??
                                            patientData?.AllData
                                              ?.manuallyEnteredSpO2?.score,
                                          patientData?.AllData
                                            ?.thresholdSetAgeBlockDetail
                                            ?.ThresholdSetColours,
                                          patientData?.AllData?.pulseOxSpO2
                                            ?.reading ??
                                            patientData?.AllData
                                              ?.manuallyEnteredSpO2?.reading
                                        ),
                                      }}
                                    >
                                      {!patientData?.AllData
                                        ?.patientSessionEnded && (
                                        <>
                                          {showDevelopmentCode() && (
                                            <>
                                              <div>
                                                {getSensorStatusText(
                                                  patientData?.AllData
                                                    ?.pulseOxSensorStatus
                                                )}
                                              </div>

                                              <div>
                                                DSID :{" "}
                                                {showVariableIfNotEmpty(
                                                  getSensorStatusText(
                                                    patientData?.AllData
                                                      ?.pulseOxSpO2
                                                      ?.deviceSessionId
                                                  )
                                                )}
                                              </div>
                                              <div>
                                                VSS :{" "}
                                                {showVariableIfNotEmpty(
                                                  patientData?.AllData
                                                    ?.pulseOxSpO2
                                                    ?.vitalSignState
                                                )}
                                              </div>
                                              <div>
                                                S:{" "}
                                                {showVariableIfNotEmpty(
                                                  patientData?.AllData
                                                    ?.pulseOxSpO2?.score
                                                )}
                                              </div>
                                              <div>
                                                T:{" "}
                                                {showVariableIfNotEmpty(
                                                  patientData?.AllData
                                                    ?.pulseOxSpO2?.timeStamp
                                                )}
                                              </div>
                                            </>
                                          )}

                                          <>
                                            {formatWithOneDecimalPlace(
                                              patientData?.AllData?.pulseOxSpO2
                                                ?.reading
                                            ) ? (
                                              <div>
                                                {formatWithOneDecimalPlace(
                                                  patientData?.AllData
                                                    ?.pulseOxSpO2?.reading ??
                                                    patientData?.AllData
                                                      ?.manuallyEnteredSpO2
                                                      ?.reading
                                                )}
                                              </div>
                                            ) : (
                                              <div>
                                                {patientData?.AllData
                                                  ?.pulseOxSpO2?.timeStamp ??
                                                  patientData?.AllData
                                                    ?.manuallyEnteredSpO2
                                                    ?.reading}
                                              </div>
                                            )}
                                          </>
                                          <>
                                            {canShowLastData(
                                              patientData?.AllData?.pulseOxSpO2
                                            ) && (
                                              <div>
                                                {t("dashboard.table.last_data")}
                                                <br />
                                                {patientData?.AllData
                                                  ?.temperature?.timeStamp &&
                                                  moment(
                                                    patientData?.AllData
                                                      ?.temperature?.timeStamp
                                                  ).format("HH:mm:ss MMM Do")}
                                              </div>
                                            )}
                                          </>
                                        </>
                                      )}

                                      {configData?.showManuallyEnteredVitalsOnDashboard &&
                                        !patientData?.AllData
                                          ?.patientSessionEnded && (
                                          <>
                                            {patientData?.AllData
                                              ?.manuallyEnteredSpO2.reading ? (
                                              <div>
                                                {
                                                  patientData?.AllData
                                                    ?.manuallyEnteredSpO2
                                                    .reading
                                                }
                                              </div>
                                            ) : (
                                              <div>
                                                {
                                                  patientData?.AllData
                                                    ?.manuallyEnteredSpO2
                                                    ?.timeStamp
                                                }
                                              </div>
                                            )}
                                            {canShowLastData(
                                              patientData?.AllData
                                                ?.manuallyEnteredSpO2
                                            ) && (
                                              <div>
                                                {t("dashboard.table.last_data")}
                                                <br />
                                                {patientData?.AllData
                                                  ?.temperature?.timeStamp &&
                                                  moment(
                                                    patientData?.AllData
                                                      ?.temperature?.timeStamp
                                                  ).format("HH:mm:ss MMM Do")}
                                              </div>
                                            )}
                                          </>
                                        )}
                                    </TableCell>
                                  )}

                                  {patientData?.bloodPressure?.display ??
                                  patientData?.manuallyEnteredBloodPressure
                                    ?.display ? (
                                    <TableCell
                                      className={getDashboardVitalSignDisplayStatusIcon(
                                        patientData?.bloodPressure?.display ??
                                          patientData
                                            ?.manuallyEnteredBloodPressure
                                            ?.display
                                      )}
                                    ></TableCell>
                                  ) : (
                                    <TableCell
                                      className="table-cell"
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        // fontSize: "13px",
                                        // border: "1px solid white",
                                        // borderBottom:
                                        //   "1px solid rgba(224, 224, 224, 1)",

                                        background: getTextColorForScore(
                                          patientData?.AllData?.bloodPressure
                                            ?.score ??
                                            patientData?.AllData
                                              ?.manuallyEnteredBloodPressure
                                              ?.score,
                                          patientData?.AllData
                                            ?.thresholdSetAgeBlockDetail
                                            ?.ThresholdSetColours,
                                          patientData?.AllData?.bloodPressure
                                            ?.reading ??
                                            patientData?.AllData
                                              ?.manuallyEnteredBloodPressure
                                              ?.reading
                                        ),
                                      }}
                                    >
                                      {!patientData?.AllData
                                        ?.patientSessionEnded && (
                                        <>
                                          {showDevelopmentCode() && (
                                            <>
                                              <div>
                                                {getSensorStatusText(
                                                  patientData?.AllData
                                                    ?.bloodPressureSensorStatus
                                                )}
                                              </div>

                                              <div>
                                                DSID :{" "}
                                                {showVariableIfNotEmpty(
                                                  getSensorStatusText(
                                                    patientData?.AllData
                                                      ?.bloodPressure
                                                      ?.deviceSessionId
                                                  )
                                                )}
                                              </div>
                                              <div>
                                                VSS :{" "}
                                                {showVariableIfNotEmpty(
                                                  patientData?.AllData
                                                    ?.bloodPressure
                                                    ?.vitalSignState
                                                )}
                                              </div>
                                              <div>
                                                S:{" "}
                                                {showVariableIfNotEmpty(
                                                  patientData?.AllData
                                                    ?.bloodPressure?.score
                                                )}
                                              </div>
                                              <div>
                                                T:{" "}
                                                {showVariableIfNotEmpty(
                                                  patientData?.AllData
                                                    ?.bloodPressure?.timeStamp
                                                )}
                                              </div>
                                            </>
                                          )}

                                          <>
                                            {canShowMeasurement(
                                              patientData?.AllData
                                                ?.bloodPressure
                                            ) ? (
                                              <div>
                                                {
                                                  patientData?.AllData
                                                    ?.bloodPressure?.display
                                                }{" "}
                                                /{" "}
                                                {
                                                  patientData?.AllData
                                                    ?.bloodPressure?.display2
                                                }
                                              </div>
                                            ) : (
                                              <div>
                                                {getDashboardVitalSignDisplayStatus(
                                                  patientData?.AllData
                                                    ?.bloodPressure?.reading
                                                )}
                                              </div>
                                            )}
                                            <div>
                                              {
                                                patientData?.AllData
                                                  ?.bloodPressure?.timeStamp
                                              }
                                            </div>
                                          </>
                                          <>
                                            {canShowLastData(
                                              patientData?.AllData
                                                ?.bloodPressure
                                            ) && (
                                              <div>
                                                {t("dashboard.table.last_data")}
                                                <br />
                                                {patientData?.AllData
                                                  ?.temperature?.timeStamp &&
                                                  moment(
                                                    patientData?.AllData
                                                      ?.temperature?.timeStamp
                                                  ).format("HH:mm:ss MMM Do")}
                                              </div>
                                            )}
                                          </>
                                        </>
                                      )}

                                      {configData?.showManuallyEnteredVitalsOnDashboard &&
                                        !patientData?.AllData
                                          ?.patientSessionEnded && (
                                          <>
                                            {getBloodPressureDeviceStatus(
                                              patientData?.AllData
                                                ?.manuallyEnteredBloodPressure
                                            ) ? (
                                              <div>
                                                {getBloodPressureDeviceStatus(
                                                  patientData?.AllData
                                                    ?.manuallyEnteredBloodPressure
                                                )}
                                              </div>
                                            ) : (
                                              <div>
                                                {
                                                  patientData?.AllData
                                                    ?.manuallyEnteredBloodPressure
                                                    ?.timeStamp
                                                }
                                              </div>
                                            )}
                                            {canShowLastData(
                                              patientData?.AllData
                                                ?.manuallyEnteredBloodPressure
                                            ) && (
                                              <div>
                                                {t("dashboard.table.last_data")}
                                                <br />
                                                {patientData?.AllData
                                                  ?.temperature?.timeStamp &&
                                                  moment(
                                                    patientData?.AllData
                                                      ?.temperature?.timeStamp
                                                  ).format("HH:mm:ss MMM Do")}
                                              </div>
                                            )}
                                          </>
                                        )}
                                    </TableCell>
                                  )}

                                  {patientData?.weight?.display ??
                                  patientData?.manuallyEnteredWeight
                                    ?.display ? (
                                    <TableCell
                                      className={getDashboardVitalSignDisplayStatusIcon(
                                        patientData?.weight?.display ??
                                          patientData?.manuallyEnteredWeight
                                            ?.display
                                      )}
                                    ></TableCell>
                                  ) : (
                                    <TableCell
                                      className="table-cell"
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        // fontSize: "13px",
                                        // border: "1px solid white",
                                        // borderBottom:
                                        //   "1px solid rgba(224, 224, 224, 1)",

                                        background: getTextColorForScore(
                                          patientData?.AllData?.weight?.score ??
                                            patientData?.AllData
                                              ?.manuallyEnteredWeight?.score,
                                          patientData?.AllData
                                            ?.thresholdSetAgeBlockDetail
                                            ?.ThresholdSetColours,
                                          patientData?.AllData?.weight
                                            ?.reading ??
                                            patientData?.AllData
                                              ?.manuallyEnteredWeight?.reading
                                        ),
                                      }}
                                    >
                                      {!patientData?.AllData
                                        ?.patientSessionEnded && (
                                        <>
                                          {showDevelopmentCode() && (
                                            <>
                                              <div>
                                                {getSensorStatusText(
                                                  patientData?.AllData
                                                    ?.weightScaleSensorStatus
                                                )}
                                              </div>

                                              <div>
                                                DSID :{" "}
                                                {showVariableIfNotEmpty(
                                                  getSensorStatusText(
                                                    patientData?.AllData?.weight
                                                      ?.deviceSessionId
                                                  )
                                                )}
                                              </div>
                                              <div>
                                                VSS :{" "}
                                                {showVariableIfNotEmpty(
                                                  patientData?.AllData?.weight
                                                    ?.vitalSignState
                                                )}
                                              </div>
                                              <div>
                                                S:{" "}
                                                {showVariableIfNotEmpty(
                                                  patientData?.AllData?.weight
                                                    ?.score
                                                )}
                                              </div>
                                              <div>
                                                T:{" "}
                                                {showVariableIfNotEmpty(
                                                  patientData?.AllData?.weight
                                                    ?.displayTimeStamp
                                                )}
                                              </div>
                                            </>
                                          )}

                                          <>
                                            {getDashboardVitalSignDisplayStatus(
                                              patientData?.AllData?.weight
                                                ?.reading
                                            ) ? (
                                              <div>
                                                {getDashboardVitalSignDisplayStatus(
                                                  patientData?.AllData?.weight
                                                    ?.reading
                                                )}
                                              </div>
                                            ) : (
                                              <div>
                                                {
                                                  patientData?.AllData?.weight
                                                    ?.timeStamp
                                                }
                                              </div>
                                            )}
                                          </>
                                          <>
                                            {canShowLastData(
                                              patientData?.AllData?.weight
                                            ) && (
                                              <div>
                                                {t("dashboard.table.last_data")}
                                                <br />
                                                {patientData?.AllData
                                                  ?.temperature?.timeStamp &&
                                                  moment(
                                                    patientData?.AllData
                                                      ?.temperature?.timeStamp
                                                  ).format("HH:mm:ss MMM Do")}
                                              </div>
                                            )}
                                          </>
                                        </>
                                      )}

                                      {configData?.showManuallyEnteredVitalsOnDashboard &&
                                        !patientData?.AllData
                                          ?.patientSessionEnded && (
                                          <>
                                            {patientData?.AllData
                                              ?.manuallyEnteredWeight
                                              ?.reading ? (
                                              <div>
                                                {
                                                  patientData?.AllData
                                                    ?.manuallyEnteredWeight
                                                    ?.reading
                                                }
                                              </div>
                                            ) : (
                                              <div>
                                                {
                                                  patientData?.AllData
                                                    ?.manuallyEnteredWeight
                                                    .timeStamp
                                                }
                                              </div>
                                            )}
                                            {canShowLastData(
                                              patientData?.AllData
                                                ?.manuallyEnteredWeight
                                            ) && (
                                              <div>
                                                {t("dashboard.table.last_data")}
                                                <br />
                                                {patientData?.AllData
                                                  ?.temperature?.timeStamp &&
                                                  moment(
                                                    patientData?.AllData
                                                      ?.temperature?.timeStamp
                                                  ).format("HH:mm:ss MMM Do")}
                                              </div>
                                            )}
                                          </>
                                        )}
                                    </TableCell>
                                  )}

                                  <TableCell
                                    className="table-cell"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",

                                      background: getTextColorForScore(
                                        patientData?.AllData
                                          ?.manuallyEnteredConsciousnessLevel
                                          ?.score,
                                        patientData?.AllData
                                          ?.thresholdSetAgeBlockDetail
                                          ?.ThresholdSetColours,
                                        patientData?.AllData
                                          ?.manuallyEnteredConsciousnessLevel
                                          ?.reading
                                      ),
                                    }}
                                  >
                                    {configData?.ShowConsciousnessLevelAndOxygenSaturation &&
                                      !patientData?.AllData
                                        ?.patientSessionEnded && (
                                        <>
                                          {patientData?.AllData
                                            ?.manuallyEnteredConsciousnessLevel
                                            ?.display ? (
                                            <div>
                                              {
                                                patientData?.AllData
                                                  ?.manuallyEnteredConsciousnessLevel
                                                  ?.display
                                              }
                                            </div>
                                          ) : (
                                            <div>
                                              {
                                                patientData?.AllData
                                                  ?.manuallyEnteredConsciousnessLevel
                                                  ?.timeStamp
                                              }
                                            </div>
                                          )}
                                          {canShowLastData(
                                            patientData?.AllData
                                              ?.manuallyEnteredConsciousnessLevel
                                          ) && (
                                            <div>
                                              {t("dashboard.table.last_data")}
                                              <br />
                                              {patientData?.AllData?.temperature
                                                ?.timeStamp &&
                                                moment(
                                                  patientData?.AllData
                                                    ?.temperature?.timeStamp
                                                ).format("HH:mm:ss MMM Do")}
                                            </div>
                                          )}
                                        </>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    className="table-cell"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",

                                      background: getTextColorForScore(
                                        patientData?.AllData
                                          ?.manuallyEnteredSupplementalOxygenLevel
                                          ?.score,
                                        patientData?.AllData
                                          ?.thresholdSetAgeBlockDetail
                                          ?.ThresholdSetColours,
                                        patientData?.AllData
                                          ?.manuallyEnteredRespirationDistress
                                          ?.reading
                                      ),
                                    }}
                                  >
                                    {configData?.ShowConsciousnessLevelAndOxygenSaturation &&
                                      !patientData?.AllData
                                        ?.patientSessionEnded && (
                                        <>
                                          {patientData?.AllData
                                            ?.manuallyEnteredSupplementalOxygenLevel
                                            ?.display ? (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  patientData?.AllData
                                                    ?.manuallyEnteredSupplementalOxygenLevel
                                                    ?.display,
                                              }}
                                            ></div>
                                          ) : (
                                            <div>
                                              {
                                                patientData?.AllData
                                                  ?.manuallyEnteredRespirationDistress
                                                  ?.timeStamp
                                              }
                                            </div>
                                          )}
                                          {canShowLastData(
                                            patientData?.AllData
                                              ?.manuallyEnteredRespirationDistress
                                          ) && (
                                            <div>
                                              {t("dashboard.table.last_data")}
                                              <br />
                                              {patientData?.AllData?.temperature
                                                ?.timeStamp &&
                                                moment(
                                                  patientData?.AllData
                                                    ?.temperature?.timeStamp
                                                ).format("HH:mm:ss MMM Do")}
                                            </div>
                                          )}
                                        </>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    className="table-cell"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",

                                      background: getTextColorForScore(
                                        patientData?.AllData
                                          ?.manuallyEnteredCapillaryRefillTime
                                          ?.score,
                                        patientData?.AllData
                                          ?.thresholdSetAgeBlockDetail
                                          ?.ThresholdSetColours,
                                        patientData?.AllData
                                          ?.manuallyEnteredCapillaryRefillTime
                                          ?.reading
                                      ),
                                    }}
                                  >
                                    {configData?.ShowConsciousnessLevelAndOxygenSaturation &&
                                      !patientData?.patientSessionEnded && (
                                        <>
                                          {patientData?.AllData
                                            ?.manuallyEnteredCapillaryRefillTime
                                            ?.display ? (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  patientData?.AllData
                                                    ?.manuallyEnteredCapillaryRefillTime
                                                    ?.display,
                                              }}
                                            ></div>
                                          ) : (
                                            <div>
                                              {
                                                patientData?.AllData
                                                  ?.manuallyEnteredCapillaryRefillTime
                                                  ?.timeStamp
                                              }
                                            </div>
                                          )}
                                          {canShowLastData(
                                            patientData?.AllData
                                              ?.manuallyEnteredCapillaryRefillTime
                                          ) && (
                                            <div>
                                              {t("dashboard.table.last_data")}
                                              <br />
                                              {patientData?.AllData?.temperature
                                                ?.timeStamp &&
                                                moment(
                                                  patientData?.AllData
                                                    ?.temperature?.timeStamp
                                                ).format("HH:mm:ss MMM Do")}
                                            </div>
                                          )}
                                        </>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    className="table-cell"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      // fontSize: "13px",
                                      // border: "1px solid white",
                                      // borderBottom:
                                      //   "1px solid rgba(224, 224, 224, 1)",

                                      background: getTextColorForScore(
                                        patientData?.AllData
                                          ?.manuallyEnteredFamilyOrNurseConcern
                                          ?.score,
                                        patientData?.AllData
                                          ?.thresholdSetAgeBlockDetail
                                          ?.ThresholdSetColours,
                                        patientData?.AllData
                                          ?.manuallyEnteredFamilyOrNurseConcern
                                          ?.display
                                      ),
                                    }}
                                  >
                                    {configData?.ShowConsciousnessLevelAndOxygenSaturation &&
                                      !patientData?.AllData
                                        ?.patientSessionEnded && (
                                        <>
                                          {patientData?.AllData
                                            ?.manuallyEnteredFamilyOrNurseConcern
                                            ?.display ? (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  patientData?.AllData
                                                    ?.manuallyEnteredFamilyOrNurseConcern
                                                    ?.display,
                                              }}
                                            ></div>
                                          ) : (
                                            <div>
                                              {formatDate(
                                                patientData?.AllData
                                                  ?.manuallyEnteredFamilyOrNurseConcern
                                                  ?.timeStamp
                                              ) && (
                                                <>
                                                  <span>
                                                    {t(
                                                      "dashboard.table.last_updated"
                                                    )}
                                                  </span>
                                                  <br />
                                                  {formatDate(
                                                    patientData?.AllData
                                                      ?.manuallyEnteredFamilyOrNurseConcern
                                                      ?.timeStamp
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          )}
                                          {canShowLastData(
                                            patientData.AllData
                                              ?.manuallyEnteredFamilyOrNurseConcern
                                          ) && (
                                            <div>
                                              {t("dashboard.table.last_data")}
                                              <br />
                                              {patientData?.AllData?.temperature
                                                ?.timeStamp &&
                                                moment(
                                                  patientData?.AllData
                                                    ?.temperature?.timeStamp
                                                ).format("HH:mm:ss MMM Do")}
                                            </div>
                                          )}
                                        </>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    className="table-cell"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      // fontSize: "13px",
                                      // border: "1px solid white",
                                      // borderBottom:
                                      //   "1px solid rgba(224, 224, 224, 1)",

                                      background: getTextColorForScore(
                                        patientData?.AllData
                                          ?.manuallyEnteredSupplementalOxygenLevel
                                          ?.score,
                                        patientData?.AllData
                                          ?.thresholdSetAgeBlockDetail
                                          ?.ThresholdSetColours,
                                        patientData?.AllData
                                          ?.manuallyEnteredRespirationDistress
                                          ?.reading
                                      ),
                                    }}
                                  >
                                    {/* {displayTimeStamp(
                              patientData?.AllData
                                ?.manuallyEnteredRespirationDistress,
                              patientData?.RespirationDistress,
                              patientData?.AllData,
                              "false"
                            )} */}
                                    {configData?.ShowConsciousnessLevelAndOxygenSaturation &&
                                      !patientData?.AllData
                                        ?.patientSessionEnded && (
                                        <>
                                          {patientData?.AllData
                                            ?.manuallyEnteredSupplementalOxygenLevel
                                            ?.display ? (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  patientData?.AllData
                                                    ?.manuallyEnteredSupplementalOxygenLevel
                                                    ?.display,
                                              }}
                                            ></div>
                                          ) : (
                                            <div>
                                              {formatDate(
                                                patientData?.AllData
                                                  ?.manuallyEnteredRespirationDistress
                                                  ?.timeStamp
                                              ) && (
                                                <>
                                                  <span>
                                                    {t(
                                                      "dashboard.table.last_updated"
                                                    )}
                                                  </span>
                                                  <br />
                                                  <span>
                                                    {formatDate(
                                                      patientData?.AllData
                                                        ?.manuallyEnteredRespirationDistress
                                                        ?.timeStamp
                                                    )}
                                                  </span>
                                                </>
                                              )}
                                            </div>
                                          )}
                                          {canShowLastData(
                                            patientData?.AllData
                                              ?.manuallyEnteredRespirationDistress
                                          ) && (
                                            <div>
                                              {t("dashboard.table.last_data")}
                                              <br />
                                              {patientData?.AllData?.temperature
                                                ?.timeStamp &&
                                                moment(
                                                  patientData?.AllData
                                                    ?.temperature?.timeStamp
                                                ).format("HH:mm:ss MMM Do")}
                                            </div>
                                          )}
                                        </>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    className="table-cell"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      // fontSize: "13px",
                                      // border: "1px solid white",
                                      // borderBottom:
                                      //   "1px solid rgba(224, 224, 224, 1)",

                                      background: getTextColorForScore(
                                        patientData?.AllData
                                          ?.manuallyEnteredUrineOutput?.score,
                                        patientData?.AllData
                                          ?.thresholdSetAgeBlockDetail
                                          ?.ThresholdSetColours,
                                        patientData?.AllData
                                          ?.manuallyEnteredUrineOutput?.display
                                      ),
                                    }}
                                  >
                                    {/* {displayTimeStamp(
                              patientData?.AllData
                                ?.manuallyEnteredRespirationDistress,
                              patientData?.RespirationDistress,
                              patientData?.AllData,
                              "false"
                            )} */}
                                    {configData?.ShowConsciousnessLevelAndOxygenSaturation &&
                                      !patientData?.AllData
                                        ?.patientSessionEnded && (
                                        <>
                                          <div>
                                            {
                                              patientData?.AllData
                                                ?.manuallyEnteredUrineOutput
                                                ?.display
                                            }
                                          </div>
                                          <div>
                                            {showDevelopmentCode() && (
                                              <>
                                                <div>
                                                  DSID :{" "}
                                                  {
                                                    patientData?.AllData
                                                      ?.manuallyEnteredUrineOutput
                                                      ?.deviceSessionId
                                                  }
                                                </div>
                                                <div>
                                                  VSS :{" "}
                                                  {
                                                    patientData?.AllData
                                                      ?.manuallyEnteredUrineOutput
                                                      ?.vitalSignState
                                                  }
                                                </div>
                                                <div>
                                                  S:
                                                  {
                                                    patientData?.AllData
                                                      ?.manuallyEnteredUrineOutput
                                                      .score
                                                  }
                                                </div>
                                                <div>
                                                  T:
                                                  {
                                                    patientData?.AllData
                                                      ?.manuallyEnteredUrineOutput
                                                      ?.timeStamp
                                                  }
                                                </div>
                                              </>
                                            )}
                                          </div>
                                          <div>
                                            {formatDate(
                                              patientData?.AllData
                                                ?.manuallyEnteredUrineOutput
                                                ?.timeStamp
                                            ) && (
                                              <>
                                                <span>last_date</span>
                                                <br />
                                                <span>
                                                  {formatDate(
                                                    patientData?.AllData
                                                      ?.manuallyEnteredUrineOutput
                                                      ?.timeStamp
                                                  )}
                                                </span>
                                              </>
                                            )}
                                          </div>
                                          {canShowLastData(
                                            patientData?.AllData
                                              ?.manuallyEnteredUrineOutput
                                          ) && (
                                            <div>
                                              {t("dashboard.table.last_data")}
                                              <br />
                                              {patientData?.AllData?.temperature
                                                ?.timeStamp &&
                                                moment(
                                                  patientData?.AllData
                                                    ?.temperature?.timeStamp
                                                ).format("HH:mm:ss MMM Do")}
                                            </div>
                                          )}
                                        </>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    className="table-cell"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      // fontSize: "13px",
                                      // border: "1px solid white",
                                      // borderBottom:
                                      //   "1px solid rgba(224, 224, 224, 1)",

                                      background: getTextColorForScore(
                                        patientData?.AllData?.earlyWarning
                                          ?.score,
                                        patientData?.AllData
                                          ?.thresholdSetAgeBlockDetail
                                          ?.ThresholdSetColours,
                                        patientData?.AllData?.earlyWarning
                                          ?.reading
                                      ),
                                    }}
                                  >
                                    {configData?.IsEwsEnabled &&
                                      !patientData?.AllData
                                        ?.patientSessionEnded &&
                                      !emptyBed(
                                        patientData?.AllData?.patientDetailsId
                                      ) && (
                                        <>
                                          <span>
                                            {canShowEwsMeasurement(
                                              patientData?.AllData?.earlyWarning
                                            ) && (
                                              <>
                                                <span>
                                                  {
                                                    patientData?.AllData
                                                      ?.earlyWarning?.score
                                                  }
                                                </span>
                                                <span
                                                  style={{
                                                    marginLeft: "2px",
                                                    marginRight: "2px",
                                                  }}
                                                >
                                                  /
                                                </span>
                                                <span>
                                                  {
                                                    patientData?.AllData
                                                      ?.earlyWarning?.maxScore
                                                  }
                                                </span>
                                              </>
                                            )}
                                          </span>
                                          <div>
                                            {canShowEwsLastDataReceivedAtXString(
                                              patientData?.AllData?.earlyWarning
                                            ) &&
                                              !patientData?.AllData
                                                ?.earlyWarning?.score && (
                                                <>
                                                  <span>
                                                    {t(
                                                      "dashboard.table.last_updated"
                                                    )}
                                                  </span>
                                                  <br />
                                                  <span>
                                                    {
                                                      patientData?.AllData
                                                        ?.earlyWarning
                                                        ?.timeStamp
                                                    }
                                                  </span>
                                                </>
                                              )}
                                          </div>
                                        </>
                                      )}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={hook?.ward ? filteredRows?.length : rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          </div>
          <div className="allWard">
            {/* <h1>{t("dashboard.sidebar.allwards")}</h1> */}
            <div
              style={{ marginRight: "6%", marginTop: 15 }}
              className="d-flex align-items-end"
            >
              <div
                className="d-flex align-items-baseline"
                style={{ marginBottom: "10px" }}
              >
                <IsanSelect
                  id="byWardId"
                  name="byWardId"
                  label="asdfd"
                  style={{ marginBottom: "1%" }}
                  onChange={(e: any) => setSelectedWard(e.target.value)}
                  value={!isNaN(ward) ? parseInt(ward) : ""}
                  options={fetchWard?.data?.map(wardToOption) || []}
                />
              </div>
            </div>
            <div
              className="form-group d-flex mb-6 validated justify-content-between"
              style={{ marginBottom: "1px" }}
            >
              <div style={{ width: "11rem", height: "auto" }}>
                <span style={{ display: "flex" }}>
                  <input
                    type="checkbox"
                    checked={showEmpty}
                    onClick={handleChangeCheckBox}
                    name="download"
                    id="download"
                    style={{ marginTop: "-2rem", marginRight: "10px" }}
                  />
                  <label
                    htmlFor="email"
                    className="mr-2 c-b w-20"
                    style={{ fontSize: "15px" }}
                  >
                    {t("dashboard.sidebar.showemptybeds")}
                  </label>
                </span>
              </div>

              <div className="timer" style={{ minWidth: "50px" }}></div>
            </div>
            <div style={{ height: "auto" }}>
              <span style={{ fontWeight: 400 }}>
                <input
                  type="checkbox"
                  checked={endSession}
                  onClick={handleShowEndSession}
                  name="download"
                  id="download"
                  style={{ marginTop: "-2rem", marginRight: "10px" }}
                />

                <label
                  htmlFor="email"
                  className="mr-2 c-b w-20"
                  style={{ fontSize: "15px" }}
                >
                  {t("dashboard.sidebar.show_endsession")}
                </label>
              </span>
            </div>
            <div
              className="wardSidebar"
              style={{
                height:
                  dashboardDetail.filter(
                    (data: any) => data.connectivityState === 0
                  ).length > 0
                    ? 120
                    : "auto",
              }}
            >
              <div className="d-flex">
                <div
                  className="bed-indicator"
                  style={{ background: "red" }}
                ></div>
                <p className="text">
                  {t("dashboard.sidebar.gatewaysnottalking")}
                </p>
                <br />
              </div>
              <div className="wardDetail">
                {dashboardDetail
                  .filter((data: any) => data.connectivityState === 0)
                  .map((ward: any, index: number) => (
                    <div
                      key={`dashboard-ward-${index}`}
                    >{`${ward.wardName} - Last Data: Unknown`}</div>
                  ))}
              </div>
            </div>
            <div className="wardSidebar">
              <div className="d-flex">
                <div
                  className="bed-indicator"
                  style={{ background: "#FFD700" }}
                ></div>
                <p className="text">
                  {" "}
                  {t("dashboard.sidebar.gatewaysnotcharging")}
                </p>
              </div>
              <div className="wardDetail">
                {dashboardDetail
                  .filter((data: any) => data.connectivityState === 2)
                  .map((ward: any) => (
                    <div>{`${ward.wardName} - Last Data: Unknown`}</div>
                  ))}
              </div>
            </div>
            <div className="d-flex wardSidebar">
              <img src={LeadsOff} alt="leadsOff"></img>
              <p className="text">{t("dashboard.sidebar.leadsoff")}</p>
            </div>
            <div className="d-flex wardSidebar">
              <img src={outofrange} alt="out of range"></img>
              <p className="text">{t("dashboard.sidebar.otofrange")}</p>
            </div>
            <div className="d-flex wardSidebar">
              <img src={cross} alt="cross"></img>
              <p className="text">{t("dashboard.sidebar.removed")}</p>
            </div>
            <div className="d-flex wardSidebar">
              <img src={lowbattery} alt="low battery"></img>
              <p className="text">{t("dashboard.sidebar.wardbattery")}</p>
            </div>
            <div
              className="loggedUser"
              style={{
                fontSize: "15px",
                marginBottom: "5px",
              }}
            >
              User logged in = {userDetail?.userName}
            </div>
            <div
              className="logged-user"
              style={{
                fontSize: "15px",
                marginBottom: "5px",
                position: "absolute",
                bottom: "10px",
              }}
            >
              User logged in = {userDetail?.userName}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
