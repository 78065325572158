import { useEffect } from "react";
import { createGlobalState } from "react-hooks-global-state";
import Battery from "@/assets/battery-0.png";
import Battery25 from "@/assets/battery-25.png";
import Battery50 from "@/assets/battery-50.png";
import Battery75 from "@/assets/battery-75.png";

import Battery100 from "@/assets/battery-100.png";
interface user {
  authorisationKey: string;
  email: string;
  roleType: number;
  userDetailsId: number;
}

const initialState = {
  ward: null as string | any,
  user: {} as user,
};

const { useGlobalState } = createGlobalState(initialState);

export const useGlobalVar = () => {
  const [ward, setSelectedWard] = useGlobalState("ward");
  const [user, setUser] = useGlobalState("user");

  useEffect(() => {
    const userDetail = localStorage.getItem("userDetail");
    if (userDetail) {
      setUser(JSON.parse(userDetail));
    }
  }, [setUser]);

  return {
    ward,
    user,
    setSelectedWard,
  };
};

export const getBatteryIconFromBatteryPercentage = (batteryPercentage: any) => {
  if (batteryPercentage === 100) return Battery100;
  else if (batteryPercentage >= 75) return Battery75;
  else if (batteryPercentage >= 50) return Battery50;
  else if (batteryPercentage >= 25) return Battery25;
  else if (batteryPercentage >= 10) return Battery;
  else return Battery;
};

export const getMaxData = [
  { name: "ERROR_CODES", value: 0xf000 },
  { name: "ERROR_CODE__LIFETOUCH_LEADS_OFF", value: 0xf001 },
  { name: "ERROR_CODE__LIFETOUCH_LEADS_ON", value: 0xf002 },
  { name: "ERROR_CODE__NONIN_WRIST_OX_LEADS_OFF", value: 0xf003 },
  { name: "ERROR_CODE__LIFETOUCH_NO_TIMESTAMP", value: 0xf004 },
  { name: "ERROR_CODE__LIFETEMP_LEADS_OFF", value: 0xf005 },
  { name: "ERROR_CODE__NOT_ENOUGH_DATA_FOR_ALGORITHM", value: 0xf006 },
  { name: "ERROR_CODE__LIFETOUCH_CONNECTION_TIMEOUT", value: 0xf007 },
  { name: "ERROR_CODE__LIFETOUCH_NO_BEATS_DETECTED", value: 0xf008 },
  { name: "ERROR_CODE__LIFETOUCH_POOR_SIGNAL_IN_LAST_MINUTE", value: 0xf009 },
  { name: "ERROR_CODE__LIFETEMP_RESET_CALLEE_IDENTIFIER", value: 0xf00a },
  { name: "ERROR_CODE__LIFETEMP_RESET_ERROR_CODE", value: 0xf00b },
  { name: "ERROR_CODE__LIFETEMP_RESET_LINE_NUMBER", value: 0xf00c },
  { name: "ERROR_CODE__LIFETOUCH_DATA_CREDIT_TIMEOUT", value: 0xf00d },
  { name: "ERROR_CODE__LIFETOUCH_ACK_TIMEOUT", value: 0xf00e },
  { name: "ERROR_CODE__AND_MEASUREMENT_ERROR", value: 0xf00f },
  { name: "ERROR_CODE__LIFETOUCH_ORIENTATION_BASE", value: 0xf010 },
  { name: "ERROR_CODE__LIFETOUCH_ORIENTATION_UNKNOWN", value: 0xf010 },
  { name: "ERROR_CODE__LIFETOUCH_ORIENTATION_UPRIGHT", value: 0xf011 },
  { name: "ERROR_CODE__LIFETOUCH_ORIENTATION_LEFT_SIDE", value: 0xf012 },
  { name: "ERROR_CODE__LIFETOUCH_ORIENTATION_RIGHT_SIDE", value: 0xf013 },
  { name: "ERROR_CODE__LIFETOUCH_ORIENTATION_FRONT", value: 0xf014 },
  { name: "ERROR_CODE__LIFETOUCH_ORIENTATION_BACK", value: 0xf015 },
  { name: "ERROR_CODE__LIFETOUCH_ORIENTATION_UPSIDE_DOWN", value: 0xf016 },
  { name: "ERROR_CODE__LIFETOUCH_ADVERTISING_TIMEOUT", value: 0xf020 },
  { name: "ERROR_CODE__LIFETOUCH_NO_DATA", value: 0xffff },
  { name: "ERROR_CODE__LIFETOUCH_ORIENTATION_UNKNOWN", value: 0xf010 },
];
