import clsx from "clsx";
import { Form } from "react-bootstrap";

const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

type InputProps = {
  text: string | undefined;
  inputTouched: boolean | undefined;
} & React.ComponentProps<typeof Form.Text>;

export function IsanErrorLabel({
  text,
  inputTouched,
  className,
  ...rest
}: InputProps) {
  if (text !== undefined && inputTouched === undefined) return <></>;

  return (
    <div {...rest} className={clsx(className, "invalid-feedback")}>
      {text && capitalize(text)}
    </div>
  );
}
