import { Breadcum, IsanButton, TopBar } from "@/components";
import { Modal } from "react-bootstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import { createSearchParams, useNavigate } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { useEffect, useState } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useAlert } from "react-alert";
import Loader from "@/Loader/Loader";
import APIHelper from "@/utils/ApiHelper";

type Ward = {
  WardName: string;
  Timestamp: string;
  WardDetailsId: number;
  Status: number;
};

const Status: Record<number, string> = {
  0: "Enabled",
  1: "Disabled",
};

function WardList() {
  const { t } = useTranslation();
  const [rowDelete, setRowDelete] = useState<Ward | undefined | any>(undefined);
  const [wardThresholdArray, setWardThresholdArray] = useState<any[]>([]);
  const [getWardDetail, setWardDetail] = useState<any>([]);
  const navigate = useNavigate();
  const alert = useAlert();
  const goTo = () => navigate("/dashboard/wardForm");

  const getThresholdSetNamesString = function (wardId: number) {
    let lineEnding = ", ";
    let displayString = "";
    // debugger;
    for (var i = 0; i < wardThresholdArray.length; i++) {
      if (wardThresholdArray[i].WardDetailsId === wardId) {
        displayString = displayString.concat(
          wardThresholdArray[i].ThresholdSetName
        );
        displayString = displayString.concat(lineEnding);
      }
    }

    if (displayString.length !== 0) {
      displayString = displayString.substring(
        0,
        displayString.length - lineEnding.length
      );

      findThresholdByName(displayString);

      return <> {displayString}</>;
    } else {
      return <>------</>;
    }
  };

  let thresholdSetNames: any;
  const findThresholdByName = (thresholdNamesString: string) => {
    const thresholdNames = thresholdNamesString.split(", ");
    const foundThresholds = wardThresholdArray.filter((obj) =>
      thresholdNames.includes(obj.ThresholdSetName)
    );

    thresholdSetNames = foundThresholds.map((obj) => obj.ThresholdSetName);

    return foundThresholds;
  };

  const EditIconComponent = (row: Ward) => {
    const navigate = useNavigate();
    const params = createSearchParams(row as unknown as Record<string, string>);
    let data = "";

    if (thresholdSetNames?.length >= 1) {
      thresholdSetNames.forEach((name: any, index: any) => {
        if (name === "NEWS" || name === "PEWS") {
          data += `&${name}=true`;
        } else {
          data += `&${name}=false`;
        }
      });
    }

    const goTo = () => navigate("/dashboard/wardForm?" + params + data);
    return <EditIcon onClick={goTo} />;
  };

  const fetch = () => {
    APIHelper.getWards()
      .then((data: any) => {
        setWardDetail(data);
      })
      .catch((err: any) => {
        console.log("error =>", err);
      });
  };

  useEffect(() => {
    APIHelper.fetchWardThreshhold()
      .then((data: any) => {
        const arr = data?.data?.filter((val: any) => val.IsDeleted === 0);
        setWardThresholdArray(arr);
        fetch();
      })
      .catch(() => {
        fetch();
      });
  }, []);

  const columns: TableColumn<{
    WardName: string;
    Timestamp: string;
    WardDetailsId: number;
    Status: number;
  }>[] = [
    {
      name: t("wardform.table.id"),
      selector: (row) => row.WardDetailsId,
      sortable: true,
    },
    {
      name: t("wardform.table.description"),
      selector: (row) => row.WardName,
      sortable: true,
    },
    {
      name: t("wardform.table.status"),
      cell: (row) => (
        <span style={{ color: row.Status === 1 ? "red" : "green" }}>
          {Status[row.Status]}
        </span>
      ),
      selector: (row) => row.Status,
      sortable: true,
    },
    {
      name: t("wardform.table.edit"),
      cell: EditIconComponent,
    },
    {
      name: t("wardform.table.threshold_set"),
      cell: (row) => getThresholdSetNamesString(row.WardDetailsId),
      // cell: (row) => row?.,
    },
  ];

  const onDeleteClick = () => {
    const userDetail: any = localStorage.getItem("userDetail");
    const { authorisationKey } = JSON.parse(userDetail);
    const { byUserId, status, timestamp, wardDetailsId, wardName } = rowDelete;
    const deleteRow = {
      byUserId,
      status,
      timestamp,
      wardDetailsId,
      wardName,
      isDeleted: 1,
      authorisationKey,
    };

    APIHelper.deleteWard(deleteRow).then(() => {
      alert.success(t("wardform.alertmessage.delete"));
      setRowDelete(undefined);
      fetch();
    });
  };

  const onDeleteCancel = () => {
    setRowDelete(undefined);
  };

  const DeleteIcon = (row: Ward) => {
    const markForDelete = () => {
      setRowDelete(row);
    };

    return (
      <FontAwesomeIcon
        onClick={markForDelete}
        icon={faTrash}
        style={{ marginLeft: "15px" }}
      />
    );
  };

  return getWardDetail.loading ? (
    <Loader />
  ) : (
    <>
      <div
        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
        id="kt_wrapper"
      >
        <TopBar />
        <Breadcum />
        <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className="kt-portlet kt-portlet--mobile">
              <div
                className="kt-portlet__body kt-portlet__body--fit"
                style={{ marginTop: "13px" }}
              >
                <DataTable
                  progressPending={getWardDetail.loading}
                  title={
                    <IsanButton onClick={goTo} text={t("users.new.btn")} />
                  }
                  pagination={true}
                  columns={[
                    ...columns,
                    {
                      name: t("wardform.table.delete"),
                      cell: DeleteIcon,
                    },
                  ]}
                  data={getWardDetail.data || []}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={rowDelete !== undefined}>
        <Modal.Header closeButton>
          <Modal.Title>{t("users.new.delete")} </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{t("users.new.sure")} </p>
        </Modal.Body>

        <Modal.Footer>
          <IsanButton onClick={onDeleteCancel} text="cancel" />
          <IsanButton onClick={onDeleteClick} text="Delete" />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WardList;
