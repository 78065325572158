import {
  Breadcum,
  IsanButton,
  IsanErrorLabel,
  IsanSelect,
  IsanInput,
  TopBar,
} from "@/components";
import { useFormik } from "formik";
// import useAxios from "axios-hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
// import { AxiosRequestConfig } from "axios";
import { useTranslation } from "react-i18next";
import APIHelper from "@/utils/ApiHelper";

const wardToOption = (w: { WardName: string; WardDetailsId: number }) => {
  return { label: w.WardName, value: w.WardDetailsId };
};

function AddBed() {
  let [searchParams]: any = useSearchParams();
  const [fetchWards, setWards] = useState<any>([]);
  const [fetchReq, setReq] = useState<any>([]);
  const alert = useAlert();
  const navigate = useNavigate();
  const goTo = () => navigate("/dashboard/bedList");
  // const roleToOption = (w: number) => {
  //   return { label: w, value: w };
  // };

  const formik = useFormik({
    initialValues: {
      bedName: "",
      bedDetailsId: 0,
      byUserId: 3,
      byWardId: 0,
      isDeleted: 0,
      authorisationKey: "",
      status: 0,
    },

    onSubmit: async (values) => {
      const {
        bedName,
        bedDetailsId,
        byUserId,
        byWardId,
        authorisationKey,
        status,
        isDeleted,
      } = values;
      const body = {
        bedName,
        bedDetailsId,
        byUserId,
        byWardId,
        authorisationKey,
        status,
        isDeleted,
      };
      let alertMessage = t("addbed.alertmessage.create");

      try {
        const config = {
          data: body,
        };
        const bedId = searchParams.get("bedDetailsId");

        if (bedId) {
          // config.method = "POST";
          // config.url = "/Administration/UpdateBedDetails";
          APIHelper.updatebed(config)
            .then(() => {
              alertMessage = t("addbed.alertmessage.edit");
            })
            .catch((error: any) => {
              console.log("error => ", error);
            });
        }

        await APIHelper.createNewBed(config)
          .then((data: any) => {
            setReq(data);
          })
          .catch((error: any) => {
            console.log("error =>", error);
          });
        alert.success(alertMessage);
        goTo();
      } catch (err) {
        alert.error(t("addbed.alertmessage.create"));
      }
    },
  });

  useEffect(() => {
    const payload = {
      wardDetailsId: 0,
      byUserId: 0,
      wardName: "string",
      status: 0,
      isDeleted: 0,
      timestamp: "2024-01-16T07:07:43.724Z",
    };
    APIHelper.getWards(payload)
      .then((data: any) => {
        setWards(data);
      })
      .catch((error: any) => {
        console.log("error =>", error);
      });
  }, []);

  useEffect(() => {
    const userDetail: any = localStorage.getItem("userDetail");
    const { authorisationKey, userDetailsId } = JSON.parse(userDetail);
    formik.setFieldValue("authorisationKey", authorisationKey);
    formik.setFieldValue("byUserId", userDetailsId);

    if (searchParams.get("bedDetailsId")) {
      formik.setFieldValue("bedDetailsId", searchParams.get("bedDetailsId"));
      formik.setFieldValue("byWardId", parseInt(searchParams.get("byWardId")));
      formik.setFieldValue("byUserId", searchParams.get("byUserId"));
      formik.setFieldValue("bedName", searchParams.get("bedName"));
      formik.setFieldValue("status", searchParams.get("status"));
      formik.setFieldValue("isDeleted", searchParams.get("isDeleted"));
    }
  }, [searchParams, formik]);

  const { t } = useTranslation();

  return (
    <>
      <div
        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
        id="kt_wrapper"
      >
        <TopBar />
        <Breadcum />
        <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className="kt-portlet kt-portlet--mobile">
              <div className="kt-portlet__body kt-portlet__body--fit">
                <div className="kt-form kt-form--label-right">
                  <div className="kt-form__body">
                    <div className="text-center h3 mt-4 mb-4">
                      {t("addbed.data.addnew")}
                    </div>
                    <div className="card card-custom gutter-b example example-compact">
                      <div className="card-body">
                        <form onSubmit={formik.handleSubmit}>
                          <div className="form-group mb-3 validated">
                            <label htmlFor="Ward Description">
                              {t("addbed.data.desc")}
                            </label>
                            <IsanSelect
                              id="byWardId"
                              name="byWardId"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.byWardId}
                              options={fetchWards.data?.map(wardToOption) || []}
                            />
                            <IsanErrorLabel
                              inputTouched={formik.touched.byWardId}
                              text={formik.errors.byWardId}
                            />
                          </div>
                          <div className="form-group mb-3 validated">
                            <label htmlFor="New Bed Name">
                              {t("addbed.data.addnew")}
                            </label>
                            <IsanInput
                              id="bedName"
                              name="bedName"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.bedName}
                            />
                            <IsanErrorLabel
                              inputTouched={formik.touched.bedName}
                              text={formik.errors.bedName}
                            />
                          </div>
                          <IsanButton
                            loading={fetchReq.loading}
                            type="submit"
                            text={t("users.addnew.submit")}
                          />
                          &nbsp;
                          <IsanButton
                            disabled={fetchReq.loading}
                            type="submit"
                            text={t("users.addnew.cancle")}
                            onClick={goTo}
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddBed;
