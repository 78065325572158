import { useGlobalState } from "@/state";
import { Navigate } from "react-router-dom";

export function RequireAuth({ children }: { children: JSX.Element }) {
  const [isLogged] = useGlobalState("logged");
  if (!isLogged) {
    return <Navigate to="/" replace />;
  }

  return children;
}
