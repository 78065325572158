import { TopBar } from "@/components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGlobalVar } from "@/utils/getGlobalVar";
import InstallationQRCode from "@/assets/8859015c760c643a8290.png";
import moment from "moment";
import APIHelper from "@/utils/ApiHelper";
import { calculateDaysLeft } from "@/utils/commonFunctions";

function About() {
  const hook: any = useGlobalVar();
  const { t } = useTranslation();
  const [expiryDate, setExpiryDate] = useState("");
  const [licenseData, setLicenseData] = useState<any>([]);

  useEffect(() => {
    APIHelper.licenseData()
      .then((data: any) => {
        console.log("data ==>", data);
        setLicenseData(data?.data);
      })
      .catch((error: any) => {
        console.log("error =>", error);
      });
  }, []);

  useEffect(() => {
    const { customeConfig } = hook?.user;
    if (customeConfig) {
      const date = moment(new Date(), "DD-MM-YYYY")
        .add(hook.user.customeConfig.licenseDaysRemaining, "days")
        .format("DD MMM YYYY HH:MM");
      setExpiryDate(date.toString());
    }
  }, [hook?.user]);

  return (
    <div style={{ overflow: "hidden" }}>
      <div style={{ display: "contents" }}>
        <TopBar />
      </div>
      <div
        id="kt_wrapper"
        className="container-wrapper-about"
        style={{
          maxHeight: "92vh",
          marginTop: 60,
        }}
      >
        {/* <Breadcum /> */}
        <div
          className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
          style={{ paddingTop: "0px", paddingBottom: "0px" }}
        >
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <h1>{t("about.data.about")}</h1>
            <div>
              <div className="kt-portlet__body kt-portlet__body--fit p-3">
                <div className="mb-2">
                  {!hook?.user?.customeConfig?.nonCeMode && (
                    <div className="about-version">
                      {t("topbar.deviceidentify")}: (01)5060488680175
                    </div>
                  )}
                  <div className="about-version">
                    {t("about.data.version")}
                    <span>{hook?.user?.customeConfig?.SvnRevision ?? "0"}</span>
                  </div>
                </div>
                <p className="bottom-border" />
                <div className="mt-1 ">
                  <h3 style={{ color: "black" }}>{t("about.data.orcode")}</h3>
                  <img
                    src={InstallationQRCode}
                    alt="Patient Gateway Installation QR Code not installed. Please contact support@isansys.com"
                  />
                </div>
                <p className="bottom-border" />
                <div
                  style={{
                    border: "2px solid #D7E4A4",
                    position: "relative",
                    display: "inline-block",
                  }}
                  className=" mt-3 p-3 pb-0"
                >
                  <h3 style={{ color: "black" }} className="lincense-section">
                    {t(licenseData?.headerText)}
                  </h3>
                  <h5 className="mt-2">
                    {t("about.data.expiredate")} {calculateDaysLeft(expiryDate)}
                  </h5>
                  <h5>
                    {t("about.data.hardwareid")} {licenseData?.ThisHardwareId}
                  </h5>
                  <h5>
                    {t("about.data.ap")}{" "}
                    {licenseData?.Licensee != null
                      ? licenseData?.Licensee
                      : "----"}
                  </h5>
                </div>
                <p className="bottom-border" />
                <div className="mt-4 ">
                  <h3 style={{ color: "black" }}>{t("about.data.credits")}</h3>
                  <p>
                    {t("about.data.icon")}{" "}
                    <a className="credit-div" href="https://www.flaticon.com/">
                      {t("about.data.freepik")}
                    </a>
                    {t("about.data.from")}{" "}
                    <a className="credit-div" href="https://www.flaticon.com/">
                      www.flaticon.com
                    </a>{" "}
                    {t("about.data.by")}{" "}
                    <a
                      className="credit-div"
                      href="https://creativecommons.org/licenses/by/3.0/"
                    >
                      CC 3.0 VON
                    </a>
                  </p>
                  <p>
                    {t("about.data.icon")}{" "}
                    <a
                      className="credit-div"
                      href="https://www.flaticon.com/authors/tomas-knop"
                    >
                      Tomas Knop
                    </a>
                    {t("about.data.from")}{" "}
                    <a className="credit-div" href="https://www.flaticon.com/">
                      www.flaticon.com
                    </a>
                  </p>
                  <p>
                    {t("about.data.icon")}{" "}
                    <a
                      className="credit-div"
                      href="https://www.flaticon.com/authors/mayor-icons"
                    >
                      Mayor Icons
                    </a>
                    {t("about.data.from")}{" "}
                    <a className="credit-div" href="https://www.flaticon.com/">
                      www.flaticon.com
                    </a>
                  </p>
                  <p>
                    {t("about.data.icon")}{" "}
                    <a
                      className="credit-div"
                      href="https://www.flaticon.com/authors/kiranshastry"
                    >
                      Kiranshastry
                    </a>
                    {t("about.data.from")}{" "}
                    <a className="credit-div" href="https://www.flaticon.com/">
                      www.flaticon.com
                    </a>
                  </p>
                </div>
                <p className="bottom-border" />
                <div style={{ textAlign: "right", color: "black" }}>
                  User logged in = {hook.user?.userName ?? hook.user?.email}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
