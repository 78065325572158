import { useEffect, useState } from "react";
import { useFormik } from "formik";
import logo from "@/assets/logo.png";
import { IsanErrorLabel, IsanInput } from "@/components";
import * as yup from "yup";
import { setLoggedStatus, useGlobalState } from "@/state";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import useAxios from "axios-hooks";
import nacl from "tweetnacl";
import { decrypt } from "@/utils/naclCrypto";
import util from "tweetnacl-util";
import moment from "moment";

const schema = yup.object().shape({
  userName: yup.string().required(),
  password: yup.string().required(),
  captchaAnswer: yup.string().when("showCaptcha", {
    is: true,
    then: yup.string().required("Captcha is required"),
  }),
});

function generateMathCaptcha() {
  const a = Math.floor(Math.random() * 10);
  const b = Math.floor(Math.random() * 10);
  return {
    question: `${a} + ${b}`,
    answer: (a + b).toString(),
  };
}

function Login() {
  const [customConfig, setCustomConfig] = useState<any>({});
  const [configLoading, setConfigLoading] = useState<boolean>(true);
  const [failedAttempts, setFailedAttempts] = useState(0);
  const [captcha, setCaptcha] = useState(generateMathCaptcha());
  const navigate = useNavigate();
  const [isLogged] = useGlobalState("logged");
  const alert = useAlert();
  const { t } = useTranslation();
  const [, doLogin] = useAxios(
    {
      url: "/Account/Login",
      method: "POST",
    },
    { manual: true }
  );

  const [, getCustomUiConfig] = useAxios(
    {
      url: "/Account/GetCustomUiConfig",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
    { manual: true }
  );

  useEffect(() => {
    const keyPairFrontEnd = nacl.box.keyPair();
    var sitecustomConfig: any = getCustomUiConfig({
      data: {
        frontEndPublicKey: util.encodeBase64(keyPairFrontEnd.publicKey),
      },
    });

    sitecustomConfig
      .then(async (config: any) => {
        const uint8Array = util.decodeBase64(config.data.publicKey);
        const sharedKey: any = nacl.box.before(
          uint8Array,
          keyPairFrontEnd.secretKey
        );
        const plaintext = await decrypt(sharedKey, config.data.payload);
        setCustomConfig(JSON.parse(plaintext));
        localStorage.setItem("configUiData", plaintext);

        setTimeout(() => {
          setConfigLoading(false);
        }, 100);
      })
      .catch((err: any) => {
        setConfigLoading(false);
      });

    if (isLogged) {
      navigate("/dashboard");
    }
  }, [getCustomUiConfig, isLogged, navigate]);

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
      captchaAnswer: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      if (failedAttempts >= 1 && values.captchaAnswer !== captcha.answer) {
        alert.error(t("login.captchaError"));
        return;
      }

      const loginObj = {
        data: { userName: values.userName, password: values.password },
      };

      const loginRes = await doLogin(loginObj);

      if (loginRes.data.Success) {
        setLoggedStatus();
        const {
          email,
          authorisationKey,
          RoleType,
          UserDetailsId,
          UserCapabilities,
          Token,
        } = loginRes.data;
        const userDetail = {
          email,
          authorisationKey,
          roleType: RoleType,
          userDetailsId: UserDetailsId,
          userCapabilities: UserCapabilities,
          customeConfig: customConfig,
          token: Token,
          config: {},
          userName: values.userName,
        };
        localStorage.setItem("userDetail", JSON.stringify(userDetail));
        localStorage.setItem(
          "ISANSYSLoggedTIME",
          moment().format("YYYY-MM-DD HH:mm:ss")
        );
        navigate("/dashboard");
      } else {
        setFailedAttempts((prev) => prev + 1);
        setCaptcha(generateMathCaptcha());
        alert.error(t("login.loginError"));
      }
    },
  });

  const isLoginMessage = () => {
    return (
      customConfig?.connectionMessage &&
      customConfig?.connectionMessage.length > 0
    );
  };

  const mytext = () => {
    if (typeof customConfig?.licenseDaysRemaining !== "undefined") {
      return t("topbar.license_days_remaining").replace(
        "{0}",
        customConfig?.licenseDaysRemaining.toString()
      );
    }
  };

  return !configLoading && customConfig?.SvnRevision ? (
    <div className="kt-grid kt-grid--ver kt-grid--root min-vh-100">
      <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile grid-item-list">
          <div className="kt-header kt-titlebar kt-login-titlebar">
            <img src={logo} alt="logo" />

            <div className="title-text" style={{ minWidth: "33%" }}>
              <p>
                <span>{t("topbar.patientstatus")}</span>
              </p>
            </div>
            <div>
              <span className="version">
                {`${t("topbar.deviceidentify")}: (01)5060488680175`}
              </span>
              <h2 className="version">
                {!configLoading && (
                  <>
                    {t("topbar.softwareversion")}{" "}
                    {customConfig?.SvnRevision ?? 0}
                  </>
                )}
              </h2>
              {isLoginMessage() && (
                <div>
                  <div>{mytext()}</div>
                  <div>
                    {t("topbar.contact_support")}{" "}
                    <a href="mailto:support@isansys.com">Isansys Support</a>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper grid-wrapper-item">
            <div className="kt-login__body">
              <div className="kt-login__form">
                <form className="kt-form" onSubmit={formik.handleSubmit}>
                  <div className="form-group mb-3 validated d-flex align-items-center">
                    <label htmlFor="userName">{t("login.userName")}:</label>
                    <IsanInput
                      id="userName"
                      name="userName"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.userName}
                    />
                  </div>
                  <IsanErrorLabel
                    inputTouched={formik.touched.userName}
                    text={formik.errors.userName}
                  />

                  <div className="form-group validated d-flex align-items-center">
                    <label htmlFor="password">{t("login.password")}:</label>
                    <IsanInput
                      id="password"
                      name="password"
                      type="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                  </div>
                  <IsanErrorLabel
                    inputTouched={formik.touched.password}
                    text={formik.errors.password}
                  />

                  {failedAttempts >= 1 && (
                    <div className="form-group validated d-flex align-items-center">
                      <label htmlFor="captchaAnswer">
                        {t("login.captchaQuestion", {
                          question: captcha.question,
                        })}
                        :
                      </label>
                      <IsanInput
                        id="captchaAnswer"
                        name="captchaAnswer"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.captchaAnswer}
                      />
                      <IsanErrorLabel
                        inputTouched={formik.touched.captchaAnswer}
                        text={formik.errors.captchaAnswer}
                      />
                    </div>
                  )}

                  <div className="kt-login__actions">
                    <button className="login-button">{t("login.login")}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default Login;
