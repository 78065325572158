import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

interface IstateContext {
  configData: any;
  setConfigData: Dispatch<SetStateAction<any>>;
}

const StateContext = createContext<IstateContext | undefined>(undefined);

export const useStateContext = () => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a StateProvider");
  }
  return context;
};

export const StateProvider = ({ children }: any) => {
  const [configData, setConfigData] = useState<any>();
  return (
    <StateContext.Provider
      value={{
        configData,
        setConfigData,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
