import axios, { AxiosResponse } from "axios";
import { Backend_Url } from "./AxiosBootstrap";

const baseURL = `${Backend_Url}/api`;

const APIENDPOINTS = {
  getWards: baseURL + "/Administration/GetWards",
  getBed: baseURL + "/Administration/GetBedDetails",
  deleteBed: baseURL + "/Administration/DeleteBed",
  getThreshold: baseURL + "/Administration/GetRawWardsAndWardThresolds",
  deleteWard: baseURL + "/Administration/DeleteWard",
  historicalPatient: baseURL + "/Measurements/GetHistoricalPatientSessions",
  updatePatient: baseURL + "/Patients/UpdateSessionsPatientId",
  setupClick: baseURL + "/Patients/SetupModeButtonClicked",
  setUpOrigin: baseURL + "/Patients/SetupModeOriginState",
  rowAccelatorClick: baseURL + "/Patients/RawAccelerometerModeButtonClicked",
  getRowAccelatorOrigin: baseURL + "/Patients/RawAccelerometerModeOriginState",
  reloadCache: baseURL + "/Administration/ReloadCache",
  createNewBed: baseURL + "/Administration/CreateNewBed",
  updatebed: baseURL + "/Administration/UpdateBedDetails",
  createUser: baseURL + "/Administration/RegisterUser",
  updateUserInfo: baseURL + "/Administration/UpdateUserInfo",
  CreateNewWard: baseURL + "/Administration/CreateNewWard",
  updateWardDetail: baseURL + "/Administration/UpdateWardDetails",
  thresholdInfo: baseURL + "/Administration/GetThresholdsetInfo",
  getWardDetail: baseURL + "/Administration/GetUserWards",
  AddUserWard: baseURL + "/Administration/UserWardsADD",
  requestIntialDashbord: baseURL + "/Administration/RequestInitialDashboard",
  GetUserDetails: baseURL + "/Administration/GetUserDetails",
  patientSummary: baseURL + "/Patients/PatientSessionSummary",
  licenseData: baseURL + "/Patients/HandleLicensingAboutPage",
  exportdata: baseURL + "/Measurements/Export",
  exportPdf: baseURL + "/Measurements/ExportPdf",
};

const lastCallTimestamps: { [url: string]: number } = {};
const throttleLimit = 1000;

export const doPost = (url: string, body: any): Promise<any> => {
  const { token } = JSON.parse(localStorage.getItem("userDetail")!);

  return new Promise((resolve, reject) => {
    // const now = Date.now();
    // const lastCallTimestamp = lastCallTimestamps[url];

    // if (lastCallTimestamp && now - lastCallTimestamp < throttleLimit) {
    //   resolve("Throttling");
    //   return;
    // }

    // lastCallTimestamps[url] = now;

    axios({
      url: url,
      method: "POST",
      data: { ...body },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res: AxiosResponse<any>) => resolve(res))
      .catch((err: any) => reject(err));
  });
};

export const doGet = (url: string): Promise<any> => {
  const { token } = JSON.parse(localStorage.getItem("userDetail")!);

  return new Promise((resolve, reject) => {
    const now = Date.now();
    const lastCallTimestamp = lastCallTimestamps[url];

    if (lastCallTimestamp && now - lastCallTimestamp < throttleLimit) {
      resolve("Throttling");
      return;
    }

    lastCallTimestamps[url] = now;

    axios({
      url: url,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res: AxiosResponse<any>) => resolve(res))
      .catch((err: any) => reject(err));
  });
};

export const doDelete = (url: string, body: any): Promise<any> => {
  const { token } = JSON.parse(localStorage.getItem("userDetail")!);

  return new Promise((resolve, reject) => {
    const now = Date.now();
    const lastCallTimestamp = lastCallTimestamps[url];

    if (lastCallTimestamp && now - lastCallTimestamp < throttleLimit) {
      resolve("Throttling");
      return;
    }

    lastCallTimestamps[url] = now;

    axios({
      url: url,
      method: "DELETE",
      data: body,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res: AxiosResponse<any>) => resolve(res))
      .catch((err: any) => reject(err));
  });
};

export const doGetWithPayload = (url: string, body: any): Promise<any> => {
  const { token } = JSON.parse(localStorage.getItem("userDetail")!);

  return new Promise((resolve, reject) => {
    const now = Date.now();
    const lastCallTimestamp = lastCallTimestamps[url];

    if (lastCallTimestamp && now - lastCallTimestamp < throttleLimit) {
      resolve("Throttling");
      return;
    }

    lastCallTimestamps[url] = now;

    axios({
      url: url,
      method: "GET",
      data: { ...body },

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res: AxiosResponse<any>) => resolve(res))
      .catch((err: any) => reject(err));
  });
};

const getWards = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.getWards, body);
};

const getBedList = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.getBed, body);
};

const getDeleteBed = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.deleteBed, body);
};

const fetchWardThreshhold = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.getThreshold, body);
};

const deleteWard = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.deleteWard, body);
};

const getHistoricalData = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.historicalPatient, body);
};

const updatePatiet = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.updatePatient, body);
};

const getSetupButtonClick = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.setupClick, body);
};

const getSetupOriginState = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.setUpOrigin, body);
};

const getRowAccelator = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.rowAccelatorClick, body);
};

const getRowAccelatorOrigin = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.getRowAccelatorOrigin, body);
};

const setReloadCache = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.reloadCache, body);
};

const createNewBed = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.createNewBed, body);
};

const updatebed = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.updatebed, body);
};

const registerUser = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.createUser, body);
};

const updateUser = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.updateUserInfo, body);
};

const createNewWard = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.CreateNewWard, body);
};

const updateWard = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.updateWardDetail, body);
};

const getThresholdsetInfo = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.thresholdInfo, body);
};

const getAllUserWards = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.getWardDetail, body);
};

const addUserWard = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.AddUserWard, body);
};

const requestIntialDashbord = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.requestIntialDashbord, body);
};

const GetUserDetails = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.GetUserDetails, body);
};

const patientSummary = (body?: any): Promise<any> => {
  return doPost(APIENDPOINTS.patientSummary, body);
};

const licenseData = (): Promise<any> => {
  return doGet(APIENDPOINTS.licenseData);
};

const exportData = (body?: any): Promise<any> => {
  return doGetWithPayload(APIENDPOINTS.exportdata, body);
};

const exportPdf = (body?: any): Promise<any> => {
  return doGetWithPayload(APIENDPOINTS.exportPdf, body);
};

export const APIHelper = {
  getWards,
  getBedList,
  getDeleteBed,
  fetchWardThreshhold,
  deleteWard,
  getHistoricalData,
  updatePatiet,
  getSetupButtonClick,
  getSetupOriginState,
  getRowAccelator,
  getRowAccelatorOrigin,
  setReloadCache,
  createNewBed,
  updatebed,
  registerUser,
  updateUser,
  createNewWard,
  updateWard,
  getThresholdsetInfo,
  getAllUserWards,
  addUserWard,
  requestIntialDashbord,
  GetUserDetails,
  patientSummary,
  licenseData,
  exportData,
  exportPdf,
};
export default APIHelper;
