type IsanSelectProps = {
  options: { label: string; value: number }[];
} & React.HTMLProps<HTMLSelectElement>;

export function IsanSelect({ value, options, ...rest }: IsanSelectProps) {
  return (
    <select {...rest} className="form-control">
      <option defaultValue={value === undefined ? "" : value} value={undefined}>
      All Wards
      </option>
      {options.map((o) => {
        return (
          <option key={o.value} selected={value === o.value} value={o.value}>
            {o.label}
          </option>
        );
      })}
    </select>
  );
}
