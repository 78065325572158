import { IsanInput } from "@/components";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const PatientSummaryedit = (props: any) => {
  const [patientData, setPatientdata] = useState<any>(props?.patientData);

  useEffect(() => {
    setPatientdata(props?.patientData);
  }, [props?.patientData]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setPatientdata({ ...patientData, [name]: value });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit Patient ID</DialogTitle>
        <DialogContent>
          <div className="form-group mb-3 validated ">
            <label htmlFor="contactName">Patient ID:</label>
            <IsanInput
              id="hospitalPatientId"
              name="hospitalPatientId"
              onChange={handleChange}
              value={patientData?.hospitalPatientId}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.updateDetail(patientData)}>
            Update
          </Button>
          <Button onClick={props.handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PatientSummaryedit;
