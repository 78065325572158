import * as React from "react";
import { TopBar, Breadcum, IsanSelect, IsanInput } from "@/components";
import pako from "pako";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { createConnection } from "@/utils/signarrConnection";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import PatientSummaryedit from "./PatientEditModals/PatientSummaryedit";
import PatientSummaryDelete from "./PatientEditModals/PatientSummaryDelete";
import { Backend_Url } from "@/utils/AxiosBootstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DownloadPatientSummary from "./PatientEditModals/DownloadPatientSummary";
import { Button } from "react-bootstrap";
import Loader from "@/Loader/Loader";
import APIHelper from "@/utils/ApiHelper";
// import { encryptData } from "@/utils/commonFunctions";
// import CryptoJS from "crypto-js";

interface Data {
  Edit: string;
  Delete: string;
  bedName: string;
  SessionStart: string;
  SessionEnd: number;
  Duration: number;
  Download: string;
  Visualise: string;
  Summary: string;
  hospitalPatientId: string;
  wardName: string;
  allData: any;
  Report: string;
}

function createData(
  Edit: string,
  Delete: string,
  hospitalPatientId: string,
  wardName: string,
  bedName: string,
  SessionStart: string,
  SessionEnd: number | any,
  Duration: number,
  Download: string,
  Visualise: string,
  Summary: string,
  allData: any,
  Report: string
): Data {
  return {
    Edit,
    Delete,
    hospitalPatientId,
    wardName,
    bedName,
    SessionStart,
    SessionEnd,
    Duration,
    Download,
    Visualise,
    Summary,
    allData,
    Report,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  display: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "Edit",
    numeric: true,
    disablePadding: false,
    label: "Edit",
    display: false,
  },
  {
    id: "Delete",
    numeric: true,
    disablePadding: false,
    label: "Delete",
    display: false,
  },
  {
    id: "hospitalPatientId",
    numeric: false,
    disablePadding: false,
    label: "Patient ID",
    display: true,
  },
  {
    id: "wardName",
    numeric: false,
    disablePadding: false,
    label: "Ward",
    display: true,
  },
  {
    id: "bedName",
    numeric: false,
    disablePadding: false,
    label: "Bed",
    display: true,
  },
  {
    id: "SessionStart",
    numeric: false,
    disablePadding: false,
    label: "Session Start",
    display: true,
  },
  {
    id: "SessionEnd",
    numeric: false,
    disablePadding: false,
    label: "Session End",
    display: true,
  },
  {
    id: "Duration",
    numeric: false,
    disablePadding: false,
    label: "Duration",
    display: true,
  },
  {
    id: "Download",
    numeric: true,
    disablePadding: false,
    label: "Download",
    display: false,
  },
  {
    id: "Report",
    numeric: true,
    disablePadding: false,
    label: "Report",
    display: false,
  },
  {
    id: "Visualise",
    numeric: true,
    disablePadding: false,
    label: "Visualise",
    display: false,
  },
  {
    id: "Summary",
    numeric: true,
    disablePadding: false,
    label: "Summary",
    display: false,
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const wardToOption = (w: { WardName: string; WardDetailsId: number }) => {
  return { label: w.WardName, value: w.WardDetailsId };
};

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={headCell.display ? orderBy === headCell.id : false}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={true}
            >
              {t(`historical.table.${headCell.label}`)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const convertCompressedDataBackToJson = (b64Data: string) => {
  var strData = atob(b64Data);
  var charData = strData.split("").map(function (x) {
    return x.charCodeAt(0);
  });
  var binData = new Uint8Array(charData);
  var data = pako.inflate(binData);
  var json = new TextDecoder().decode(data);
  return json;
};

const getDownloadIPAddress = async () => {
  var findIP: any = new Promise(function (r) {
    var w: any = window,
      a = new (w.RTCPeerConnection ||
        w.mozRTCPeerConnection ||
        w.webkitRTCPeerConnection)({ iceServers: [] }),
      b = function b() {};
    a.createDataChannel("");
    a.createOffer(function (c: any) {
      return a.setLocalDescription(c, b, b);
    }, b);
    a.onicecandidate = function (c: any) {
      try {
        c.candidate.candidate
          .match(
            /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g
          )
          .forEach(r);
      } catch (e) {}
    };
  });

  findIP
    .then(function (ip: any) {
      return ip;
    })
    .catch(function (e: any) {
      return console.error(e);
    });
};

export default function Historical() {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("wardName");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [historicalData, setHistoricalData] = React.useState<any>([]);
  const [filter, setFilter] = React.useState<any>({});
  const [connection, setConnection] = React.useState<any>();
  const [editDiolog, setEditDiolog] = React.useState<any>(false);
  const [editData, setEditData] = React.useState<any>({});
  const [deleteDiolog, setDeleteDiolog] = React.useState<any>(false);
  const [deletetData, setDeleteData] = React.useState<any>({});
  // const [historyData, setHistoryData] = React.useState<any>();
  const [wardData, setWardData] = React.useState<any>([]);
  const [downloadFiles, setDownloadFiles] = React.useState<any>({
    open: false,
    historicalData: [],
  });
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);

  const [buttonIndex, setButtonIndex] = React.useState<any>("");
  const [ReportIndex, setReportIndex] = React.useState<any>("");

  const iframeRef: any = React.useRef(null);
  React.useEffect(() => {
    const data = localStorage.getItem("configUiData");
    if (data) {
      const config = JSON.parse(data);
    }
  }, []);

  const goTo = useNavigate();
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getWards = () => {
    const payload = {
      wardDetailsId: 0,
      byUserId: 0,
      wardName: "string",
      status: 0,
      isDeleted: 0,
      timestamp: "2024-01-16T07:07:43.724Z",
    };

    APIHelper.getWards(payload)
      .then((data) => {
        setWardData(data);
      })
      .catch((err) => {
        console.log("error =>", err);
      });
  };

  const setupHistoricalTable = (historicalData: any) => {
    try {
      const data: any = JSON.parse(
        convertCompressedDataBackToJson(historicalData)
      );

      const downloadExportButton = {
        label: "Download",
        disabled: false,
        title: "click to start to download the file",
        extra: "(Shift+Click for Verbose, Ctrl+Click to regenerate)",
      };
      const exportSummary = {
        label: "Summary",
        disabled: false,
        title: "Get Summary of export from other page",
      };
      const updatedHistoricData = data?.map((data: any) => {
        return {
          ...data,
          hasExportSubscription: false,
          exportButton: downloadExportButton,
          summaryButton: exportSummary,
        };
      });
      setHistoricalData(updatedHistoricData);
      if (updatedHistoricData) {
        let data = updatedHistoricData.map((val: any) =>
          createData(
            "",
            "",
            val.hospitalPatientId,
            val.wardName,
            val.bedName,
            val?.patientStartSessionTime,
            val?.patientEndSessionTime,
            durationStringToMilliseconds(
              val?.patientEndSessionTime ?? "",
              val?.patientStartSessionTime ?? ""
            ),
            "",
            "",
            "",
            val,
            ""
          )
        );
        setRows(data);
      }
    } catch (err) {
      console.log("Error in historical data", err);
    }
  };

  React.useEffect(() => {
    setLoading(true);
    const config = {};
    APIHelper.getHistoricalData(config)
      .then((data) => {
        console.log("data => ", data);

        setupHistoricalTable(data.data);
      })
      .finally(() => {
        setLoading(false);
      });

    getWards();

    const signalRconnection: any = createConnection(`${Backend_Url}/signalr`);
    signalRconnection
      .start()
      .then(() => console.log("SignalR connection Start"));
    setConnection(signalRconnection);
  }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { t } = useTranslation();

  const durationStringToMilliseconds = (
    startTime: string,
    endTime: string
  ): number => {
    const startMoment = moment(startTime);
    const endMoment = moment(endTime);
    const durationInMilliseconds = endMoment.diff(startMoment);
    return durationInMilliseconds;
  };

  const getDuration = (endDate: any, startDate: any) => {
    const duration = moment.duration(moment(endDate).diff(startDate));
    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.hours());
    const minites = Math.floor(duration.minutes());
    const seconds = Math.floor(duration.seconds());

    return `${days}d:${hours}h:${minites}m:${seconds}s`;
  };

  const handleChangeFilter = React.useCallback(
    (name: any, value: any) => {
      const updatedFilter = { ...filter, [name]: value };
      setFilter(updatedFilter);
      const config = JSON.parse(JSON.stringify(updatedFilter), (key, value) =>
        value === ""
          ? undefined
          : key === "start" || key === "end"
          ? moment(value).format().split("T")[0]
          : value
      );

      const applyFilters = (config: any, rows: any) => {
        let filteredRows = rows;

        if (config.wardId) {
          filteredRows = filteredRows.filter(
            (row: any) => row.wardDetailsId === config.wardId
          );
        }

        if (config.start) {
          filteredRows = filteredRows.filter(
            (row: any) =>
              row.patientStartSessionTime.split("T")[0] === config.start
          );
        }

        if (config.end) {
          filteredRows = filteredRows.filter(
            (row: any) => row.patientEndSessionTime.split("T")[0] === config.end
          );
        }

        if (config.hospitalPatientId) {
          const searchPatientId = config.hospitalPatientId.toLowerCase();
          filteredRows = filteredRows.filter((row: any) => {
            if (typeof row.hospitalPatientId === "string") {
              return row.hospitalPatientId
                .toLowerCase()
                .includes(searchPatientId);
            }
            return false;
          });
        }
        return filteredRows;
      };

      const filteredRows = applyFilters(config, historicalData);

      if (filteredRows) {
        let data = filteredRows.map((val: any) =>
          createData(
            "",
            "",
            val.hospitalPatientId,
            val.wardName,
            val.bedName,
            val.patientStartSessionTime,
            val.patientEndSessionTime,
            durationStringToMilliseconds(
              val?.patientEndSessionTime ?? "",
              val?.patientStartSessionTime ?? ""
            ),
            "",
            "",
            "",
            val,
            ""
          )
        );
        setRows(data);
      }
    },
    [filter, historicalData]
  );

  React.useEffect(() => {
    let timeout: any = "";
    if (filter?.hospitalPatientId) {
      timeout = setTimeout(() => {
        handleChangeFilter("hospitalPatientId", filter?.hospitalPatientId);
      }, 500);
    }

    return () => clearTimeout(timeout);
  }, [filter.hospitalPatientId]);

  const getPublicationPayload = (
    serverIPAddress: any,
    patientSessionId: any,
    verbose: any,
    forceOverwrite: any,
    convertTemperatureToFahrenheit: any
  ) => {
    var targetNamePrefix = "Session_ID_" + patientSessionId;

    var targetName = targetNamePrefix + ".zip";
    if (verbose) {
      targetName = targetNamePrefix + "_V.zip";
    }

    var commandline =
      "-psid " +
      patientSessionId +
      " -sa " +
      serverIPAddress +
      " -xlsx -log " +
      targetNamePrefix +
      ".log -lang " +
      "en";
    if (convertTemperatureToFahrenheit) {
      commandline += " -f";
    }

    if (verbose) {
      commandline = commandline.concat(" -verbose");
    }

    if (filter?.anonymousDownloads) {
      commandline = commandline.concat(" -anonData");
    }

    var event = {
      name: "pubSubHistoricExport",
      enabled: true,
      commandline: commandline,
      unique_id: patientSessionId,
      target_name: targetName,
      forceRegeneration: forceOverwrite,
    };

    return JSON.stringify(event);
  };

  const pubSubHistoricExport = async (item: any, index: any) => {
    setButtonIndex(index);
    const verbose = false;
    item.hasExportSubscription = false;
    let configData = {
      ServerAddressForExport: "",
    };

    const data: any = localStorage.getItem("configUiData");
    if (data) {
      configData = JSON.parse(data);
    }
    if (item.hasExportSubscription === false) {
      var payload = {
        ServerAddressForExport: configData?.ServerAddressForExport,
        PatientSessionId: item?.allData?.patientSessionId,
        Verbose: verbose,
        ForceOverwrite: false,
        ConnectionId: connection?.connectionId,
        Subscription: `https:\\\\sandbox.isansys.com\\DatabaseExtractor\\DownloadResponse\\${item?.allData?.patientSessionId}`,
        Publication: "https:\\\\sandbox.isansys.com",
        PublicationPayload: getPublicationPayload(
          "https:\\\\sandbox.isansys.com",
          item?.allData?.patientSessionId,
          verbose,
          false,
          false
        ),
      };
      const payloadStr = JSON.stringify(payload);
      connection.invoke("RealTimeHistoricExportProxy", payloadStr);
      item.hasExportSubscription = true;
    }
    getDownloadIPAddress().then((ip) => console.log("ip ===> ", ip));

    const { authorisationKey, userDetailsId } = JSON.parse(
      localStorage.getItem("userDetail") as any
    );
    var jsonpayload = {
      AuditLogID: 1,
      UserId: userDetailsId,
      userName: "",
      PatientSessionId: item?.allData?.patientSessionId,
      GatewayId: authorisationKey,
      Action: 1,
      Details:
        "WardName:" +
        " " +
        item?.wardName +
        " " +
        " " +
        "BedName:" +
        " " +
        item?.bedName,
      ActionDate: new Date().toJSON(),
    };

    var json = JSON.stringify(jsonpayload);
    connection.invoke("InsertAuditData", json);
  };

  //Export report
  const generateReportProgressCallback = React.useCallback(
    (json: any) => {
      let eventData = JSON.parse(json);

      let patientSessionId = parseInt(eventData.PatientSessionId);
      let item = historicalData.find(
        (val: any) => val?.patientSessionId === patientSessionId
      );
      if (eventData.complete === true) {
        const { token } = JSON.parse(localStorage.getItem("userDetail")!);

        let payload = {
          fileAndPath: eventData.FileOutput,
          patientSessionId: patientSessionId,
        };

        let outgoingJson = JSON.stringify(payload);

        const apiUrl = `${process.env.REACT_APP_API_URL}/api/Measurements/ExportPdf?payload=${outgoingJson}`;

        fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            if (response.ok) {
              setButtonIndex("");
              return response.blob();
            } else if (response.status === 500) {
              console.error("API error 500");
              setButtonIndex("");
              return Promise.reject(new Error("API error 500"));
            } else {
              setButtonIndex("");
              throw new Error(`API error ${response.status}`);
            }
          })
          .then((blob) => {
            setButtonIndex("");
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${item.hospitalPatientId}.pdf`;
            a.click();
          })
          .catch((error) => {
            setButtonIndex("");
            console.error("Error:", error);
          });
      }
    },
    [historicalData, setButtonIndex]
  );

  const generateReport = async (item: any, index: any) => {
    await setReportIndex(index);
    var payload = {
      PatientSessionId: item?.allData?.patientSessionId,
      HospitalPatientId: item?.allData?.hospitalPatientId,
      ForceRegeneration: true,
      ConnectionId: connection?.connectionId,
    };

    await connection?.invoke("GenerateReport", JSON.stringify(payload));
    await setReportIndex("");
  };

  // Excel download
  const historicExportCallback = React.useCallback(
    (event: any) => {
      const eventData = JSON.parse(event);
      const patientSessionId = parseInt(eventData.unique_id);
      let item =
        historicalData.length > 0 &&
        historicalData.find(
          (val: any) => val?.patientSessionId === patientSessionId
        );

      if (item) {
        if (eventData.complete === true) {
          let payload = {
            fileAndPath: eventData.details,
            name: item.hospitalPatientId,
            psid: item.patientSessionId,
            start: moment(item.patientStartSessionTime).format(
              "Do-MMM-YYYY-h.mma"
            ),
            end: moment(item.patientEndSessionTime).format("Do-MMM-YYYY-h.mma"),
          };
          let outgoingJson = JSON.stringify(payload);

          const { token } = JSON.parse(localStorage.getItem("userDetail")!);

          const apiUrl = `${process.env.REACT_APP_API_URL}/api/Measurements/Export?payload=${outgoingJson}`;

          fetch(apiUrl, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
            .then((response) => {
              if (response.ok) {
                setReportIndex("");
                return response.blob();
              } else if (response.status === 500) {
                console.error("API error 500");
                setReportIndex("");
                return Promise.reject(new Error("API error 500"));
              } else {
                setReportIndex("");
                throw new Error(`API error ${response.status}`);
              }
            })
            .then((blob) => {
              setReportIndex("");
              const url = URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = `${item.hospitalPatientId}_from_${moment(
                item.patientStartSessionTime
              ).format("Do-MMM-YYYY-h.mmA")}_to_${moment(
                item.patientEndSessionTime
              ).format("Do-MMM-YYYY-h.mmA")}.zip`;
              a.click();
            })
            .catch((error) => {
              setReportIndex("");
              console.error("Error:", error);
            });
        }
      }
    },
    [historicalData, setReportIndex]
  );

  React.useEffect(() => {
    if (connection && historicalData) {
      connection.on("generateReportProgressCallback", function (payload: any) {
        generateReportProgressCallback(payload);
      });

      connection.on("historicExportCallback", function (payload: any) {
        historicExportCallback(payload);
      });
    }
  }, [connection]);

  const editSummary = (data: any) => {
    setEditDiolog(!editDiolog);
    setEditData(data);
  };

  const onClickofView = (data: any, startTime: string, endTime: string) => {
    const {
      patientSessionId,
      byPatientId,
      thresholdSetAgeBlockDetailId,
      // patientStartSessionTime,
      // patientEndSessionTime,
      hospitalPatientId,
      wardName,
      bedName,
    } = data;
    const URL = `/patienddetail?patientDetaildsId=${byPatientId}&patientSessionId=${patientSessionId}&isHistoric=true&thresholdSetAgeBlockDetailId=${thresholdSetAgeBlockDetailId}&from=${startTime}&to=${endTime}&hospitalPatientId=${hospitalPatientId}&wardName=${wardName}&bedName=${bedName}`;
    window.open(URL, "_self");
    // goTo(URL, {
    //   state: {
    //     allData: data,
    //     patientData: data,
    //   },
    // });
  };

  const updateDetail = async (patientData: any) => {
    const userDetail: any = localStorage.getItem("userDetail");
    const loginUser: any = JSON.parse(userDetail);
    const config: any = {
      patientDetailsId: patientData?.allData?.byPatientId,
      newHospitalPatientId: patientData?.hospitalPatientId,
      byUserId: loginUser?.userDetailsId,
      authorisationKey: loginUser?.authorisationKey,
    };

    const getPostedData = await APIHelper.updatePatiet(config);

    if (getPostedData.data) {
      setLoading(true);
      APIHelper.getHistoricalData(config).then((data) => {
        const historicalData = JSON.parse(
          convertCompressedDataBackToJson(data?.data)
        );
        setHistoricalData(historicalData);
        setupHistoricalTable(historicalData);
        setLoading(false);
      });
      setEditDiolog(!editDiolog);
    }
    setEditDiolog(!editDiolog);
  };

  const deleteSummary = (data: any) => {
    setDeleteDiolog(!deleteDiolog);
    setDeleteData(data);
  };

  const deleteDetail = async (data: any) => {
    const userDetail: any = localStorage.getItem("userDetail");
    const loginUser: any = JSON.parse(userDetail);
    const config: any = {
      byUserId: loginUser?.userDetailsId,
      authorisationKey: loginUser?.authorisationKey,
      patientSessionId: data?.patientSessionId,
    };
    const deletedata = await APIHelper.updatePatiet(config);
    if (deletedata.data) {
      APIHelper.getHistoricalData(config).then((data) => {
        const historicalData = JSON.parse(
          convertCompressedDataBackToJson(data?.data)
        );
        setHistoricalData(historicalData);
        setupHistoricalTable(historicalData);
      });
      setDeleteDiolog(!deleteDiolog);
    }
    setDeleteDiolog(!deleteDiolog);
  };

  const downloadAllfiles = () => {
    const { open } = downloadFiles;
    setDownloadFiles({ open: !open, historicalData, connection });
  };

  const visibleRows = React.useMemo(() => {
    setLoading(true);
    const sortedRows = stableSort(rows, getComparator(order, orderBy));
    const slicedRows = sortedRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    setLoading(false);
    return slicedRows;
  }, [order, orderBy, page, rowsPerPage, rows]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <TopBar />
      <Breadcum />
      <PatientSummaryedit
        open={editDiolog}
        handleClose={editSummary}
        patientData={editData}
        updateDetail={updateDetail}
      />
      <PatientSummaryDelete
        open={deleteDiolog}
        handleClose={deleteSummary}
        patientData={deletetData}
        deleteDetail={deleteDetail}
      />
      <DownloadPatientSummary
        downloadFiles={downloadFiles}
        handleClose={downloadAllfiles}
        getPublicationPayload={getPublicationPayload}
      />
      <div
        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
        id="kt_wrapper"
        style={{
          paddingTop: "50px",
          borderTop: "1px solid #ddc",
        }}
      >
        <div className="historicalMain container-wrappers">
          <Box sx={{ width: "100%" }}>
            <div className="d-flex">
              <div className="form-group mb-3 w-25 validated">
                <label htmlFor="byWardId">{t("historical.data.byward")}</label>
                <IsanSelect
                  id="wardId"
                  name="wardId"
                  type="select"
                  onChange={(e: any) =>
                    handleChangeFilter(
                      e.target.name,
                      e.target.value === "Select..."
                        ? ""
                        : parseInt(e.target.value)
                    )
                  }
                  value={!isNaN(filter?.ward) ? filter?.ward : ""}
                  options={wardData.data?.map(wardToOption) || []}
                />
              </div>
              <div className="form-group mb-3 ml-5 w-25 validated">
                <label htmlFor="byWardId">
                  {t("historical.data.startday")}
                </label>
                <IsanInput
                  id="start"
                  name="start"
                  type="date"
                  onChange={(e: any) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                  value={filter?.start}
                />
              </div>
              <div className="form-group mb-3 ml-5 w-25 validated">
                <label htmlFor="byWardId">{t("historical.data.endday")}</label>
                <IsanInput
                  id="end"
                  name="end"
                  type="date"
                  onChange={(e: any) =>
                    handleChangeFilter(e.target.name, e.target.value)
                  }
                  value={filter?.end}
                />
              </div>
              <div className="form-group mb-3 ml-5 w-25 validated">
                <label htmlFor="byWardId">
                  {t("historical.data.patientid")}
                </label>
                <IsanInput
                  id="hospitalPatientId"
                  name="hospitalPatientId"
                  type="text"
                  onChange={(e: any) =>
                    setFilter({
                      ...filter,
                      hospitalPatientId: e?.target?.value,
                    })
                  }
                  value={filter?.hospitalPatientId}
                />
              </div>
              <div className="form-group mb-3 ml-5 w-15 validated">
                <label htmlFor="byWardId">
                  {t("historical.data.batchdownload")}:
                </label>
                <Button
                  className="btn btn-success"
                  onClick={() => downloadAllfiles()}
                >
                  {t("historical.data.downloadallfiles")}
                </Button>
              </div>
              <div className="form-group mb-3 ml-5 w-15 validated">
                <label htmlFor="byWardId">
                  {t("historical.data.anonydownload")}:
                </label>
                <input
                  type="checkbox"
                  name="anonymousDownloads"
                  id="anonymousDownloads"
                  onChange={(e) =>
                    handleChangeFilter(e.target.name, e.target.checked)
                  }
                />
              </div>
            </div>

            <Paper>
              <TableContainer>
                <Table style={{ minWidth: "750px" }}>
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows?.map((data: any, index: any) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          key={index}
                          style={{
                            textAlign: "left",
                            height: 50,
                          }}
                        >
                          <TableCell align="center" style={{ width: "3rem" }}>
                            <button
                              className="btn btn-warning"
                              onClick={() => editSummary(data)}
                              // style={{ marginLeft: "2rem" }}
                            >
                              <EditIcon />
                            </button>
                          </TableCell>
                          <TableCell align="center" style={{ width: "3rem" }}>
                            <button
                              className="btn btn-danger"
                              onClick={() => deleteSummary(data)}
                              // style={{ marginLeft: "2.5rem" }}
                            >
                              <DeleteIcon />
                            </button>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            align="center"
                            style={{ width: "9rem", maxWidth: "9rem" }}
                          >
                            {data?.hospitalPatientId}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ width: "3rem", maxWidth: "3rem" }}
                          >
                            {data.wardName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ width: "3rem", maxWidth: "3rem" }}
                          >
                            {data.bedName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ width: "5rem", maxWidth: "5rem" }}
                          >
                            {moment(
                              data?.allData?.patientStartSessionTime
                            ).format("dddd Do MMM YYYY, h:mm:ss")}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ width: "3rem", maxWidth: "5rem" }}
                          >
                            {moment(
                              data?.allData?.patientEndSessionTime
                            ).format("dddd Do MMM YYYY, h:mm:ss")}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ width: "3rem", maxWidth: "3rem" }}
                          >
                            {getDuration(
                              data?.allData?.patientEndSessionTime,
                              data?.allData?.patientStartSessionTime
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ width: "3rem", maxWidth: "3rem" }}
                          >
                            <button
                              className="btn btn-primary"
                              onClick={() => pubSubHistoricExport(data, index)}
                            >
                              {buttonIndex === index
                                ? t("historical.table.Generate")
                                : t("historical.table.Download")}
                            </button>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ width: "3rem", maxWidth: "3rem" }}
                          >
                            <button
                              className="btn btn-primary"
                              onClick={() => generateReport(data, index)}
                            >
                              {ReportIndex === index
                                ? t("historical.table.Generate")
                                : t("historical.table.Download")}
                            </button>
                          </TableCell>
                          <TableCell
                            className=""
                            align="center"
                            style={{ width: "3rem", maxWidth: "3rem" }}
                          >
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                onClickofView(
                                  data?.allData,
                                  data?.allData?.patientStartSessionTime,
                                  data?.allData?.patientEndSessionTime
                                )
                              }
                              // style={{ marginLeft: "1rem" }}
                            >
                              {t("historical.table.View")}
                            </button>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ width: "3rem", maxWidth: "3rem" }}
                          >
                            <button
                              // style={{ marginLeft: "2.5rem" }}
                              className="btn btn-warning"
                              onClick={() => {
                                goTo(
                                  `/patientHistorysummary?patientSessionId=${data?.allData?.patientSessionId}`,
                                  { state: { patientData: data } }
                                );
                              }}
                            >
                              {t("historical.table.Summary")}
                            </button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={historicalData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </div>
        <iframe
          id="my_iframe"
          title="my iFrame"
          style={{ display: "none" }}
          ref={iframeRef}
        ></iframe>
      </div>
    </>
  );
}
