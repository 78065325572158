import {
  Breadcum,
  IsanButton,
  IsanErrorLabel,
  IsanInput,
  TopBar,
} from "@/components";
import { useFormik } from "formik";
// import useAxios from "axios-hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Checkbox, Switch } from "@material-ui/core";
import APIHelper from "@/utils/ApiHelper";

function AddWard() {
  const currentDate = new Date().toISOString();
  const userId = localStorage.getItem("userDetail");
  // const location = useLocation();
  const userData = userId ? JSON.parse(userId) : null;
  const firstApiHeaders = useMemo(
    () => ({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData?.token}`,
    }),
    [userData?.token]
  );
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const [thresholdData, setThresHoldData] = useState<any>([]);
  const [fetchReq, setreq] = useState<any>([]);
  const [setWardCheck] = useState<any>([]);
  const alert = useAlert();
  const navigate = useNavigate();
  const goTo = () => navigate("/dashboard/wardList");
  console.log("thresholdData", thresholdData);
  const formik = useFormik({
    initialValues: {
      wardName: "",
      wardDetailsId: 0,
      byUserId: 0,
      authorisationKey: "",
      status: "",
      isDeleted: 0,
      PEWS: "false",
      NEWS: "false",
    },

    onSubmit: async (values) => {
      const {
        wardName,
        wardDetailsId,
        byUserId,
        authorisationKey,
        status,
        isDeleted,
        PEWS,
        NEWS,
      } = values;
      const body = {
        wardName,
        wardDetailsId,
        byUserId,
        authorisationKey,
        status,
        isDeleted,
        PEWS,
        NEWS,
      };
      try {
        const config = {
          data: body,
        };
        let wardId: any = searchParams.get("WardDetailsId");
        let alertMessage = t("wardform.alertmessage.create");

        if (wardId) {
          // config.method = "POST";
          // config.url = "/Administration/UpdateWardDetails";
          APIHelper.updateWard(config)
            .then(() => {
              alertMessage = t("wardform.alertmessage.edit");
            })
            .catch((error: any) => {
              console.log("error => ", error);
            });
        }

        // thresholdId: number,
        // wardDetailId: number,
        // thresholdSetId: number,
        // isChecked: boolean

        thresholdData.forEach((values: any) => {
          handleChanges(
            values?.WardThresholdId,
            wardId,
            values?.ThresholdSetId,
            values?.isChecked
          );
        });

        // const getPostedData =
        await APIHelper.createNewWard(config)
          .then((data) => {
            setreq(data);
            alert.success(alertMessage);
          })
          .catch((error: any) => {
            console.log("error => ", error);
          });

        goTo();
      } catch (err) {
        alert.error(t("wardform.alertmessage.error"));
      }
    },
  });

  // const data = searchParams.get("display");
  const WardDetailsId = searchParams.get("WardDetailsId");

  // if (data !== null) {
  //   const decodedValue = btoa(data);
  // }

  useEffect(() => {
    const userDetail: any = localStorage.getItem("userDetail");
    const { authorisationKey, userDetailsId } = JSON.parse(userDetail);
    formik.setFieldValue("authorisationKey", authorisationKey);
    formik.setFieldValue("byUserId", parseInt(userDetailsId));

    if (searchParams.get("WardDetailsId")) {
      formik.setFieldValue("wardName", searchParams.get("WardName"));
      formik.setFieldValue("wardDetailsId", searchParams.get("WardDetailsId"));
      formik.setFieldValue("byUserId", searchParams.get("ByUserId"));
      formik.setFieldValue("status", searchParams.get("Status"));
      formik.setFieldValue("isDeleted", searchParams.get("IsDeleted"));
      formik.setFieldValue("PEWS", searchParams.get("PEWS"));
      formik.setFieldValue("NEWS", searchParams.get("NEWS"));
    }
  }, [searchParams, formik]);

  const datas = useMemo(
    () => ({
      thresholdSetId: 0,
      thresholdSetName: "",
      thresholdSetAgeBlockDetailId: 0,
      onlyDefault: true,
      patientSessionId: 0,
      wardDetailsId: 0,
    }),
    []
  );

  const getApi = useCallback(async () => {
    const data = await APIHelper.getThresholdsetInfo(datas);

    const item = data.data;
    const secondApiUrl = `${process.env.REACT_APP_API_URL}/PatientMeasurementDatabase/json/reply/GetWardThresholdByWardId`;
    const wardDetailsId = searchParams.get("WardDetailsId");

    const secondApiPayload = {
      wardThresholdId: 0,
      wardDetailsId: wardDetailsId ? +wardDetailsId : 0,
      thresholdSetId: 0,
      createdByUserId: 0,
      createdTimestamp: currentDate,
      isDeleted: formik?.values?.isDeleted ? +formik?.values?.isDeleted : 0,
      deletedByUserId: 0,
      deletedTimestamp: currentDate,
    };

    try {
      const secondResponse = await axios.post(secondApiUrl, secondApiPayload, {
        headers: firstApiHeaders,
      });
      setWardCheck(secondResponse?.data);
      console.log("secondResponse?.data", secondResponse?.data);
      console.log("item =>", item);
      const threshhold = item?.map((val: any) => {
        const correspondingWardCheck = secondResponse?.data?.find(
          (ward: any) =>
            ward?.ThresholdSetId === val?.ThresholdSetId &&
            ward?.IsDeleted === 0
        );
        const isChecked = correspondingWardCheck ? true : false;
        console.log("isChecked", correspondingWardCheck);
        val.isChecked = isChecked;
        val.WardThresholdId = correspondingWardCheck?.WardThresholdId;
        return val;
      });
      setThresHoldData(threshhold);
    } catch (secondError: any) {
      console.error("Second API Error:", secondError.message);
    }
  }, [
    datas,
    searchParams,
    formik?.values?.isDeleted,
    currentDate,
    firstApiHeaders,
    setWardCheck,
    setThresHoldData,
  ]);

  useEffect(() => {
    getApi();
  }, [getApi]);

  const handleChanges = async (
    thresholdId: number,
    wardDetailId: number,
    thresholdSetId: number,
    isChecked: boolean
  ) => {
    setWardCheck((prevWardCheck: any) =>
      prevWardCheck.map((ward: any) =>
        ward.WardThresholdId === thresholdId
          ? { ...ward, statusCode: isChecked ? 1 : 0 }
          : ward
      )
    );

    if (!isChecked) {
      const deleteUrl = `${process.env.REACT_APP_API_URL}/api/Administration/WardThresholdDelete`;
      try {
        await fetch(deleteUrl, {
          method: "POST",
          headers: firstApiHeaders,
          body: JSON.stringify({
            WardThresholdId: thresholdId,
            DeletedByUserId: userData?.userDetailsId,
          }),
        });
      } catch (error) {
        console.error("Delete API call failed:", error);
      }
    } else {
      const addUrl = `${process.env.REACT_APP_API_URL}/api/administration/Wardthresholdadd`;
      try {
        await fetch(addUrl, {
          method: "POST",
          headers: firstApiHeaders,
          body: JSON.stringify({
            WardThresholdId: thresholdId,
            WardDetailsId: wardDetailId,
            ThresholdSetId: thresholdSetId,
            CreatedByUserId: userData?.userDetailsId,
            CreatedTimestamp: currentDate,
            IsDeleted: 0,
            DeletedByUserId: 0,
            DeletedTimestamp: currentDate,
          }),
        });
      } catch (error) {
        console.error("Add API call failed:", error);
      }
    }
  };

  return (
    <div
      className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
      id="kt_wrapper"
    >
      <TopBar />
      <Breadcum />
      <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__body kt-portlet__body--fit">
              <div className="kt-form kt-form--label-right">
                <div className="kt-form__body">
                  <div className="text-center h3 mt-4 mb-4">
                    {WardDetailsId
                      ? t("wardform.data.edit")
                      : t("wardform.data.addNew")}
                  </div>
                  <div className="card card-custom gutter-b example example-compact">
                    <div className="card-body">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="form-group mb-3 validated">
                          <label htmlFor="contactName">
                            {t("wardform.data.location")}
                          </label>
                          <IsanInput
                            id="wardName"
                            name="wardName"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.wardName}
                          />
                          <IsanErrorLabel
                            inputTouched={formik.touched.wardName}
                            text={formik.errors.wardName}
                          />
                          <div className="mb-3">
                            <label>Active</label>
                            <Checkbox
                              checked={formik.values.status === "0"}
                              onChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: "status",
                                    value: e.target.checked ? "0" : "1",
                                  },
                                });
                              }}
                            />
                          </div>
                          {WardDetailsId && (
                            <>
                              <hr />

                              <div className="mb-4">
                                <div style={{ fontSize: "20px" }}>
                                  {t("Threshold Sets to use for Ward")}
                                </div>
                                <hr />

                                <div>
                                  {thresholdData.map(
                                    (item: any, index: any) => {
                                      return (
                                        <div key={index}>
                                          <label>{item.ThresholdSetName}</label>
                                          <Switch
                                            checked={item?.isChecked}
                                            // onChange={(e: any) =>
                                            //   handleChanges(
                                            //     e,
                                            //     item?.WardThresholdId,
                                            //     formik.values.wardDetailsId,
                                            //     item?.ThresholdSetId
                                            //   )
                                            // }
                                            onChange={() => {
                                              thresholdData[index].isChecked =
                                                !thresholdData[index].isChecked;
                                              setThresHoldData(
                                                JSON.parse(
                                                  JSON.stringify(thresholdData)
                                                )
                                              );
                                            }}
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <IsanButton
                          loading={fetchReq.loading}
                          type="submit"
                          text={t("users.addnew.submit")}
                        />
                        &nbsp;
                        <IsanButton
                          disabled={fetchReq.loading}
                          type="submit"
                          text={t("users.addnew.cancle")}
                          onClick={goTo}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddWard;
